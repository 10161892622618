import { Descriptions } from 'antd';
import React from 'react';
import { ClientInterface, ColorConstant, i18n } from '../../common';
import { T1, H3 } from '../texts';

interface Props {
  client?: ClientInterface;
}

const CompanyDetails = ({client}: Props) => {
  const descriptionsItems: Array<{label: string | JSX.Element, render: JSX.Element}> = [
    {
      label: <T1 style={{ color: ColorConstant.DARK_GREY.hash }}>{i18n.translate('components.details.company.companyName')}</T1>,
      render: <H3>{client?.name ? client.name : '—'}</H3>
    },
    {
      label: <T1 style={{ color: ColorConstant.DARK_GREY.hash }}>{i18n.translate('components.details.company.oib')}</T1>,
      render: <H3>{client?.vat ? client.vat : '—'}</H3>
    },
  ]

  return (
    <Descriptions column={1} layout={'vertical'} colon={false}>
      {descriptionsItems.map((item: {label: string | JSX.Element, render: JSX.Element}, index: number) => (
        <Descriptions.Item key={index} label={item.label}>{item.render}</Descriptions.Item>
      ))}
    </Descriptions>
  );
}

export default CompanyDetails;
