import React from "react";
import { Input, theme } from "antd";
import { SearchIcon } from "../icons";
import { FilterProps } from "../tables/table";

const { useToken } = theme;

const ColumnSearch = ({ dataKey, placeholder, handleFilterChange }: FilterProps): JSX.Element => {
  const { token } = useToken();

  const onSearchChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    if (e.target.value === '') handleFilterChange();
    else handleFilterChange({ name: dataKey, operator: 'LIKE', value: `${e.target.value}%` });
  }

  const contentStyle = {
    borderRadius: 4,
    boxShadow: token.boxShadow,
    paddingTop: 8,
    paddingBottom: 8
  };

  return (
    <div style={contentStyle}>
      <Input
        placeholder={placeholder}
        variant="borderless"
        prefix={<SearchIcon />}
        onChange={onSearchChange}
        allowClear
      />
    </div>
  )
}

export default ColumnSearch;