import { Col, Row, Space } from 'antd';
import React from 'react';
import { AddressInterface, ClientInterface, i18n } from 'src/common';
import { H2 } from 'src/components/texts';
import { AddressCard } from 'src/components/cards';
import { CompanyDetails } from 'src/components/details';

interface Props {
  client?: ClientInterface;
}

const DetailsTab = ({client}: Props) => {

  // OVO MORAMO ZBOG CIRCULAR DEPENDENCYJA NAPRAVITI
  client?.addresses?.forEach((a: any) => { if (a?.client) a.client = null; });
  client?.employees?.forEach((e: any) => { if (e?.client) e.client = null; });
  client?.partners?.forEach((pa: any) => { if (pa?.client) pa.client = null; });
  client?.orders?.forEach((pr: any) => { if (pr?.client) pr.client = null; });

  const headquarters = client?.addresses?.find((a: AddressInterface) => a.isHeadquarters);
  const secondaryAddresses = client?.addresses?.filter((a: AddressInterface) => !a.isHeadquarters);

  return (
    <>
      <Row justify={'space-between'}>
        <Col xs={24} md={20} flex={'auto'}>
          <CompanyDetails client={client} />
          {headquarters ? <>
            <Space className='mb8'>
              <H2>{i18n.translate('components.tabs.company.headquarters')}</H2>
            </Space>
            <Col>
              <AddressCard address={headquarters} />
            </Col>
          </> : null}
          {secondaryAddresses?.length ? <>
            <Space className='mb8'>
              <H2>{i18n.translate('components.tabs.company.addresses')}</H2>
            </Space>
            <Row gutter={16} wrap>
              {secondaryAddresses.map((address: AddressInterface) => (
                <Col key={`address${address.id}`} className="gutter-row" xs={24} xl={10}>
                  <AddressCard address={address} />
                </Col>
              ))}
            </Row>
          </> : null}
        </Col>
      </Row>
    </>
  );
}

export default DetailsTab;
