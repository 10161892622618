import React, { useState } from "react";
import { Link } from "react-router-dom";
import { openClientDrawerAndSetClient } from "src/common/redux/drawer/drawer.slice";
import { Dropdown, Button } from "antd";
import type { MenuProps } from 'antd';
import { ColumnsType } from "antd/es/table";
import { useQuery } from "src/lib/jsonapi-react/src";
import { QueryParams, i18n, ClientInterface, DefaultPagination, FetchParams, useAppDispatch } from "../../common";
import Table, { TableParams } from "./table";

type ClientsTableColumnType = 'name | address | vat | actions';

export const allDriversTableColumns: Array<ClientsTableColumnType> = ['name | address | vat | actions'];


interface Props {
  injectedQueryParams?: QueryParams;
}

const ClientsTable = ({ injectedQueryParams }: Props) => {
  const componentQueryParams: QueryParams = {
    ...injectedQueryParams,
    includes: [
      'addresses',
      'addresses.city',
      // 'employees',
      // 'partners' // BE u nekim slučajevima ne podržava više stvari u includeu? Dobije se 403, problem sa permisijama?
    ]
  }

  const [queryParams, setQueryParams] = useState<QueryParams>(componentQueryParams);

  const params: FetchParams = {
    type: 'client',
    queryParams
  };

  const { data } = useQuery(params);

  console.log(data);

  const dispatch = useAppDispatch();

  const menu = (tableClient: ClientInterface) => { // 
    // OVO MORAMO ZBOG CIRCULAR DEPENDENCYJA NAPRAVITI
    const processedTableClient = { ...tableClient,
      addresses: tableClient.addresses?.map((a: any) => { a.client = null; return a }),
      employees: tableClient.employees?.map((e: any) => { e.client = null; return e }),
      partners: tableClient.partners?.map((pa: any) => { pa.client = null; return pa }),
      orders: tableClient.orders?.map((pr: any) => { pr.client = null; return pr }),
    };

    const items: MenuProps['items'] = [
      {
        key: '1',
        label: <a onClick={() => dispatch(openClientDrawerAndSetClient(processedTableClient))}>{i18n.t("components.tables.clients.editAction")}</a>
      }
    ];

    return { items };
  }
  
  const clientsTableColumns: ColumnsType<ClientInterface> = [
    {
      title: i18n.t('components.tables.clients.name'),
      dataIndex: 'name',
      key: 'name',
      render: (dataIndex: string, client: ClientInterface) => <Link to={`/clients/${client.id}`}>{client.name}</Link>,
      sorter: true,
      width: 300,
    },
    {
      title: i18n.t('components.tables.clients.address'),
      dataIndex: 'address',
      key: 'address',
      render: (dataIndex: string, client: ClientInterface) =>
        client.addresses?.find((x) => x.isHeadquarters) &&
        `${client.addresses?.find((x) => x.isHeadquarters)?.street}${
          client.addresses?.find((x) => x.isHeadquarters)?.city
            ? `, ${client.addresses?.find((x) => x.isHeadquarters)?.city.name}`
            : ''
        }`,
      width: 300,
    },
    {
      title: i18n.t('components.tables.clients.vat'),
      dataIndex: 'vat',
      key: 'vat',
      render: (dataIndex: string, client: ClientInterface) => client.vat,
      sorter: true,
      width: 300,
    },
    {
      title: i18n.t('components.tables.clients.actions'),
      dataIndex: 'actions',
      key: 'actions',
      render: (dataIndex: number, client: ClientInterface) => (
        <Dropdown menu={menu(client)} trigger={['click']}>
          <a
            onClick={(e) => {
              e.preventDefault();
            }}
          >
            <Button type="link">. . .</Button>
          </a>
        </Dropdown>
      ),
      width: 50,
    },
  ];

  const handleTableChange = (tableParams: TableParams) => {
    setQueryParams({
      pagination: tableParams.pagination,
      filters: [
        ...(tableParams.filters || []),
        ...(componentQueryParams?.filters || []),
      ],
      sorters: tableParams.sorters || componentQueryParams.sorters,
      includes: componentQueryParams.includes,
      search: tableParams.search
    });
  };

  if (!data) return null;
  return (
    <>
      <Table
        rowKey={"id"}
        columns={clientsTableColumns}
        dataSource={data}
        handleTableChange={handleTableChange}
        paginationDefault={DefaultPagination}
        total={data.total}
      />
    </>
  )

}

export default ClientsTable;