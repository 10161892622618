import { Breadcrumb, Card, Flex, Tabs, TabsProps } from "antd";
import React from "react";
import { useQuery } from "src/lib/jsonapi-react/src";
import { H2 } from "src/components";
import { useParams } from "react-router-dom";
import { i18n, FetchParams, QueryParams, ClientInterface } from '../../../common';
import { ClientsTab, DetailsTab, EmployeesTab, OrdersTab } from "./tabs";

const ClientPage = () => {

  const { id } = useParams();

  const queryParams: QueryParams = {
    filters: [
      {
        name: 'id',
        operator: 'eq',
        value: `${id}`
      }
    ],
    includes: [
      'addresses',
      'addresses.city'
    ]
  }

  const params: FetchParams = {
    type: 'client',
    queryParams
  };

  const { data } = useQuery(params);
  // TODO promijeniti na single dohvat po id-ju nakon što se BE popravi
  const client: ClientInterface = data?.length ? data[0] : undefined;

  const tabItems: TabsProps['items'] = [
    {
      key: '1',
      label: i18n.translate('pages.client.tabs.details'),
      children: <DetailsTab client={client} />,
    },
    {
      key: '2',
      label: i18n.translate('pages.client.tabs.employees'),
      children: <EmployeesTab clientId={client?.id} />,
    },
    {
      key: '3',
      label: i18n.translate('pages.client.tabs.clients', { clientName: client?.name }),
      children: <ClientsTab clientId={client?.id} />,
    },
    {
      key: '4',
      label: i18n.translate('pages.client.tabs.orders'),
      children: <OrdersTab clientId={client?.id} />,
    },
  ];

  return (
    <>
      <Breadcrumb
        items={[
          { title: `${i18n.t('common.breadcrumb.clients')}` },
          { title: data?.[0]?.name }
        ]}
        style={{ marginLeft: 32, marginBottom: 16 }}
      />
      <Card>
        <Flex justify='space-between' align='center' style={{ marginBottom: 16 }}>
          <H2>
            {data?.[0]?.name}
          </H2>
        </Flex>
        <Tabs defaultActiveKey="1" items={tabItems} destroyInactiveTabPane />
      </Card>
    </>
  );
}

export default ClientPage;