import { OrderPayerEnum } from "../enums";
import { i18n } from "../services";

export interface OrderPayer {
  code: string;
  label: string;
}

export const OrderPayers = (): Array<OrderPayer> => [
  { code: OrderPayerEnum.ORDERER, label: i18n.translate("common.constants.orderPayer.orderer") },
  { code: OrderPayerEnum.SENDER, label: i18n.translate("common.constants.orderPayer.sender") },
  { code: OrderPayerEnum.RECEIVER, label: i18n.translate("common.constants.orderPayer.receiver") },
]

export const getOrderPayerLabel = (code: string): string =>
  OrderPayers().find((op: OrderPayer) => op.code === code) ? OrderPayers().find((op: OrderPayer) => op.code === code)!.label : "";
