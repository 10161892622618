import { Drawer, Space, Button } from 'antd'
import React from 'react'
import { UserInterface, useAppSelector, RootState, useAppDispatch, i18n } from 'src/common';
import { closeUserDrawer, submitUserForm } from 'src/common/redux/drawer/drawer.slice';
import UserForm, { allUserFormItems } from '../forms/user.form';

const UserDrawer = () => {

  const dispatch = useAppDispatch();

  const userDrawer = useAppSelector((state: RootState) => state.state.drawer.user);

  const handleClose = () => {
    dispatch(closeUserDrawer());
  };

  const createUser = (data: UserInterface) => {
    console.log(data);
    // mutateCreate(data);
    handleClose();
  }

  const updateUser = (data: UserInterface) => {
    console.log(data);
    // mutateUpdate(data);
    handleClose();
  }

  const onUserFormFinish = (values: UserInterface) => {
    if (!userDrawer.id) {
      createUser(values);
    } else {
      updateUser(values);
    }
  };

  return <Drawer onClose={handleClose} open={userDrawer.open} destroyOnClose
    title={!userDrawer.id ? i18n.translate('components.drawer.user.titleCreate') : i18n.translate('components.drawer.user.titleEdit')}
    width={500}
    extra={
      <Space>
        <Button onClick={handleClose}>{i18n.translate('components.drawer.user.buttons.cancel')}</Button>
        <Button type="primary" onClick={() => dispatch(submitUserForm())}>{!userDrawer.id ? i18n.translate('components.drawer.user.buttons.create') : i18n.translate('components.drawer.user.buttons.edit')}</Button>
      </Space>
    }>
    <UserForm
      onUserFormFinish={(values) => onUserFormFinish(values)}
      formItems={allUserFormItems.filter((f) => (f !== 'active'))}
      user={userDrawer.data ? userDrawer.data : undefined}
    ></UserForm>
  </Drawer>
}

export default UserDrawer;
