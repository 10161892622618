import React from 'react';
import { Form, Input, Button, Space } from 'antd';
import { i18n } from '../../common';
import { H1, L1, T1 } from '../texts';
import { AuthRoutes } from '../../pages/auth';

interface Props {
  onLoginFormFinish?: (values: any) => void;
  onLoginFormFinishFailed?: (errorInfo: any) => void;
}

const LoginForm = ({ onLoginFormFinish, onLoginFormFinishFailed }: Props) => (
  <Space style={{ maxWidth: 420, minWidth: 360 }} direction='vertical' size={24}>
    <H1>{i18n.translate(`components.forms.login.title`)}</H1>
    <Form
      layout="vertical"
      size="large"
      name="login"
      onFinish={onLoginFormFinish}
      onFinishFailed={onLoginFormFinishFailed}
    >
      <Form.Item
        name="username"
        label={<T1>{i18n.t(`components.forms.login.mail`)}</T1>}
        rules={[{ required: true, message: i18n.translate(`components.forms.login.required`) }, { type: 'email', message: i18n.translate(`components.forms.login.mailFormat`) }]}
      >
        <Input placeholder={i18n.translate(`components.forms.login.mailPlaceholder`)} />
      </Form.Item>
      <Form.Item
        name='password'
        label={<T1>{i18n.t(`components.forms.login.password`)}</T1>}
        rules={[{ required: true, message: i18n.translate(`components.forms.login.required`) }]}
      >
        <Input.Password placeholder={i18n.translate(`components.forms.login.passwordPlaceholder`)} />
      </Form.Item>
      <Form.Item>
        <L1 to={AuthRoutes.FORGOT_PASSWORD.path} key={1}>{i18n.translate(`components.forms.login.forgotPassword`)}</L1>
      </Form.Item>
      <Form.Item>
        <Button type="primary" size="large" block htmlType="submit">
          {i18n.translate(`components.forms.login.btnLogin`)}
        </Button>
      </Form.Item>
    </Form>
  </Space>
);

export default LoginForm;
