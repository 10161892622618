import React from 'react';
import ReactDOM from 'react-dom/client';
import * as serviceWorker from './serviceWorker';

import './assets/scss/_index.scss';
import './assets/images/index';

import App from './app';

const container = document.getElementById('root');
const root = ReactDOM.createRoot(container! as HTMLElement);
const app = <App />;
root.render(app);


serviceWorker.unregister();
