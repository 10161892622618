import React, { useState } from "react";
import { Button, Dropdown, MenuProps } from "antd";
import Tag from "antd/es/tag";
import { ColumnsType } from "antd/es/table";
import { useQuery } from "src/lib/jsonapi-react/src";
import { openUserDrawerAndSetUser } from "src/common/redux/drawer/drawer.slice";
import { QueryParams, i18n, UserInterface, DefaultPagination, FetchParams, Roles, Role, ColorConstant, useAppDispatch } from "../../common";

import Table, { TableFilter, TableParams, TableSearch } from "./table";

type UsersTableColumnType = 'name' | 'email' | 'phoneNumber' | 'dbRole' | 'actions';
type UsersTableFilterType = 'name' | 'email' | 'phoneNumber' | 'dbRole' | 'active';

export const allUsersTableColumns: Array<UsersTableColumnType> = ['name', 'email', 'phoneNumber', 'dbRole', 'actions'];
export const allUsersTableFilters: Array<UsersTableFilterType> = ['name', 'email', 'phoneNumber', 'dbRole', 'active'];

interface Props {
  injectedQueryParams?: QueryParams;
  columns?: Array<UsersTableColumnType>;
  filters?: Array<UsersTableFilterType>;
}

const UsersTable = ({ injectedQueryParams, columns, filters }: Props) => {
    
  const componentQueryParams: QueryParams = {
    ...injectedQueryParams,
    includes: [
      ...(injectedQueryParams?.includes || []),
      'client',
      'roles',
    ],
    sorters: [
      { columnKey: 'id', order: 'descend', field: 'id' }
    ]
  };

  const [ queryParams, setQueryParams ] = useState<QueryParams>(componentQueryParams);

  const params: FetchParams = {
    type: 'user',
    queryParams
  };

  const { data, meta } = useQuery(params);

  const dispatch = useAppDispatch();

  const menu = (tableUser: UserInterface) => { // 
    const items: MenuProps['items'] = [
      {
        key: '1',
        label: <a onClick={() => dispatch(openUserDrawerAndSetUser(tableUser))}>{i18n.t("components.tables.users.editAction")}</a>
      }
    ];

    return { items };
  }

  console.log(data, meta)

  const tableColumns: Array<UsersTableColumnType> = columns || allUsersTableColumns;

  const usersTableColumns: ColumnsType<UserInterface> = [
    {
      title: i18n.t('components.tables.users.name'),
      dataIndex: 'name',
      key: 'name',
      render: (dataIndex: string, user: UserInterface) => user.name,
      sorter: true,
      width: 300
    },
    {
      title: i18n.t('components.tables.users.email'),
      dataIndex: 'email',
      key: 'email',
      width: 300
    },
    {
      title: i18n.t('components.tables.users.phoneNumber'),
      dataIndex: 'phoneNumber',
      key: 'phoneNumber',
      width: 300
    },
    {
      title: i18n.t('components.tables.users.dbRole'),
      dataIndex: 'dbRole',
      key: 'dbRole',
      render: (dataIndex: string, user: UserInterface) => <Tag color={ColorConstant.TAG_GREY.hash}>{user.roles?.length && Roles().find((r: Role) => r.id.toString() === user.roles![0].id.toString())?.name}</Tag>,
      sorter: true,
      width: 300
    },
    {
      title: i18n.t('components.tables.users.actions'),
      dataIndex: 'actions',
      key: 'actions',
      render: (dataIndex: number, user: UserInterface) => <Dropdown menu={menu(user)} trigger={['click']}>
        <a onClick={(e) => {e.preventDefault()}}>
          <Button type="link">. . .</Button>
        </a>
      </Dropdown>,
      width: 50
    }
  ].filter((c) => tableColumns.includes(c.key! as UsersTableColumnType));

  console.log(usersTableColumns);

  interface Filter {
    filterKey: UsersTableFilterType;
    tableFilter: TableFilter;
  }

  const tableFilters: Array<UsersTableFilterType> = filters || allUsersTableFilters;
  console.log(tableFilters)

  const usersTableFilters: Array<Filter> = [];
  //   // { filterKey: 'activeSalesOpportunityStatus' as OrdersTableFilterType, tableFilter: {component: SalesOpportunityStatusFilter, key: 'transientValues.activeSalesOpportunity!.latestOrderState.id' }},
  //   { filterKey: 'orderType' as OrdersTableFilterType, tableFilter: { component: OrderTypeFilter, key: 'orderType.id' } },
  //   { filterKey: 'area' as OrdersTableFilterType, tableFilter: { component: AreaFilter, key: 'areas.id' } },
  //   { filterKey: 'user' as OrdersTableFilterType, tableFilter: { component: UserFilter, key: 'user.id', nullOption: true } },
  //   ...(GuardFunction({ domain: 'order', action: 'view_active' }) ? [{ filterKey: 'active' as OrdersTableFilterType, tableFilter: { component: ActiveFilter, key: 'active' } }] : []),
  // ].filter((f) => tableFilters.includes(f.filterKey as OrdersTableFilterType))

  const searchFields: Array<TableSearch> = [
    // { component: Search, keys: ['name', 'oib'], placeholder: i18n.t('components.tables.orders.searchPlaceholder') }
  ]

  // Potrebno prilagoditi funkciju ovisno o tome koje su sve mogućnosti
  const handleTableChange = (tableParams: TableParams) => {
    setQueryParams({
      pagination: tableParams.pagination,
      filters: [
        ...(tableParams.filters || []),
        ...(componentQueryParams?.filters || []),
      ],
      sorters: tableParams.sorters || componentQueryParams.sorters,
      includes: componentQueryParams.includes,
      search: tableParams.search
    })
  };

  // const handleGroupAction = (selectedRows: Array<DeliveryListInterface>, selectedGroupAction?: number) => {
  //   setSelectedOrders(selectedRows);
  //   groupActions?.find(x => x.key === selectedGroupAction)?.action();
  // };

  if (!data) return null;
  return (
    <>
      <Table
        rowKey={"id"}
        columns={usersTableColumns}
        dataSource={data}
        handleTableChange={handleTableChange}
        paginationDefault={DefaultPagination}
        total={data.total}
        filters={usersTableFilters.map((filter: Filter) => filter.tableFilter)}
        searchFields={searchFields}
      />
    </>
  )

}

export default UsersTable;