import Icon from '@ant-design/icons';
// import type { CustomIconComponentProps } from '@ant-design/icons';
import React from 'react';
import { GetProps } from 'antd';

import { ReactComponent as AddSvg } from '../../assets/icons/add.svg';
import { ReactComponent as AssignmentsSvg } from '../../assets/icons/assignments.svg';
import { ReactComponent as BellSvg } from '../../assets/icons/bell.svg';
import { ReactComponent as CallSvg } from '../../assets/icons/call.svg';
import { ReactComponent as CampaignsSvg } from '../../assets/icons/campaigns.svg';
import { ReactComponent as CancelSvg } from '../../assets/icons/cancel.svg';
import { ReactComponent as ClientsSvg } from '../../assets/icons/clients.svg';
import { ReactComponent as DashboardSvg } from '../../assets/icons/dashboard.svg';
import { ReactComponent as DeleteSvg } from '../../assets/icons/delete.svg';
import { ReactComponent as DoneSvg } from '../../assets/icons/done.svg';
import { ReactComponent as EditSvg } from '../../assets/icons/edit.svg';
import { ReactComponent as EmailSvg } from '../../assets/icons/email.svg';
import { ReactComponent as HideSvg } from '../../assets/icons/hide.svg';
import { ReactComponent as VideoCallSvg } from '../../assets/icons/videoCall.svg';
import { ReactComponent as MeetingSvg } from '../../assets/icons/meeting.svg';
import { ReactComponent as NewMessageSvg } from '../../assets/icons/newMsg.svg';
import { ReactComponent as NoteSvg } from '../../assets/icons/note.svg';
import { ReactComponent as OfferSvg } from '../../assets/icons/offer.svg';
import { ReactComponent as OffersSvg } from '../../assets/icons/offers.svg';
import { ReactComponent as ReservationsSvg } from '../../assets/icons/reservations.svg';
import { ReactComponent as SalesSvg } from '../../assets/icons/salesOpps.svg';
import { ReactComponent as SearchSvg } from '../../assets/icons/search.svg';
import { ReactComponent as SendSvg } from '../../assets/icons/send.svg';
import { ReactComponent as SettingsSvg } from '../../assets/icons/settings.svg';
import { ReactComponent as ShowSvg } from '../../assets/icons/show.svg';
import { ReactComponent as UsersSvg } from '../../assets/icons/users.svg';
import { ReactComponent as UserSvg } from '../../assets/icons/user.svg';
import { ReactComponent as ArrowRightSvg } from '../../assets/icons/arrowRight.svg';

import { ReactComponent as HamburgerSvg } from '../../assets/icons/hamburger.svg';
import { ReactComponent as OrderSvg } from '../../assets/icons/order.svg';
import { ReactComponent as TruckSvg } from '../../assets/icons/truck.svg';
import { ReactComponent as WarehouseSvg } from '../../assets/icons/warehouse.svg';
import { ReactComponent as DriversSvg } from '../../assets/icons/drivers.svg';
import { ReactComponent as SearchWithCheckSvg } from '../../assets/icons/searchWithCheck.svg';
import { ReactComponent as ThreeDimensionalBoxSvg } from '../../assets/icons/threeDimensionalBox.svg';
import { ReactComponent as EditWithLineSvg } from '../../assets/icons/editWithLine.svg';
import { ReactComponent as WebWithCheckSvg } from '../../assets/icons/webWithCheck.svg';
import { ReactComponent as FoldersSvg } from '../../assets/icons/folders.svg';
import { ReactComponent as ReportPlusSvg } from '../../assets/icons/reportPlus.svg';
import { ReactComponent as RouteTwoSvg } from '../../assets/icons/routeTwo.svg';
import { ReactComponent as BoxSvg } from '../../assets/icons/box.svg';
import { ReactComponent as WebSvg } from '../../assets/icons/web.svg';
import { ReactComponent as MoreSvg } from '../../assets/icons/more.svg';
import { ReactComponent as SendParcelSvg } from '../../assets/icons/sendParcel.svg';
import { ReactComponent as RouteSvg } from '../../assets/icons/route.svg';
import { ReactComponent as StatisticSvg } from '../../assets/icons/statistic.svg';
import { ReactComponent as StoreWithArrowSvg } from '../../assets/icons/storeWithArrow.svg';
import { ReactComponent as StackSvg } from '../../assets/icons/stack.svg';
import { ReactComponent as NotificationSvg } from '../../assets/icons/notification.svg';
import { ReactComponent as FactorySvg } from '../../assets/icons/factory.svg';
import { ReactComponent as BriefcaseSvg } from '../../assets/icons/briefcase.svg';
import { ReactComponent as LogOutSvg } from '../../assets/icons/logOut.svg';
import { ReactComponent as BackSvg } from '../../assets/icons/back.svg';
import { ReactComponent as BasketSvg } from '../../assets/icons/basket.svg';
import { ReactComponent as CalendarWithCheckSvg } from '../../assets/icons/calendarWithCheck.svg';
import { ReactComponent as TrolleyWithCheckSvg } from '../../assets/icons/trolleyWithCheck.svg';
import { ReactComponent as DownSvg } from '../../assets/icons/down.svg';
import { ReactComponent as CheckedSvg } from '../../assets/icons/checked.svg';
import { ReactComponent as CardSvg } from '../../assets/icons/card.svg';
import { ReactComponent as InfoSvg } from '../../assets/icons/info.svg';
import { ReactComponent as TrolleyWithArrowSvg } from '../../assets/icons/trolleyWithArrow.svg';
import { ReactComponent as CalendarSvg } from '../../assets/icons/calendar.svg';
import { ReactComponent as HomeSvg } from '../../assets/icons/home.svg';
import { ReactComponent as TrolleySvg } from '../../assets/icons/trolley.svg';
import { ReactComponent as SuitcaseSvg } from '../../assets/icons/suitcase.svg';
import { ReactComponent as ServerSvg } from '../../assets/icons/server.svg';
import { ReactComponent as StornoSvg } from '../../assets/icons/storno.svg';
import { ReactComponent as StatusSvg } from '../../assets/icons/status.svg';
import { ReactComponent as PrintSvg } from '../../assets/icons/print.svg';
import { ReactComponent as CheckSvg } from '../../assets/icons/check.svg';
import { ReactComponent as CircleCheckSvg } from '../../assets/icons/circleCheck.svg';
import { ReactComponent as CircleCircleSvg } from '../../assets/icons/circleCircle.svg';
import { ReactComponent as DuplicateSvg } from '../../assets/icons/duplicate.svg';

type CustomIconComponentProps = GetProps<typeof Icon>;

export const OrderIcon = (props: Partial<CustomIconComponentProps>) => (
  <Icon component={OrderSvg} {...props} />
);

export const TruckIcon = (props: Partial<CustomIconComponentProps>) => (
  <Icon component={TruckSvg} {...props}/>
);

export const HamburgerIcon = (props: Partial<CustomIconComponentProps>) => (
  <Icon component={HamburgerSvg} {...props}/>
);

export const ClientsIcon = (props: Partial<CustomIconComponentProps>) => (
  <Icon component={ClientsSvg} {...props} />
);

export const SettingsIcon = (props: Partial<CustomIconComponentProps>) => (
  <Icon component={SettingsSvg} {...props} />
);

export const WarehouseIcon = (props: Partial<CustomIconComponentProps>) => (
  <Icon component={WarehouseSvg} {...props} />
);

export const DriversIcon = (props: Partial<CustomIconComponentProps>) => (
  <Icon component={DriversSvg} {...props} />
);

export const AddIcon = (props: Partial<CustomIconComponentProps>) => (
  <Icon component={AddSvg} {...props} />
);

export const SearchWithCheckIcon = (props: Partial<CustomIconComponentProps>) => (
  <Icon component={SearchWithCheckSvg} {...props} />
);

export const ThreeDimensionalBoxIcon = (props: Partial<CustomIconComponentProps>) => (
  <Icon component={ThreeDimensionalBoxSvg} {...props} />
);

export const SearchIcon = (props: Partial<CustomIconComponentProps>) => (
  <Icon component={SearchSvg} {...props} />
);

export const EditWithLineIcon = (props: Partial<CustomIconComponentProps>) => (
  <Icon component={EditWithLineSvg} {...props} />
);

export const WebWithCheckIcon = (props: Partial<CustomIconComponentProps>) => (
  <Icon component={WebWithCheckSvg} {...props} />
);

export const FoldersIcon = (props: Partial<CustomIconComponentProps>) => (
  <Icon component={FoldersSvg} {...props} />
);

export const ReportPlusIcon = (props: Partial<CustomIconComponentProps>) => (
  <Icon component={ReportPlusSvg} {...props} />
);

export const EditIcon = (props: Partial<CustomIconComponentProps>) => (
  <Icon component={EditSvg} {...props} />
);

export const RouteTwoIcon = (props: Partial<CustomIconComponentProps>) => (
  <Icon component={RouteTwoSvg} {...props} />
);

export const BoxIcon = (props: Partial<CustomIconComponentProps>) => (
  <Icon component={BoxSvg} {...props} />
);

export const WebIcon = (props: Partial<CustomIconComponentProps>) => (
  <Icon component={WebSvg} {...props} />
);

export const MoreIcon = (props: Partial<CustomIconComponentProps>) => (
  <Icon component={MoreSvg} {...props} />
);

export const SendParcelIcon = (props: Partial<CustomIconComponentProps>) => (
  <Icon component={SendParcelSvg} {...props} />
);

export const RouteIcon = (props: Partial<CustomIconComponentProps>) => (
  <Icon component={RouteSvg} {...props} />
);

export const StatisticIcon = (props: Partial<CustomIconComponentProps>) => (
  <Icon component={StatisticSvg} {...props} />
);

export const StoreWithArrowIcon = (props: Partial<CustomIconComponentProps>) => (
  <Icon component={StoreWithArrowSvg} {...props} />
);

export const StackIcon = (props: Partial<CustomIconComponentProps>) => (
  <Icon component={StackSvg} {...props} />
);

export const NotificationIcon = (props: Partial<CustomIconComponentProps>) => (
  <Icon component={NotificationSvg} {...props} />
);

export const FactoryIcon = (props: Partial<CustomIconComponentProps>) => (
  <Icon component={FactorySvg} {...props} />
);

export const BriefcaseIcon = (props: Partial<CustomIconComponentProps>) => (
  <Icon component={BriefcaseSvg} {...props} />
);

export const LogOutIcon = (props: Partial<CustomIconComponentProps>) => (
  <Icon component={LogOutSvg} {...props} />
);

export const BackIcon = (props: Partial<CustomIconComponentProps>) => (
  <Icon component={BackSvg} {...props} />
);

export const BasketIcon = (props: Partial<CustomIconComponentProps>) => (
  <Icon component={BasketSvg} {...props} />
);

export const CalendarWithCheckIcon = (props: Partial<CustomIconComponentProps>) => (
  <Icon component={CalendarWithCheckSvg} {...props} />
);

export const TrolleyWithCheckIcon = (props: Partial<CustomIconComponentProps>) => (
  <Icon component={TrolleyWithCheckSvg} {...props} />
);

export const DownIcon = (props: Partial<CustomIconComponentProps>) => (
  <Icon component={DownSvg} {...props} />
);

export const CheckedIcon = (props: Partial<CustomIconComponentProps>) => (
  <Icon component={CheckedSvg} {...props} />
);

export const CardIcon = (props: Partial<CustomIconComponentProps>) => (
  <Icon component={CardSvg} {...props} />
);

export const InfoIcon = (props: Partial<CustomIconComponentProps>) => (
  <Icon component={InfoSvg} {...props} />
);

export const TrolleyWithArrowIcon = (props: Partial<CustomIconComponentProps>) => (
  <Icon component={TrolleyWithArrowSvg} {...props} />
);

export const CalendarIcon = (props: Partial<CustomIconComponentProps>) => (
  <Icon component={CalendarSvg} {...props} />
);

export const DeleteIcon = (props: Partial<CustomIconComponentProps>) => (
  <Icon component={DeleteSvg} {...props} />
);

export const HomeIcon = (props: Partial<CustomIconComponentProps>) => (
  <Icon component={HomeSvg} {...props} />
);

export const TrolleyIcon = (props: Partial<CustomIconComponentProps>) => (
  <Icon component={TrolleySvg} {...props} />
);

export const CancelIcon = (props: Partial<CustomIconComponentProps>) => (
  <Icon component={CancelSvg} {...props} />
);

export const SuitcaseIcon = (props: Partial<CustomIconComponentProps>) => (
  <Icon component={SuitcaseSvg} {...props} />
);

export const ServerIcon = (props: Partial<CustomIconComponentProps>) => (
  <Icon component={ServerSvg} {...props} />
);

export const StornoIcon = (props: Partial<CustomIconComponentProps>) => (
  <Icon component={StornoSvg} {...props} />
);

export const StatusIcon = (props: Partial<CustomIconComponentProps>) => (
  <Icon component={StatusSvg} {...props}/>
);

export const PrintIcon = (props: Partial<CustomIconComponentProps>) => (
  <Icon component={PrintSvg} {...props} />
);

export const CheckIcon = (props: Partial<CustomIconComponentProps>) => (
  <Icon component={CheckSvg} {...props} />
);

export const CircleCheckIcon = (props: Partial<CustomIconComponentProps>) => (
  <Icon component={CircleCheckSvg} {...props} />
);

export const CircleCircleIcon = (props: Partial<CustomIconComponentProps>) => (
  <Icon component={CircleCircleSvg} {...props} />
);

export const DuplicateIcon = (props: Partial<CustomIconComponentProps>) => (
  <Icon component={DuplicateSvg} {...props} />
);

/** STARE IKONICE */

export const AssignmentsIcon = (props: Partial<CustomIconComponentProps>) => (
  <Icon component={AssignmentsSvg} {...props} style={{ color: 'transparent' }} />
);

export const BellIcon = (props: Partial<CustomIconComponentProps>) => (
  <Icon component={BellSvg} {...props} style={{ color: 'transparent' }} />
);

export const CallIcon = (props: Partial<CustomIconComponentProps>) => (
  <Icon component={CallSvg} {...props} style={{ color: 'transparent' }} />
);

export const CampaignsIcon = (props: Partial<CustomIconComponentProps>) => (
  <Icon component={CampaignsSvg} {...props} style={{ color: 'transparent' }} />
);

export const DashboardIcon = (props: Partial<CustomIconComponentProps>) => (
  <Icon component={DashboardSvg} {...props} style={{ color: 'transparent' }} />
);

export const DoneIcon = (props: Partial<CustomIconComponentProps>) => (
  <Icon component={DoneSvg} {...props} style={{ color: 'transparent' }} />
);

export const EmailIcon = (props: Partial<CustomIconComponentProps>) => (
  <Icon component={EmailSvg} {...props} style={{ color: 'transparent' }} />
);

export const HideIcon = (props: Partial<CustomIconComponentProps>) => (
  <Icon component={HideSvg} {...props} style={{ color: 'transparent' }} />
);

export const VideoCallIcon = (props: Partial<CustomIconComponentProps>) => (
  <Icon component={VideoCallSvg} {...props} style={{ color: 'transparent' }} />
);

export const MeetingIcon = (props: Partial<CustomIconComponentProps>) => (
  <Icon component={MeetingSvg} {...props} style={{ color: 'transparent' }} />
);

export const NewMessageIcon = (props: Partial<CustomIconComponentProps>) => (
  <Icon component={NewMessageSvg} {...props} style={{ color: 'transparent' }} />
);

export const NoteIcon = (props: Partial<CustomIconComponentProps>) => (
  <Icon component={NoteSvg} {...props} style={{ color: 'transparent' }} />
);

export const OfferIcon = (props: Partial<CustomIconComponentProps>) => (
  <Icon component={OfferSvg} {...props} style={{ color: 'transparent' }} />
);

export const OffersIcon = (props: Partial<CustomIconComponentProps>) => (
  <Icon component={OffersSvg} {...props} style={{ color: 'transparent' }} />
);

export const ReservationsIcon = (props: Partial<CustomIconComponentProps>) => (
  <Icon component={ReservationsSvg} {...props} style={{ color: 'transparent' }} />
);

export const SalesIcon = (props: Partial<CustomIconComponentProps>) => (
  <Icon component={SalesSvg} {...props} style={{ color: 'transparent' }} />
);

export const SendIcon = (props: Partial<CustomIconComponentProps>) => (
  <Icon component={SendSvg} {...props} style={{ color: 'transparent' }} />
);

export const ShowIcon = (props: Partial<CustomIconComponentProps>) => (
  <Icon component={ShowSvg} {...props} style={{ color: 'transparent' }} />
);

export const UsersIcon = (props: Partial<CustomIconComponentProps>) => (
  <Icon component={UsersSvg} {...props} style={{ color: 'transparent' }} />
);

export const UserIcon = (props: Partial<CustomIconComponentProps>) => (
  <Icon component={UserSvg} {...props} style={{ color: 'transparent' }} />
);

export const ArrowRightIcon = (props: Partial<CustomIconComponentProps>) => (
  <Icon component={ArrowRightSvg} {...props} style={{ color: 'transparent' }} />
);


