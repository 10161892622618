import React from 'react';
import { Outlet } from 'react-router-dom';
import { Grid, Layout } from 'antd';
import { Content, Header, Sider } from '../../../components';
import { useAppSelector, RootState } from '../../../common';

const { useBreakpoint } = Grid;

const AppRouter = () => {
  const { lg } = useBreakpoint();
  const collapsed = useAppSelector((store: RootState) => store.state.layout.sider.collapsed); 

  return (
    <Layout style={{ minHeight: '100vh' }} hasSider>
      <Sider />
      <Layout id="app-container" style={{ marginLeft: collapsed ? (lg ? '80px' : 0) : '263px' }}>
        <Header />
        <Content style={{ margin: 16 }}>
          <Outlet />
        </Content>
      </Layout>
    </Layout>
  )
}

export default AppRouter;
