import { ConfigProvider } from "antd";
import { Locale } from "antd/es/locale";
import 'dayjs/locale/en-gb';
import 'dayjs/locale/hr';
import React, { useState, useEffect } from "react";
import { LanguageEnum, RootState, getLanguageLocale, setLang, useAppSelector } from "./common";
import { themeConfig } from "./theme";

interface Props {
  children: React.ReactNode;
}

const AntProvider = ({ children }: Props) => {
  const [providerLang, setProviderLang] = useState<Locale>(getLanguageLocale(LanguageEnum.HR));

  const lang = useAppSelector((store: RootState) => store.model.auth.lang);
  // const langTime = useAppSelector((store: RootState) => state.auth.langTime);

  useEffect(() => {
    setProviderLang(getLanguageLocale(lang));
    setLang(lang);
  }, [lang]
  )

  return <ConfigProvider
    theme={themeConfig}
    locale={providerLang}
  >
    {children}
  </ConfigProvider>
};


export default AntProvider;