import { Button, Flex } from 'antd';
import React from 'react';
import { AddIcon } from 'src/components/icons';
import { QueryParams, i18n, useAppDispatch } from 'src/common';
import { UsersTable } from 'src/components/tables';
import { UserDrawer } from 'src/components/drawers';
import { openUserDrawerAndSetInitialValues } from 'src/common/redux/drawer/drawer.slice';

interface Props {
  clientId?: number;
}

const EmployeesTab = ({clientId}: Props) => {

  const dispatch = useAppDispatch();

  const componentQueryParams: QueryParams = {
    filters: [
      {
        name: 'client.id',
        operator: 'eq',
        value: clientId?.toString() as string
      }
    ]
  }

  return (
    <>
      <Flex justify='end' align='center'>
        <Button
          className='mb16'
          onClick={() => dispatch(openUserDrawerAndSetInitialValues({ initialValues: []}))}
          type="primary"
          icon={<AddIcon />}
        >
          {i18n.t('components.tabs.employees.create')}
        </Button>
      </Flex>
      <UsersTable injectedQueryParams={componentQueryParams}></UsersTable>
      <UserDrawer></UserDrawer>
    </>
  );
}

export default EmployeesTab;
