import React, { useState } from "react";
import { ColumnsType } from "antd/es/table";
import { useQuery } from "src/lib/jsonapi-react/src";
import { QueryParams, i18n, DriverInterface, DefaultPagination, FetchParams, RoleEnum } from "../../common";

import Table, { TableParams } from "./table";

type DriversTableColumnType = 'uuid | phoneNumber | email | actions';

export const allDriversTableColumns: Array<DriversTableColumnType> = ['uuid | phoneNumber | email | actions'];


const DriversTable = () => {
  const componentQueryParams: QueryParams = {
    filters: [
      {
        name: 'roles.id',
        operator: 'eq',
        value: RoleEnum.DRIVER.toString()
      }
    ]
  }

  const [ queryParams, setQueryParams ] = useState<QueryParams>(componentQueryParams);

  const params: FetchParams = {
    type: 'user',
    queryParams
  };

  const { data } = useQuery(params);

  console.log(data);

  const driversTableColumns: ColumnsType<DriverInterface> = [
    {
      title: i18n.t('components.tables.drivers.name'),
      dataIndex: 'name',
      key: 'name',
      render: (dataIndex: string, driver: DriverInterface) => driver.name,
      sorter: true,
      width: 300
    },
    {
      title: i18n.t('components.tables.drivers.driverType'),
      width: 200
    },
    {
      title: i18n.t('components.tables.drivers.phoneNumber'),
      dataIndex: 'phoneNumber',
      key: 'phoneNumber',
      render: (dataIndex: string, driver: DriverInterface) => driver.phoneNumber,
      width: 300
    },
    {
      title: i18n.t('components.tables.drivers.email'),
      dataIndex: 'email',
      key: 'email',
      render: (dataIndex: string, driver: DriverInterface) => driver.email,
      width: 300
    },
    {
      title: i18n.t('components.tables.drivers.lastDeliveryList'),
      width: 400
    },
    {
      title: i18n.t('components.tables.drivers.actions'),
      width: 50,
    },
  ];

  const handleTableChange = (tableParams: TableParams) => {
    setQueryParams({
      pagination: tableParams.pagination,
      filters: [
        ...(tableParams.filters || []),
        ...(componentQueryParams?.filters || []),
      ],
      sorters: tableParams.sorters || componentQueryParams.sorters,
      includes: componentQueryParams.includes,
      search: tableParams.search
    });
  };

  if (!data) return null;
  return (
    <>
      <Table
        rowKey={"id"}
        columns={driversTableColumns}
        dataSource={data}
        handleTableChange={handleTableChange}
        paginationDefault={DefaultPagination}
        total={data.total}
      />
    </>
  )

}

export default DriversTable;