import React from "react";
import { Typography } from "antd";

const { Text } = Typography;

interface Props {
  children: React.JSX.Element | string; 
  style?: React.CSSProperties;
}

// Figtree Regular 14px
const T3 = ({ children, style }: Props) => ( 
  <Text style={{ margin: 0, padding: 0, fontWeight: 400, ...style }}>{children}</Text>
)

export default T3;
