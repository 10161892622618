import React from 'react';
import { Form, Input, Button, Space, } from 'antd';
import { i18n } from '../../common';
import { H1, T3 } from '../texts';

interface Props {
  onResetPasswordFormFinish?: (values: any) => void;
  onResetPasswordFormFinishFailed?: (errorInfo: any) => void;
}

const ResetPasswordForm = ({ onResetPasswordFormFinish, onResetPasswordFormFinishFailed }: Props) => (
  <Space style={{ maxWidth: 420, minWidth: 360 }} direction='vertical' size={24}>
    <Form
      layout='vertical'
      size='large'
      name='resetPassword'
      onFinish={onResetPasswordFormFinish}
      onFinishFailed={onResetPasswordFormFinishFailed}
    >
      <Form.Item>
        <H1>{i18n.translate(`components.forms.resetPassword.title`)}</H1>
      </Form.Item>
      <Form.Item>
        <T3>{i18n.translate(`components.forms.resetPassword.subtitle`)}</T3>
      </Form.Item>
      <Form.Item
        name="newPassword"
        rules={[{ required: true, message: i18n.translate(`components.forms.resetPassword.required`) }]}
      >
        <Input.Password placeholder={i18n.translate(`components.forms.resetPassword.newPasswordPlaceholder`)} />
      </Form.Item>

      <Form.Item
        name="repeatNewPassword"
        rules={[{ required: true, message: i18n.translate(`components.forms.resetPassword.required`) }]}
      >
        <Input.Password placeholder={i18n.translate(`components.forms.resetPassword.repeatNewPasswordPlaceholder`)} />
      </Form.Item>

      <Form.Item>
        <Button type="primary" size="large" block htmlType="submit">
          {i18n.translate(`components.forms.resetPassword.resetPasswordBtn`)}
        </Button>
      </Form.Item>
    </Form>
  </Space>
);


export default ResetPasswordForm;
