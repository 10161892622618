import React from 'react';
import { QueryParams } from 'src/common';

interface Props {
  orderId?: number;
}

const TrackingTab = ({orderId}: Props) => {

  // const dispatch = useAppDispatch();

  const componentQueryParams: QueryParams = {
    filters: [
      {
        name: 'orderer.id',
        operator: 'eq',
        value: orderId?.toString() as string
      }
    ]
  }

  console.log(componentQueryParams, orderId);

  return (
    <>
      {/** TODO - TrackingTable */}
    </>
  );
}

export default TrackingTab;
