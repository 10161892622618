import { Breadcrumb, Button, Card, Flex, Typography } from "antd";
import React from "react";
import { AddIcon } from "src/components";
import ClientDrawer from "src/components/drawers/client.drawer";
import { openClientDrawerAndSetInitialValues } from "src/common/redux/drawer/drawer.slice";
import { ClientsTable } from "../../../components";
import { i18n, useAppDispatch } from '../../../common';

const ClientsPage = () => {

  const { Title } = Typography;

  const dispatch = useAppDispatch();

  return (
    <>
      <Breadcrumb
        items={[
          { title: i18n.t('common.breadcrumb.clients') }
        ]}
        style={{ marginLeft: 32, marginBottom: 16 }}
      />

      <Card>
        <Flex justify='space-between' align='center' style={{ marginBottom: 16 }}>
          <Title level={2} style={{ margin: 0 }}>
            {i18n.t('pages.clients.title')}
          </Title>

          <Button
            type="primary"
            onClick={() => dispatch(openClientDrawerAndSetInitialValues({ initialValues: []}))}
            icon={<AddIcon />}
          >
            {i18n.t('pages.clients.create')}
          </Button>
        </Flex>
        <ClientsTable></ClientsTable>
        <ClientDrawer></ClientDrawer>
      </Card>

    </>
  );
}

export default ClientsPage;