import React, { useState } from "react";
import { ColumnsType } from "antd/es/table";
import { Button, Dropdown, MenuProps } from "antd";
import { openDepotDrawerAndSetDepot } from "src/common/redux/drawer/drawer.slice";
import { useQuery } from "src/lib/jsonapi-react/src";
import { QueryParams, i18n, DepotInterface, DefaultPagination, FetchParams, useAppDispatch } from "../../common";

import Table, { TableFilter, TableParams, TableSearch } from "./table";

type DepotsTableColumnType = 'name' | 'address' | 'phoneNumber' | 'contactPerson' | 'actions';
type DepotsTableFilterType = 'name' | 'address' | 'phoneNumber' | 'contactPerson' | 'active';

export const allDepotsTableColumns: Array<DepotsTableColumnType> = ['name', 'address', 'phoneNumber', 'contactPerson', 'actions'];
export const allDepotsTableFilters: Array<DepotsTableFilterType> = ['name', 'address', 'phoneNumber', 'contactPerson', 'active'];

interface Props {
  injectedQueryParams?: QueryParams;
  columns?: Array<DepotsTableColumnType>;
  filters?: Array<DepotsTableFilterType>;
}

const DepotsTable = ({ injectedQueryParams, columns, filters }: Props) => {
    
  const componentQueryParams: QueryParams = {
    ...injectedQueryParams,
    includes: [
      ...(injectedQueryParams?.includes || []),
      'address',
      'address.city',
      'deliveryCenterCities'
    ],
    sorters: [
      { columnKey: 'id', order: 'descend', field: 'id' }
    ]
  };

  const [ queryParams, setQueryParams ] = useState<QueryParams>(componentQueryParams);

  const params: FetchParams = {
    type: 'depot',
    queryParams
  };

  const { data, meta } = useQuery(params);

  const dispatch = useAppDispatch();

  const menu = (tableDepot: DepotInterface) => { // 
    const items: MenuProps['items'] = [
      {
        key: '1',
        label: <a onClick={() => dispatch(openDepotDrawerAndSetDepot(tableDepot))}>{i18n.t("components.tables.depots.editAction")}</a>
      }
    ];

    return { items };
  }

  console.log(data, meta)

  const tableColumns: Array<DepotsTableColumnType> = columns || allDepotsTableColumns;

  const depotsTableColumns: ColumnsType<DepotInterface> = [
    {
      title: i18n.t('components.tables.depots.name'),
      dataIndex: 'name',
      key: 'name',
      sorter: true,
      width: 300
    },
    {
      title: i18n.t('components.tables.depots.address'),
      dataIndex: 'address',
      key: 'address',
      render: (dataIndex: string, depot: DepotInterface) => `${depot.address?.city?.name} ${depot.address?.city?.postalCode}, ${depot.address?.street}`,
      width: 300
    },
    {
      title: i18n.t('components.tables.depots.phoneNumber'),
      dataIndex: 'adress.phoneNumber',
      key: 'phoneNumber',
      render: (dataIndex: string, depot: DepotInterface) => depot.address?.phoneNumber,
      sorter: true,
      width: 300
    },
    {
      title: i18n.t('components.tables.depots.contactPerson'),
      dataIndex: 'address.contactPerson',
      key: 'contactPerson',
      render: (dataIndex: string, depot: DepotInterface) => depot.address?.contactPerson,
      sorter: true,
      width: 300
    },
    {
      title: i18n.t('components.tables.depots.actions'),
      dataIndex: 'actions',
      key: 'actions',
      render: (dataIndex: number, depot: DepotInterface) => <Dropdown menu={menu(depot)} trigger={['click']}>
        <a onClick={(e) => {e.preventDefault()}}>
          <Button type="link">. . .</Button>
        </a>
      </Dropdown>,
      width: 50
    }
  ].filter((c) => tableColumns.includes(c.key! as DepotsTableColumnType));

  console.log(depotsTableColumns);

  interface Filter {
    filterKey: DepotsTableFilterType;
    tableFilter: TableFilter;
  }

  const tableFilters: Array<DepotsTableFilterType> = filters || allDepotsTableFilters;
  console.log(tableFilters)

  const depotsTableFilters: Array<Filter> = [];
  //   // { filterKey: 'activeSalesOpportunityStatus' as OrdersTableFilterType, tableFilter: {component: SalesOpportunityStatusFilter, key: 'transientValues.activeSalesOpportunity!.latestOrderState.id' }},
  //   { filterKey: 'orderType' as OrdersTableFilterType, tableFilter: { component: OrderTypeFilter, key: 'orderType.id' } },
  //   { filterKey: 'area' as OrdersTableFilterType, tableFilter: { component: AreaFilter, key: 'areas.id' } },
  //   { filterKey: 'depot' as OrdersTableFilterType, tableFilter: { component: DepotFilter, key: 'depot.id', nullOption: true } },
  //   ...(GuardFunction({ domain: 'order', action: 'view_active' }) ? [{ filterKey: 'active' as OrdersTableFilterType, tableFilter: { component: ActiveFilter, key: 'active' } }] : []),
  // ].filter((f) => tableFilters.includes(f.filterKey as OrdersTableFilterType))

  const searchFields: Array<TableSearch> = [
    // { component: Search, keys: ['name', 'oib'], placeholder: i18n.t('components.tables.orders.searchPlaceholder') }
  ]

  // Potrebno prilagoditi funkciju ovisno o tome koje su sve mogućnosti
  const handleTableChange = (tableParams: TableParams) => {
    setQueryParams({
      pagination: tableParams.pagination,
      filters: [
        ...(tableParams.filters || []),
        ...(componentQueryParams?.filters || []),
      ],
      sorters: tableParams.sorters || componentQueryParams.sorters,
      includes: componentQueryParams.includes,
      search: tableParams.search
    })
  };

  // const handleGroupAction = (selectedRows: Array<DeliveryListInterface>, selectedGroupAction?: number) => {
  //   setSelectedOrders(selectedRows);
  //   groupActions?.find(x => x.key === selectedGroupAction)?.action();
  // };

  if (!data) return null;
  return (
    <>
      <Table
        rowKey={"id"}
        columns={depotsTableColumns}
        dataSource={data}
        handleTableChange={handleTableChange}
        paginationDefault={DefaultPagination}
        total={data.total}
        filters={depotsTableFilters.map((filter: Filter) => filter.tableFilter)}
        searchFields={searchFields}
      />
    </>
  )

}

export default DepotsTable;