import React, { useEffect } from 'react';
import { Flex, Form, Input, Select, Space } from 'antd';
import { resetSubmitUserForm } from 'src/common/redux/drawer/drawer.slice';
import { UserInterface, RootState, i18n, useAppDispatch, useAppSelector, Role, Roles } from '../../common';
import { T1 } from '../texts';


export type UserFormItemType = 'name' | 'email' | 'phoneNumber' | 'dbRole' | 'active';
export type InitialValueTypeUser = { formItem: UserFormItemType, value: string | number | boolean };

export const allUserFormItems: Array<UserFormItemType> = ['name', 'email', 'phoneNumber', 'dbRole', 'active'];

const { Option } = Select;

interface Props {
  onUserFormFinish?: (values: any) => void;
  user?: UserInterface;
  formItems?: Array<UserFormItemType>;
  disabled?: Array<UserFormItemType>;
  initialValues?: Array<InitialValueTypeUser>;
  submitBtnLabel?: string;
}

function UserForm({ onUserFormFinish, user, formItems, disabled, initialValues }: Props) {
  
  const dispatch = useAppDispatch();

  const [form] = Form.useForm();

  const submitForm = useAppSelector((state: RootState) => state.state.drawer.user.submit);

  const onFormSubmit = (values: any) => {
    const data = {
      ...values,
      roles: [{id: values.dbRole}]
    }
    if (onUserFormFinish) onUserFormFinish(data);
  }

  const items: Array<UserFormItemType> = formItems || allUserFormItems;

  const initialFormValues = {
    ...user,
    name: (initialValues?.find((fi: InitialValueTypeUser) => fi.formItem === 'name')?.value as string) || user?.name,
    email: (initialValues?.find((fi: InitialValueTypeUser) => fi.formItem === 'email')?.value as string) || user?.email,
    phoneNumber: (initialValues?.find((fi: InitialValueTypeUser) => fi.formItem === 'phoneNumber')?.value as string) || user?.phoneNumber,
    dbRole: (initialValues?.find((fi: InitialValueTypeUser) => fi.formItem === 'dbRole')?.value as number) || user?.roles![0].id,
  }

  useEffect(() => {
    if (submitForm) {
      form.submit();
      dispatch(resetSubmitUserForm());
    }
  }, [submitForm]);

  return (<>
    <Form
      name='user'
      form={form}
      onFinish={onFormSubmit}
      initialValues={initialFormValues}
      layout={'vertical'}
    >
      <Flex gap={'16px'} vertical>
        <Form.Item
          name="name"
          label={<T1>{i18n.translate(`components.forms.user.name`)}</T1>}
          rules={[{ required: true, message: i18n.translate(`components.forms.common.required`) }]}
          hidden={!items.find(i => i === 'name')}
        >
          <Input disabled={!!disabled?.find(d => d === 'name')} />
        </Form.Item>
        <Form.Item
          name="email"
          label={<T1>{i18n.translate(`components.forms.user.email`)}</T1>}
          rules={[{ required: true, message: i18n.translate(`components.forms.common.required`) }]}
          hidden={!items.find(i => i === 'email')}
        >
          <Input disabled={!!disabled?.find(d => d === 'email')} />
        </Form.Item>
        <Form.Item
          name="phoneNumber"
          label={<T1>{i18n.translate(`components.forms.user.phoneNumber`)}</T1>}
          rules={[{ required: true, message: i18n.translate(`components.forms.common.required`) }]}
          hidden={!items.find(i => i === 'phoneNumber')}
        >
          <Input disabled={!!disabled?.find(d => d === 'phoneNumber')} />
        </Form.Item>
        <Form.Item
          name="dbRole"
          label={<T1>{i18n.translate(`components.forms.user.dbRole`)}</T1>}
          rules={[{ required: true, message: i18n.translate(`components.forms.common.required`) }]}
          hidden={!items.find(i => i === 'dbRole')}
        >
          <Select
            disabled={!!disabled?.find(d => d === 'dbRole')}
          >
            {Roles().map((role: Role) =>
              <Option key={role.id} value={role.id.toString()}>
                <Space style={{ display: 'flex' }}>
                  {role.name}
                </Space>
              </Option>
            )}
          </Select>
        </Form.Item>
      </Flex>
    </Form>
  </>
  );
}

export default UserForm;
