import { Flex, Grid, Layout, Menu, theme } from 'antd';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import Logo from '../../assets/images/logo.svg';
import Logoaa from '../../assets/images/logo-aa.png';
import i18n from '../../common/services/translate.service';
import { AppRoutes } from '../../pages/app/_router/app.routes';
import { ClientsIcon, OrderIcon, TruckIcon, SettingsIcon, WarehouseIcon, DriversIcon, RouteIcon } from '../icons';
import { colors } from '../../theme';
import { RootState, useAppSelector } from '../../common';
import { H3 } from '../texts';

const { useToken } = theme;
const { useBreakpoint } = Grid;

const Sider = () => {
  const { token } = useToken();

  const navigate = useNavigate();
  const { lg } = useBreakpoint();

  const collapsed = useAppSelector((store: RootState) => store.state.layout.sider.collapsed);

  const navItems = [
    {
      key: AppRoutes.ORDERS.path,
      icon: <OrderIcon />,
      label: <H3 style={{ color: 'inherit' }}>{i18n.translate(`components.layout.sider.menu.orders`)}</H3>,
      onClick: () => navigate(AppRoutes.ORDERS.fullPath)
    },
    {
      key: AppRoutes.DELIVERY_LISTS.path,
      icon: <TruckIcon />,
      label: <H3 style={{ color: 'inherit' }}>{i18n.translate(`components.layout.sider.menu.deliveryLists`)}</H3>,
      onClick: () => navigate(AppRoutes.DELIVERY_LISTS.fullPath)
    },
    {
      key: AppRoutes.DRIVERS.path,
      icon: <DriversIcon />,
      label: <H3 style={{ color: 'inherit' }}>{i18n.translate(`components.layout.sider.menu.drivers`)}</H3>,
      onClick: () => navigate(AppRoutes.DRIVERS.fullPath)
    },
    {
      key: AppRoutes.DEPOT.path,
      icon: <WarehouseIcon />,
      label: <H3 style={{ color: 'inherit' }}>{i18n.translate(`components.layout.sider.menu.depot`)}</H3>,
      onClick: () => navigate(AppRoutes.DEPOT.fullPath)
    },
    {
      key: AppRoutes.TRACKING.path,
      icon: <RouteIcon />,
      label: <H3 style={{ color: 'inherit' }}>{i18n.translate(`components.layout.sider.menu.tracking`)}</H3>,
      onClick: () => navigate(AppRoutes.TRACKING.fullPath)
    },    
    {
      key: AppRoutes.CLIENTS.path,
      icon: <ClientsIcon />,
      label: <H3 style={{ color: 'inherit' }}>{i18n.translate(`components.layout.sider.menu.clients`)}</H3>,
      onClick: () => navigate(AppRoutes.CLIENTS.path)
    },
    {
      key: AppRoutes.USERS.path,
      icon: <ClientsIcon />,
      label: <H3 style={{ color: 'inherit' }}>{i18n.translate(`components.layout.sider.menu.users`)}</H3>,
      onClick: () => navigate(AppRoutes.USERS.path)
    },
    {
      key: AppRoutes.APPLICATION_SETTINGS.path,
      icon: <SettingsIcon />,
      label: <H3 style={{ color: 'inherit' }}>{i18n.translate(`components.layout.sider.menu.applicationSettings`)}</H3>,
      onClick: () => navigate(AppRoutes.APPLICATION_SETTINGS.path)
    },
  ].filter(x => x !== null);

  const getDefaultSelectedKeys = (): string[] | undefined => {
    const itemKey = window.location.pathname.split('/')[1];
    for (const navItem of navItems) {
      if (navItem?.key === itemKey) return [navItem.key];
    }
    return [AppRoutes.ORDERS.path]
  }

  return (
    <Layout.Sider
      style={{
        height: '100vh',
        position: 'fixed',
        left: 0,
        top: 0,
        bottom: 0,
        zIndex: 999,
        background: colors.white,
        boxShadow: token.boxShadow
      }}
      width={263}
      collapsible
      collapsedWidth={lg ? '80px' : 0}
      trigger={null}
      collapsed={collapsed}
    >

      <Flex justify='center' align='center' style={{ minHeight: 64 }}>
        {!collapsed ? <img height={20} src={Logo} alt="" /> : <img height={20} src={Logoaa} alt="" />}
      </Flex>

      <Menu
        mode="inline"
        defaultSelectedKeys={getDefaultSelectedKeys()}
        items={navItems}
      />
    </Layout.Sider>
  );
}

export default Sider;
