import React from "react";
import { Typography } from "antd";

const { Text } = Typography;

interface Props {
  children: React.JSX.Element | string; 
  style?: React.CSSProperties;
}

// Figtree Bold 14px
const T1 = ({ children, style }: Props) => ( 
  <Text style={{ margin: 0, padding: 0, fontWeight: 700, ...style }}>{children}</Text>
)

export default T1;
