import { Breadcrumb, Button, Card, Flex, Typography } from "antd";
import React from "react";
import { AddIcon } from "src/components";
import { DriversTable } from "../../../components";
import { i18n } from '../../../common';


const DriversPage = () => {

  const { Title } = Typography;

  return (
    <>
      <Breadcrumb
        items={[
          { title: i18n.t('common.breadcrumb.drivers') }
        ]}
        style={{ marginLeft: 32, marginBottom: 16 }}
      />
      
      <Card>
        <Flex justify='space-between' align='center' style={{ marginBottom: 16 }}>
          <Title level={2} style={{ margin: 0}}>
            {i18n.t('pages.drivers.title')}
          </Title>

          <Button
            type="primary"
            icon={<AddIcon />}
          >
            {i18n.t('pages.drivers.create')}
          </Button>
        </Flex>
        <DriversTable></DriversTable>
      </Card>
      
    </>
  );
}

export default DriversPage;