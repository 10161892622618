import { Grid, Layout } from 'antd';
import React from 'react';
import { Outlet } from 'react-router-dom';
import LoginImage from '../../../assets/images/loginImage.png';

const { Sider, Content } = Layout;
const { useBreakpoint } = Grid;

const AuthRouter = () => {
  const { xl } = useBreakpoint();

  return (
    <Layout style={{ minHeight: '100vh' }} hasSider>
      <Sider
        style={{
          height: '100vh',
          position: 'fixed',
          left: 0,
          top: 0,
          bottom: 0,
          backgroundImage: `url(${LoginImage})`,
          backgroundPosition: 'left',
          backgroundRepeat: 'no-repeat',
          backgroundSize: 'cover'
        }}
        width={'35%'}
        breakpoint='xl'
        collapsedWidth="0"
      />
      <Content style={{ marginLeft: xl ? '35%' : '0' }}>
        <Outlet />
      </Content>
    </Layout>
  )
}

export default AuthRouter;
