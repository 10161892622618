import { Breadcrumb, Card, Flex, Typography } from "antd";
import React from "react";
import { OrdersTable } from "../../../components";
import { i18n } from '../../../common';


const DepotPage = () => {

  const { Title } = Typography;

  return (
    <>
      <Breadcrumb
        items={[
          { title: i18n.t('common.breadcrumb.depot') }
        ]}
        style={{ marginLeft: 32, marginBottom: 16 }}
      />

      <Card>
        <Flex justify='space-between' align='center' style={{ marginBottom: 16 }}>
          <Title level={2} style={{ margin: 0 }}>
            {i18n.t('pages.depot.title')}
          </Title>
        </Flex>

        <OrdersTable></OrdersTable>

      </Card>

    </>
  );
}

export default DepotPage;