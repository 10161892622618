import { Breadcrumb, Button, Card, Flex, Tabs, TabsProps } from 'antd';
import React from 'react'

import { useParams } from 'react-router-dom';

import { EditIcon, H2, L1, T1 } from 'src/components';

import { useQuery } from "src/lib/jsonapi-react/src";

import { i18n, FetchParams, QueryParams, OrderInterface } from '../../../common';
import { AppRoutes } from '../_router/app.routes';
import { DetailsTab, SpecificationTab, TrackingTab } from './tabs';

const OrderPage = () => {
  const params = useParams();

  const queryParams: QueryParams = {
    includes: [
      'orderer',
      'sender',
      'senderAddress',
      'receiver',
      'receiverAddress',
      'latestOrderState'
    ]
  }

  const fetchParams: FetchParams = {
    type: 'order',
    id: parseInt(params.order_id!, 10),
    queryParams
  };

  const { data } = useQuery(fetchParams);

  const order: OrderInterface = data;
  
  const tabItems: TabsProps['items'] = [
    {
      key: '1',
      label: <T1 style={{ color: 'inherit' }}>{i18n.translate('pages.order.tabs.details')}</T1>,
      children: <DetailsTab order={order} />,
    },
    {
      key: '2',
      label: <T1 style={{ color: 'inherit' }}>{i18n.translate('pages.order.tabs.specification')}</T1>,
      children: <SpecificationTab orderId={order?.id} />,
    },
    {
      key: '3',
      label: <T1 style={{ color: 'inherit' }}>{i18n.translate('pages.order.tabs.tracking')}</T1>,
      children: <TrackingTab orderId={order?.id} />,
    },
  ];

  return (
    <>
      <Breadcrumb
        items={[
          { title: <L1 to={AppRoutes.ORDERS.fullPath}>{i18n.t('common.breadcrumb.orders')}</L1> },
          { title: i18n.t('common.breadcrumb.orderView') }
        ]}
        style={{ marginLeft: 32, marginBottom: 16 }}
      />
      <Card>
        <Flex justify='space-between' align='center' style={{ marginBottom: 16 }}>
          <H2>{i18n.t('pages.order.title')} {order?.uuid}</H2>

          <Button
            type="primary"
            icon={<EditIcon />}
          >
            {i18n.t('pages.order.edit')}
          </Button>
        </Flex>

        <Tabs defaultActiveKey="1" items={tabItems} destroyInactiveTabPane />
      </Card>
    </>
  );
}

export default OrderPage;
