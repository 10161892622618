import { ApiClient } from 'src/lib/jsonapi-react/src';
import { Observable, from } from 'rxjs';
import schema from '../../schema.json';
import { store } from '../redux';
import { QueryParams, Filter, Sorter } from './queryParams.interface';


export type FilterType = { [index: string]: { [index: string]: string } };

export type JsonApiType = 'activity' | 'activity_status' | 'activity_type' | 'announcement' | 'announcement_seen' | 'application_settings' | 'area' | 'assignment' | 'assignment_status' | 'assignment_type' | 'campaign' | 'campaign_status' | 'client' | 'client_type' | 'contact' | 'file' | 'functions' | 'news' | 'news_seen' | 'offer' | 'offer_item' | 'priority_type' | 'reminder' | 'reservation' | 'reservation_option' | 'reservation_category' | 'role' | 'sales_opportunity' | 'sales_opportunity_status' | 'user' | 'user_settings' | 'user_type' | 'services';

export type JsonApiParams = { filter?: FilterType, include?: Array<string>, page?: { number: number, size: number }, sort?: Array<string> };

export function getJsonApiParams(
  queryParams: QueryParams
): JsonApiParams {
  const jsonApiParams: JsonApiParams = {};
  if (queryParams.filters) {
    let jsonApiFilters = {}
    queryParams.filters.forEach((filter: Filter) => {
      const filterValue: string = Array.isArray(filter.value) ? filter.value.join(',') : filter.value;
      jsonApiFilters = {
        ...jsonApiFilters,
        [filter.name]: {
          [filter.operator]: filterValue
        }
      }
    })
    jsonApiParams.filter = jsonApiFilters;
  }

  if (queryParams.includes) {
    jsonApiParams.include = queryParams.includes;
  }

  if (queryParams.pagination && queryParams.pagination.current && queryParams.pagination.pageSize) {
    jsonApiParams.page = {
      number: queryParams.pagination.current,
      size: queryParams.pagination.pageSize
    }
  }

  if (queryParams.sorters) {
    jsonApiParams.sort = queryParams.sorters.map((sorter: Sorter) => (
      sorter.order === 'desc' ? `-${sorter.columnKey}` : sorter.columnKey
    ))
  }

  return jsonApiParams;
}

export function getUrl(type: JsonApiType, id?: number, queryParams?: QueryParams): string {
  let url = `/${type}`;

  if (id) url += `/${id}`;

  if (queryParams) {
    let params = '?';
    if (queryParams.filters) {
      queryParams.filters.forEach((filter: Filter) => {
        if (params !== '?') params += '&';
        // potrebno dodati % na kraj ako se radi o LIKE
        const filterKey = `filter[${filter.name}][${filter.operator}]`;
        const filterValue: string = Array.isArray(filter.value) ? filter.value.join(',') : filter.value;
        // params += `${encodeURIComponent(filterKey)}=${encodeURIComponent(filterValue)}`;
        params += `${filterKey}=${filterValue}`;
      });
    }

    if (queryParams.includes) {
      if (params !== '?') params += '&';
      params += 'include=';
      params += queryParams.includes.join();
    }

    if (queryParams.pagination) {
      if (queryParams.pagination.current) {
        if (params !== '?') params += '&';
        // params += `${encodeURIComponent('page[number]')}=${encodeURIComponent(queryParams.pagination.current)}`;
        params += `page[number]=${queryParams.pagination.current}`;
      }
      if (queryParams.pagination.pageSize) {
        if (params !== '?') params += '&';
        // params += `${encodeURIComponent('page[size]')}=${encodeURIComponent(queryParams.pagination.pageSize)}`;
        params += `page[size]=${queryParams.pagination.pageSize}`;
      }
    }

    if (queryParams.sorters) {
      if (params !== '?') params += '&';
      queryParams.sorters.forEach((sorter: Sorter) => {
        const sortKey = sorter.order === 'descend' ? `-${sorter.columnKey}` : sorter.columnKey;
        // params += `${encodeURIComponent('sort')}=${encodeURIComponent(sortKey)}`;
        params += `sort=${sortKey}`;
      })
    }

    if (queryParams.search) {
      const searchValue = queryParams.search.value;
      const searchFields: Array<string> = [];
      queryParams.search.keys.forEach((key: string) => {
        if (key.indexOf('.') < 0)
          searchFields.push(`{"LIKE":{"${key}":"${searchValue}%"}}`);
        else {
          const split = key.split('.');
          searchFields.push(`{"LIKE":{"${split[0]}":{"${split[1]}":"${searchValue}%"}}}`);
        }
      })

      if (params !== '?') params += '&';
      // params += `filter=${encodeURI('{"OR":{"LIKE":{"email":"Ad%"}}}')}`;
      params += `filter=${encodeURIComponent('{"OR":[')}`;
      params += `${encodeURIComponent(searchFields.join())}`;
      params += `]}`;
    }

    url += params;
  }

  return url;
}

let instance: JsonApiService;

export class JsonApiService {

  private client: ApiClient | null = null;

  public static getInstance() {
    if (!instance) instance = new JsonApiService();
    return instance;
  }

  public getClient() {
    return this.client || this.initClient();
  }

  public initClient() {
    const config: any = {
      url: process.env.REACT_APP_API_URL,
      // cacheTime: 5 * 60,
      // staleTime: 60,
      ssrMode: false,
      headers: { 'Authorization': `Bearer ${store.getState().model.auth.accessToken}`, 'X-TenantUUID': store.getState().model.auth.xTenantUuid },
      schema,
    };
    this.client = new ApiClient(config);
    return this.client;
  }

  // example
  // const q: QueryParams = {
  //   filters: [{ name: 'id', operator: 'EQ', value: '1' }, { name: 'users.id', operator: 'EQ', value: '2' }, { name: 'username', operator: 'LIKE', value: 'Neki' }],
  //   pagination: { current: 1, pageSize: 10 },
  //   includes: ['assignment', 'dbRole'],
  //   sorters: [{ columnKey: 'user.id' }, { columnKey: 'role', order: 'desc' }]
  // }
  public static get(type: JsonApiType, id?: number, queryParams?: QueryParams): Observable<any> {
    const client = this.getInstance().getClient();

    const url = getUrl(type, id, queryParams);

    // const jsonApiParams: JsonApiParams | undefined = queryParams && getJsonApiParams(queryParams);
    // const fetchParams: any = [type];
    // if (id) fetchParams.push(id);
    // if (jsonApiParams) fetchParams.push(jsonApiParams);

    return from(
      client.fetch(url)
        .then((response: any) => {

          if (response.error) {
            throw response;
          }

          if (response.statusCode === 401) {
            localStorage.clear();
            setTimeout(() => {
              window.location.href = '/';
            }, 100);
            throw response;
          }
          return response;
        })
        .catch((error: any) => {
          throw error;
        })
    )
  }

  public static patch(type: JsonApiType, id: number, data: any, queryParams?: QueryParams): Observable<any> {
    const client = this.getInstance().getClient();
    const jsonApiParams: JsonApiParams | undefined = queryParams && getJsonApiParams(queryParams);
    const mutateParams: any = [type];
    if (id) mutateParams.push(id);
    if (jsonApiParams) mutateParams.push(jsonApiParams);
    return from(
      client.mutate(mutateParams, data)
        .then((response: any) => {

          if (response.error) {
            console.error(response); // eslint-disable-line no-console
            throw new Error(response.error.detail);
          }
          return response;
        })
        .catch((error: any) => {
          throw error;
        })
    )
  }

  public static post(type: JsonApiType, data: any, queryParams?: QueryParams): Observable<any> {
    const client = this.getInstance().getClient();
    const jsonApiParams: JsonApiParams | undefined = queryParams && getJsonApiParams(queryParams);
    const mutateParams: any = [type];
    if (jsonApiParams) mutateParams.push(jsonApiParams);
    return from(
      client.mutate(mutateParams, data)
        .then((response: any) => {

          if (response.error) {
            console.error(response); // eslint-disable-line no-console
            throw new Error(response.error.detail);
          }
          return response;
        })
        .catch((error: any) => {
          throw error;
        })
    )
  }
}
