import { RouteInterface } from '../../../common';

import OrdersPage from '../orders/orders.page';
import OrderPage from '../orders/order.page';
import OrderCreatePage from '../orders/orderCreate.page';
import OrderEditPage from '../orders/orderEdit.page';
import DeliveryListsPage from '../deliveryLists/deliveryLists.page';
import DeliveryListPage from '../deliveryLists/deliveryList.page';
import DeliveryListCreatePage from '../deliveryLists/deliveryListCreate.page';
import DeliveryListEditPage from '../deliveryLists/deliveryListEdit.page';
import DriversPage from '../drivers/drivers.page';
import UsersPage from '../users/users.page';
import TrackingPage from '../tracking/tracking.page';
import ApplicationSettingsPage from '../applicationSettings/applicationSettings.page';
import DepotPage from '../depot/depot.page';
import ClientsPage from '../clients/clients.page';
import ClientPage from '../clients/client.page';
import { AppRoutes } from './app.routes';

const AppRouting: Array<RouteInterface> = [
  {
    path: AppRoutes.ORDERS.fullPath,
    element: OrdersPage,
    functions: []
  },
  {
    path: `${AppRoutes.ORDERS.fullPath}/:order_id`,
    element: OrderPage,
    functions: []
  },
  {
    path: `${AppRoutes.ORDERS.fullPath}/:order_id/edit`,
    element: OrderEditPage,
    functions: []
  },
  {
    path: `${AppRoutes.ORDERS.fullPath}/new`,
    element: OrderCreatePage,
    functions: []
  },
  {
    path: AppRoutes.DELIVERY_LISTS.fullPath,
    element: DeliveryListsPage,
    functions: []
  },
  {
    path: `${AppRoutes.DELIVERY_LISTS.fullPath}/:delivery_list_id`,
    element: DeliveryListPage,
    functions: []
  },
  {
    path: `${AppRoutes.DELIVERY_LISTS.fullPath}/:delivery_list_id/edit`,
    element: DeliveryListEditPage,
    functions: []
  },
  {
    path: `${AppRoutes.DELIVERY_LISTS.fullPath}/new`,
    element: DeliveryListCreatePage,
    functions: []
  },
  {
    path: AppRoutes.DRIVERS.fullPath,
    element: DriversPage,
    functions: []
  },
  {
    path: AppRoutes.DEPOT.fullPath,
    element: DepotPage,
    functions: []
  },
  {
    path: AppRoutes.TRACKING.fullPath,
    element: TrackingPage,
    functions: []
  },  
  {
    path: AppRoutes.CLIENTS.fullPath,
    element: ClientsPage,
    functions: []
  },
  {
    path: `${AppRoutes.CLIENTS.fullPath}/:client_id`,
    element: ClientPage,
    functions: []
  },
  {
    path: AppRoutes.USERS.fullPath,
    element: UsersPage,
    functions: []
    // Example: functions: [{ domain: 'user', action: 'view_all_any' }]
  },
  {
    path: AppRoutes.APPLICATION_SETTINGS.fullPath,
    element: ApplicationSettingsPage,
    functions: []
    // Example: functions: [{ domain: 'user', action: 'view_all_any' }]
  },
];

export default AppRouting;