import React from "react";
import { Typography } from "antd";

const { Title } = Typography;

interface Props {
  children: React.JSX.Element | string; 
  style?: React.CSSProperties;
}

// Figtree SemiBold 24px
const H1 = ({ children, style }: Props) => ( 
  <Title level={1} style={{ margin: 0, padding: 0, fontWeight: 600, ...style }}>{children}</Title>
)

export default H1;
