import { Layout } from 'antd';
import React from 'react';

interface Props {
  children: React.ReactNode;
  style?: React.CSSProperties;
}

const Content = ({ children, style }: Props) =>
  <Layout.Content style={{ ...style }}>{children}</Layout.Content>;

export default Content;
