import React from 'react';
import {DatePicker, Flex, Form, Select } from 'antd';
// import { resetSubmitDeliveryListForm } from 'src/common/redux/drawer/drawer.slice';
import { DeliveryListInterface, i18n } from '../../common';
import { H3, T1 } from '../texts';
import { OrdersFormTable } from '../tables';


export type DeliveryListFormItemType = 'driver' | 'pickupDate' | 'vehicle' | 'orders' | 'active';
export type InitialValueTypeDeliveryList = { formItem: DeliveryListFormItemType, value: string | number | boolean };

export const allDeliveryListFormItems: Array<DeliveryListFormItemType> = ['driver', 'pickupDate', 'vehicle', 'orders', 'active'];

interface Props {
  onDeliveryListFormFinish?: (values: any) => void;
  deliveryList?: DeliveryListInterface;
  formItems?: Array<DeliveryListFormItemType>;
  disabled?: Array<DeliveryListFormItemType>;
  initialValues?: Array<InitialValueTypeDeliveryList>;
  submitBtnLabel?: string;
}

function DeliveryListForm({ onDeliveryListFormFinish, deliveryList, formItems, disabled, initialValues }: Props) {

  // TODO - Pravilno mapirati po novim BE entitetima
  
  // const dispatch = useAppDispatch();

  const [form] = Form.useForm();

  // const submitForm = useAppSelector((state: RootState) => state.state.drawer.deliveryList.submit);

  const onFormSubmit = (values: any) => {
    const data = {
      ...values,
      roles: [{id: values.dbRole}]
    }
    if (onDeliveryListFormFinish) onDeliveryListFormFinish(data);
  }

  const items: Array<DeliveryListFormItemType> = formItems || allDeliveryListFormItems;

  const initialFormValues = {
    ...deliveryList,
    name: (initialValues?.find((fi: InitialValueTypeDeliveryList) => fi.formItem === 'driver')?.value as string) || deliveryList?.driver
  }

  /**
  useEffect(() => {
    if (submitForm) {
      form.submit();
      // dispatch(resetSubmitDeliveryListForm());
    }
  }, [submitForm]);
  */

  return (<>
    <Form
      name='deliveryList'
      form={form}
      onFinish={onFormSubmit}
      initialValues={initialFormValues}
      layout={'vertical'}
    >
      <Flex gap={'16px'} vertical>
        <H3>{i18n.translate(`components.forms.deliveryList.orderDetails`)}</H3>
        <Flex gap={'16px'} wrap='wrap'>
          <Form.Item
            name="driver"
            label={<T1>{i18n.translate(`components.forms.deliveryList.driver`)}</T1>}
            rules={[{ required: true, message: i18n.translate(`components.forms.common.required`) }]}
            hidden={!items.find(i => i === 'driver')}
          >
            <Select
              style={{ width: 220 }}
              disabled={!!disabled?.find(d => d === 'driver')}
            >
            </Select>
          </Form.Item>
          <Form.Item
            style={{ width: 220 }}
            name="pickupDate"
            label={<T1>{i18n.translate(`components.forms.deliveryList.pickupDate`)}</T1>}
            rules={[{ required: true, message: i18n.translate(`components.forms.common.required`) }]}
            hidden={!items.find(i => i === 'pickupDate')}
          >
            <DatePicker style={{ width: '100%' }} />
          </Form.Item>
          <Form.Item
            name="vehicle"
            label={<T1>{i18n.translate(`components.forms.deliveryList.vehicle`)}</T1>}
            rules={[{ required: true, message: i18n.translate(`components.forms.common.required`) }]}
            hidden={!items.find(i => i === 'vehicle')}
          >
            <Select
              style={{ width: 220 }}
              disabled={!!disabled?.find(d => d === 'vehicle')}
            >
            </Select>
          </Form.Item>
        </Flex>
      </Flex>
      <Flex gap={'16px'} vertical>
        <H3>{i18n.translate(`components.forms.deliveryList.orders`)}</H3>
        <Form.Item
          name="orders"
          hidden={!items.find(i => i === 'orders')}
        >
          <OrdersFormTable></OrdersFormTable>
        </Form.Item>
      </Flex>
    </Form>
  </>
  );
}

export default DeliveryListForm;
