import { ParcelTypeEnum } from "../enums";
import { i18n } from "../services";

export interface ParcelType {
  code: string;
  label: string;
}

export const ParcelTypes = (): Array<ParcelType> => [
  { code: ParcelTypeEnum.STANDARD, label: i18n.translate("common.constants.parcelType.standard") },
  { code: ParcelTypeEnum.NONSTANDARD, label: i18n.translate("common.constants.parcelType.nonstandard") },
  { code: ParcelTypeEnum.PALLET, label: i18n.translate("common.constants.parcelType.pallet") }
]

export const getParcelTypeLabel = (code: string): string =>
  ParcelTypes().find((pt: ParcelType) => pt.code === code) ? ParcelTypes().find((pt: ParcelType) => pt.code === code)!.label : "";
