import { Breadcrumb, Card, Space, Tabs, TabsProps, Typography } from 'antd';
import React, { useState } from 'react';
import { useQuery } from 'src/lib/jsonapi-react/src';
import { ClientInterface, FetchParams, QueryParams, RootState, i18n, useAppSelector } from 'src/common';
import { T1 } from 'src/components';
import { CompanyTab, DepotsTab, EmployeesTab, VehiclesTab } from './tabs';

const { Title } = Typography;

const ApplicationSettingsPage = () => {

  const userAuth = useAppSelector((store: RootState) => store.model.auth);

  const componentQueryParams: QueryParams = {
    /** ovi includeovi isto bacaju 403
    includes: [
      'addresses',
      'addresses.city'
    ],
    */
    filters: [
      {
        name: 'employees.id',
        operator: 'eq',
        value: userAuth?.user?.id!.toString() as string
      }
    ]
  }

  const [queryParams] = useState<QueryParams>(componentQueryParams);

  const params: FetchParams = {
    type: 'client',
    queryParams
  };

  const { data } = useQuery(params);
  // TODO promijeniti na single dohvat po id-ju nakon što se BE popravi
  const client: ClientInterface = data?.length ? data[0] : undefined;

  const tabItems: TabsProps['items'] = [
    {
      key: '1',
      label: <T1 style={{ color: 'inherit' }}>{i18n.translate('pages.applicationSettings.tabs.companyDetails')}</T1>,
      children: <CompanyTab client={client} />,
    },
    {
      key: '2',
      label: <T1 style={{ color: 'inherit' }}>{i18n.translate('pages.applicationSettings.tabs.employees')}</T1>,
      children: <EmployeesTab clientId={client?.id} />,
    },
    {
      key: '3',
      label: <T1 style={{ color: 'inherit' }}>{i18n.translate('pages.applicationSettings.tabs.vehicles')}</T1>,
      children: <VehiclesTab clientId={client?.id} />,
    },
    {
      key: '4',
      label: <T1 style={{ color: 'inherit' }}>{i18n.translate('pages.applicationSettings.tabs.depots')}</T1>,
      children: <DepotsTab />,
    },
    {
      key: '5',
      label: <T1 style={{ color: 'inherit' }}>{i18n.translate('pages.applicationSettings.tabs.logs')}</T1>,
      children: null,
    },
  ];

  return (
    <>
      <Breadcrumb
        items={[
          { title: i18n.t('common.breadcrumb.applicationSettings') }
        ]}
        style={{ marginLeft: 32, marginBottom: 16 }}
      />
      <Card>
        <Space direction="vertical" size={16} style={{ display: 'flex' }}>
          <Title level={2} style={{ margin: 0 }}>{i18n.translate('pages.applicationSettings.title')}</Title>
          <Tabs defaultActiveKey="1" items={tabItems} destroyInactiveTabPane />
        </Space>
      </Card>
    </>
  );
};

export default ApplicationSettingsPage;
