import React, { useEffect } from 'react';
import { Space, notification, Flex } from 'antd';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { ResetPasswordInterface, resetPassword, i18n, useAppDispatch } from '../../../common';
import Logo from '../../../assets/images/logo.svg';
import { AuthRoutes } from '../_router/auth.routes';
import { ResetPasswordForm } from '../../../components';

const ResetPasswordPage = () => {

  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const [searchParams] = useSearchParams();

  useEffect(() => {
    if (!searchParams.get('key') || !searchParams.get('email')) {
      navigate(AuthRoutes.LOGIN.fullPath);
    }
  }, [])

  const onResetPassword = (values: any) => {
    if (values.newPassword !== values.repeatNewPassword) {
      notification.error({ message: i18n.translate('components.forms.resetPassword.passwordNoMatch'), duration: 2 });
      return;
    }

    const resetPasswordData: ResetPasswordInterface = {
      email: searchParams.get('email')!,
      key: searchParams.get('key')!,
      newPassword: values.newPassword
    };
    dispatch(resetPassword(resetPasswordData));
  };

  const onResetPasswordFailed = (errorInfo: any) => {
    console.log('Failed:', errorInfo);
  };

  return (
    <Flex justify='center' align='center' style={{ height: '100%' }}>
      <Space style={{ maxWidth: 420, minWidth: 360 }} direction='vertical' size={24}>
        <img width={'auto'} height={24} src={Logo} alt="" />
        <ResetPasswordForm onResetPasswordFormFinish={(values) => onResetPassword(values)} onResetPasswordFormFinishFailed={(errorInfo) => onResetPasswordFailed(errorInfo)}></ResetPasswordForm>
      </Space>
    </Flex>

  );
}

export default ResetPasswordPage;
