import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { ClientInterface, DepotInterface, UserInterface, VehicleInterface } from "src/common/interfaces";
import { InitialValueTypeClient } from "src/components/forms/client.form";
import { InitialValueTypeDepot } from "src/components/forms/depot.form";
import { InitialValueTypeUser } from "src/components/forms/user.form";
import { InitialValueTypeVehicle } from "src/components/forms/vehicle.form";


export interface DrawerStateInterface {
  client: {
    open: boolean,
    data?: ClientInterface,
    submit: boolean,
    initialValues?: Array<InitialValueTypeClient>,
    id?: number
  },
  user: {
    open: boolean,
    data?: UserInterface,
    submit: boolean,
    initialValues?: Array<InitialValueTypeUser>,
    id?: number
  },
  vehicle: {
    open: boolean,
    data?: VehicleInterface,
    submit: boolean,
    initialValues?: Array<InitialValueTypeVehicle>,
    id?: number
  },
  depot: {
    open: boolean,
    data?: DepotInterface,
    submit: boolean,
    initialValues?: Array<InitialValueTypeDepot>,
    id?: number
  },
}

const initialState: DrawerStateInterface = {
  client: {
    open: false,
    submit: false
  },
  user: {
    open: false,
    submit: false
  },
  vehicle: {
    open: false,
    submit: false
  },
  depot: {
    open: false,
    submit: false
  }
}

export const drawerSlice = createSlice({
  name: 'drawer',
  initialState,
  reducers: {
    openClientDrawerAndSetInitialValues: (state: DrawerStateInterface, action: PayloadAction<{initialValues: Array<InitialValueTypeClient>}>) => {
      state.client.open = true;
      state.client.initialValues = action.payload.initialValues;
    },
    openClientDrawerAndSetClient: (state: DrawerStateInterface, action: PayloadAction<ClientInterface>) => {
      state.client.open = true;
      state.client.data = action.payload;
      state.client.id = action.payload.id;
    },
    closeClientDrawer: (state: DrawerStateInterface) => {
      state.client.open = false;
      state.client.data = undefined;
      state.client.initialValues = undefined;
      state.client.id = undefined;
    },
    submitClientForm: (state: DrawerStateInterface) => {
      state.client.submit = true;
    },
    resetSubmitClientForm: (state: DrawerStateInterface) => {
      state.client.submit = false;
    },
    openUserDrawerAndSetInitialValues: (state: DrawerStateInterface, action: PayloadAction<{initialValues: Array<InitialValueTypeUser>}>) => {
      state.user.open = true;
      state.user.initialValues = action.payload.initialValues;
    },
    openUserDrawerAndSetUser: (state: DrawerStateInterface, action: PayloadAction<UserInterface>) => {
      state.user.open = true;
      state.user.data = action.payload;
      state.user.id = action.payload.id;
    },
    closeUserDrawer: (state: DrawerStateInterface) => {
      state.user.open = false;
      state.user.data = undefined;
      state.user.initialValues = undefined;
      state.user.id = undefined;
    },
    submitUserForm: (state: DrawerStateInterface) => {
      state.user.submit = true;
    },
    resetSubmitUserForm: (state: DrawerStateInterface) => {
      state.user.submit = false;
    },
    openVehicleDrawerAndSetInitialValues: (state: DrawerStateInterface, action: PayloadAction<{initialValues: Array<InitialValueTypeVehicle>}>) => {
      state.vehicle.open = true;
      state.vehicle.initialValues = action.payload.initialValues;
    },
    openVehicleDrawerAndSetVehicle: (state: DrawerStateInterface, action: PayloadAction<VehicleInterface>) => {
      state.vehicle.open = true;
      state.vehicle.data = action.payload;
      state.vehicle.id = action.payload.id;
    },
    closeVehicleDrawer: (state: DrawerStateInterface) => {
      state.vehicle.open = false;
      state.vehicle.data = undefined;
      state.vehicle.initialValues = undefined;
      state.vehicle.id = undefined;
    },
    submitVehicleForm: (state: DrawerStateInterface) => {
      state.vehicle.submit = true;
    },
    resetSubmitVehicleForm: (state: DrawerStateInterface) => {
      state.vehicle.submit = false;
    },
    openDepotDrawerAndSetInitialValues: (state: DrawerStateInterface, action: PayloadAction<{initialValues: Array<InitialValueTypeDepot>}>) => {
      state.depot.open = true;
      state.depot.initialValues = action.payload.initialValues;
    },
    openDepotDrawerAndSetDepot: (state: DrawerStateInterface, action: PayloadAction<DepotInterface>) => {
      state.depot.open = true;
      state.depot.data = action.payload;
      state.depot.id = action.payload.id;
    },
    closeDepotDrawer: (state: DrawerStateInterface) => {
      state.depot.open = false;
      state.depot.data = undefined;
      state.depot.initialValues = undefined;
      state.depot.id = undefined;
    },
    submitDepotForm: (state: DrawerStateInterface) => {
      state.depot.submit = true;
    },
    resetSubmitDepotForm: (state: DrawerStateInterface) => {
      state.depot.submit = false;
    }
  }
});

export const {
  openClientDrawerAndSetInitialValues, openClientDrawerAndSetClient, closeClientDrawer, submitClientForm, resetSubmitClientForm,
  openUserDrawerAndSetInitialValues, openUserDrawerAndSetUser, closeUserDrawer, submitUserForm, resetSubmitUserForm,
  openVehicleDrawerAndSetInitialValues, openVehicleDrawerAndSetVehicle, closeVehicleDrawer, submitVehicleForm, resetSubmitVehicleForm,
  openDepotDrawerAndSetInitialValues, openDepotDrawerAndSetDepot, closeDepotDrawer, submitDepotForm, resetSubmitDepotForm,
} = drawerSlice.actions;

export default drawerSlice.reducer;