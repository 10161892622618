export const ColorConstant = {
  PRIMARY: { hash: '#DAA14C' },
  BLACK: { hash: '#000' },
  BLUEISH_BLACK: { hash: '#25232F' },
  GREEN: { hash: '#6E9D10' },
  TAG_GREY: { hash: '#696969'},
  GREY: { hash: '#DADADA' },
  DARK_GREY: { hash: '#7C7B82'},
  BLUE: { hash: '#039DFC' },
  YELLOW: { hash: '#F9DF85' },
  ORANGE: { hash: '#FC8003' },
  ORANGE_STATUS: { hash: '#E95138' },
  RED: { hash: '#FC2803' },
};
