import React from 'react';
import { Breadcrumb, Card } from 'antd';
import { Link } from 'react-router-dom';
import { allDeliveryListFormItems } from 'src/components/forms/deliveryList.form';
import { DeliveryListForm } from '../../../components';
import { DeliveryListInterface, i18n } from '../../../common';
import { AppRoutes } from '../_router/app.routes';

const DeliveryListCreatePage = () => {
  
  // const dispatch = useAppDispatch();

  const onDeliveryListCreate = (values: DeliveryListInterface) => {
    console.log(values);
    // dispatch(createDeliveryList(values));
  };

  console.log(onDeliveryListCreate);

  return (
    <>
      <Breadcrumb style={{ marginBottom: 24 }} items={[
        { title: <Link to={AppRoutes.DELIVERY_LISTS.fullPath} key={AppRoutes.DELIVERY_LISTS.fullPath}>{i18n.t('common.breadcrumb.deliveryLists')}</Link> },
        { title: i18n.translate('common.breadcrumb.deliveryListCreate') }]}
      />
      <Card>
        <DeliveryListForm
          // onDeliveryListFormFinish={(values) => onDeliveryListCreate(values)}
          formItems={allDeliveryListFormItems.filter((f) => (f !== 'active'))}
          submitBtnLabel={i18n.t('components.forms.deliveryList.create')}></DeliveryListForm>
      </Card>
    </>
  );
}

export default DeliveryListCreatePage;
