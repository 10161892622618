import { RouteInterface } from "../../../common";
import ForgotPasswordPage from "../forgotPassword/forgotPassword.page";
import LoginPage from "../login/login.page";
import ResetPasswordPage from "../resetPassword/resetPassword.page";
import { AuthRoutes } from "./auth.routes";

const AuthRouting: Array<RouteInterface> = [
  {
    path: AuthRoutes.LOGIN.fullPath,
    element: LoginPage,
    functions: []
  },
  {
    path: AuthRoutes.FORGOT_PASSWORD.fullPath,
    element: ForgotPasswordPage,
    functions: []
  },
  {
    path: AuthRoutes.RESET_PASSWORD.fullPath,
    element: ResetPasswordPage,
    functions: []
  },
];

export default AuthRouting;