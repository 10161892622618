export const ROOT_PATH_APP = '';

export const AppRoutes = {
  DASHBOARD: { path: 'dashboard', fullPath: `${ROOT_PATH_APP}/` },
  ORDERS: { path: 'orders', fullPath: `${ROOT_PATH_APP}/orders` },
  DELIVERY_LISTS: { path: 'delivery-lists', fullPath: `${ROOT_PATH_APP}/delivery-lists` },
  DRIVERS: { path: 'drivers', fullPath: `${ROOT_PATH_APP}/drivers` },
  TRACKING: { path: 'tracking', fullPath: `${ROOT_PATH_APP}/tracking`},
  DEPOT: { path: 'depot', fullPath: `${ROOT_PATH_APP}/depot`},
  USERS: { path: 'users', fullPath: `${ROOT_PATH_APP}/users` },
  PROFILE: { path: 'profile', fullPath: `${ROOT_PATH_APP}/profile` },
  APPLICATION_SETTINGS: { path: 'application-settings', fullPath: `${ROOT_PATH_APP}/application-settings` },
  CLIENTS: { path: 'clients', fullPath: `${ROOT_PATH_APP}/clients` },
};