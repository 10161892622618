import React from 'react';
import { Form, Input, Button, Space, } from 'antd';
import { i18n } from '../../common';
import { AuthRoutes } from '../../pages/auth';
import { H1, L1, T3 } from '../texts';

interface Props {
  onForgotPasswordFormFinish?: (values: any) => void;
  onForgotPasswordFormFinishFailed?: (errorInfo: any) => void;
}

const ForgotPasswordForm = ({ onForgotPasswordFormFinish, onForgotPasswordFormFinishFailed }: Props) => (
  <Space style={{ maxWidth: 420, minWidth: 360 }} direction='vertical' size={24}>
    <Form
      layout='vertical'
      size='large'
      name='forgotPassword'
      onFinish={onForgotPasswordFormFinish}
      onFinishFailed={onForgotPasswordFormFinishFailed}
    >
      <Form.Item>
        <H1>{i18n.translate(`components.forms.forgotPassword.title`)}</H1>
      </Form.Item>
      <Form.Item>
        <T3>{i18n.translate(`components.forms.forgotPassword.subtitle`)}</T3>
      </Form.Item>
      <Form.Item
        name="email"
        rules={[{ required: true, message: i18n.translate(`components.forms.forgotPassword.required`) }, { type: 'email', message: i18n.translate(`components.forms.forgotPassword.mailFormat`) }]}
      >
        <Input placeholder={i18n.translate(`components.forms.forgotPassword.mailPlaceholder`)} />
      </Form.Item>
      <Form.Item>
        <L1 to={AuthRoutes.LOGIN.fullPath} key={1}>{i18n.translate(`components.forms.forgotPassword.goToLogin`)}</L1>
      </Form.Item>
      <Form.Item>
        <Button type="primary" size="large" block htmlType="submit">
          {i18n.translate(`components.forms.forgotPassword.resetPasswordBtn`)}
        </Button>
      </Form.Item>
    </Form>
  </Space>
);

export default ForgotPasswordForm;
