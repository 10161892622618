import React from 'react';
import { Breadcrumb, Card } from 'antd';
import { allOrderFormItems } from 'src/components/forms/order.form';
import { useClient } from 'src/lib/jsonapi-react/src';
import { L1, OrderForm } from '../../../components';
import { OrderInterface, i18n } from '../../../common';
import { AppRoutes } from '../_router/app.routes';

const OrderCreatePage = () => {
  const client = useClient();

  const onOrderCreate = (values: OrderInterface) => {
    console.log(values);
    const { data, error } = client.mutate(['order'], values);
    console.log(data, error)
  };

  console.log(onOrderCreate);

  const labels = {
    submitBtnLabel: i18n.t('components.forms.order.create')
  }

  return (
    <>
      <Breadcrumb
        items={[
          { title: <L1 to={AppRoutes.ORDERS.fullPath}>{i18n.t('common.breadcrumb.orders')}</L1> },
          { title: i18n.t('common.breadcrumb.orderCreate') }
        ]}
        style={{ marginLeft: 32, marginBottom: 16 }}
      />
      <Card>
        <OrderForm
          onOrderFormFinish={(values) => onOrderCreate(values)}
          formItems={allOrderFormItems.filter((f) => (f !== 'active'))}
          labels={labels}
          
        ></OrderForm>
      </Card>
    </>
  );
}

export default OrderCreatePage;
