export const colors = 
{
  "orange": "#E95138",
  "orangeBackground": "#F9E6E2",
  "blue": "#0066FF",
  "red": "#F60003",
  "green": "#059435",
  "yellow": "#FFA800",
  "purpleLightBackground": "#D2D2FA",
  "orangeLightBackground": "#FFE7DF",
  "blueLightBackground": "#D2EBFA",
  "yellowLightBackground": "#FAF4C7",
  "pinkLightBackground": "#F5CDF3",
  "greenLightBackground": "#D2FAD6",
  "greyLightBackground": "#F8F7F5",
  "greyDark": "#7C7B82",
  "beige": "#DBD6CB",
  "beigeLight": "#EFEDE9",
  "black": "#25232F",
  "white": "#FFFFFF"
}

export const themeConfig = 
{
  "token": {
    "colorPrimary": "#E95138",
    "colorError": "#F60003",
    "colorSuccess": "#059435",
    "colorWarning": "#FFA800",
    "colorLink": "#25232F",
    "colorLinkHover": "#E95138",
    "colorLinkActive": "#E95138",
    "fontFamily": "'Figtree', sans-serif",
    "colorTextBase": "#25232F",
    "colorInfo": "#E95138",
    "boxShadow": "0px 0px 4px 0px rgba(0, 0, 0, 0.25)"
  },
  "components": {
    "Typography": {
      "fontSizeHeading1": 24,
      "fontSizeHeading2": 18,
      "fontSizeHeading3": 16,
      "fontSizeHeading4": 14,
      "fontSizeHeading5": 12
    },
    "Form": {
      "labelHeight": 22,
      "controlHeight": 22,
      "controlHeightLG": 22
    },
    "Input": {
      "paddingInline": 12,
      "paddingInlineLG": 12,
      "fontSizeLG": 14
    },
    "Menu": {
      "itemSelectedBg": "#E95138",
      "itemSelectedColor": "#EFEDE9",
      "itemColor": "#7C7B82",
      "itemHoverBg": "#F9E6E2",
      "itemHoverColor": "#E95138",
      "itemHeight": 52,
      "itemMarginInline": 8,
      "itemPaddingInline": 24,
      "itemMarginBlock": 8,
      "iconSize": 24,
      "collapsedIconSize": 24,
      "itemBorderRadius": 5,
    },
    "Table": {
      "headerBg": "#F8F7F5",
      "headerColor": "#25232F",
      "colorText": "#25232F",
      "headerBorderRadius": 5,
      "borderRadius": 5,
      "lineType": "none",
      "expandIconBg": "unset"
    },
    "Card": {
      "lineWidth": 2
    },
    "Breadcrumb": {
      "linkColor": "unset",
      "linkHoverColor": "#E95138",
      "colorBgTextHover": "none",
      "itemColor": "#25232F",
      "lastItemColor": "#E95138",
      "separatorColor": "#25232F"
    },
    "Tag": {
      "borderRadiusSM": 25,
    },
    "Tabs": {
      "itemColor": "#7C7B82",
      "colorBorderSecondary": "#A8A8A8"
    },
    "Descriptions": {
      "colonMarginLeft": 0,
      "titleMarginBottom": 16
    }
  }
}