import React from "react";
import { Link, To } from "react-router-dom";

interface Props {
  children: React.JSX.Element | string; 
  style?: React.CSSProperties;
  key?: React.Key | null;
  to: To;
}

// Link Bold 14px - boje namještene u theme
const L1 = ({ children, style, to, key }: Props) => ( 
  <Link to={to} key={key ?? to.toString()} style={{ fontWeight: 700, ...style }}>{children}</Link>
)

export default L1;
