import { Drawer, Space, Button } from 'antd'
import React from 'react'
import { DepotInterface, useAppSelector, RootState, useAppDispatch, i18n } from 'src/common';
import { closeDepotDrawer, submitDepotForm } from 'src/common/redux/drawer/drawer.slice';
import DepotForm, { allDepotFormItems } from '../forms/depot.form';

const DepotDrawer = () => {

  const dispatch = useAppDispatch();

  const depotDrawer = useAppSelector((state: RootState) => state.state.drawer.depot);

  const handleClose = () => {
    dispatch(closeDepotDrawer());
  };

  const createDepot = (data: DepotInterface) => {
    console.log(data);
    // mutateCreate(data);
    handleClose();
  }

  const updateDepot = (data: DepotInterface) => {
    console.log(data);
    // mutateUpdate(data);
    handleClose();
  }

  const onDepotFormFinish = (values: DepotInterface) => {
    if (!depotDrawer.id) {
      createDepot(values);
    } else {
      updateDepot(values);
    }
  };

  return <Drawer onClose={handleClose} open={depotDrawer.open} destroyOnClose
    title={!depotDrawer.id ? i18n.translate('components.drawer.depot.titleCreate') : i18n.translate('components.drawer.depot.titleEdit')}
    width={500}
    extra={
      <Space>
        <Button onClick={handleClose}>{i18n.translate('components.drawer.depot.buttons.cancel')}</Button>
        <Button type="primary" onClick={() => dispatch(submitDepotForm())}>{!depotDrawer.id ? i18n.translate('components.drawer.depot.buttons.create') : i18n.translate('components.drawer.depot.buttons.edit')}</Button>
      </Space>
    }>
    <DepotForm
      onDepotFormFinish={(values) => onDepotFormFinish(values)}
      formItems={allDepotFormItems.filter((f) => (f !== 'active'))}
      depot={depotDrawer.data ? depotDrawer.data : undefined}
    ></DepotForm>
  </Drawer>
}

export default DepotDrawer;
