import { Breadcrumb, Button, Card, Flex, Typography } from 'antd';
import React from 'react';
import { UserDrawer } from 'src/components/drawers';
import { openUserDrawerAndSetInitialValues } from 'src/common/redux/drawer/drawer.slice';
import { i18n, useAppDispatch } from '../../../common';
import { AddIcon, UsersTable } from '../../../components';

const { Title } = Typography;

const UsersPage = () => {
  const dispatch = useAppDispatch();

  return (
    <>
      <Breadcrumb
        items={[
          { title: i18n.t('common.breadcrumb.users') },
        ]}
        style={{ marginLeft: 32, marginBottom: 16 }}
      />
      <Card>
        <Flex justify='space-between' align='center' style={{ marginBottom: 16 }}>
          <Title level={2} style={{ margin: 0 }}>
            {i18n.t('pages.users.title')}
          </Title>

          <Button
            type="primary"
            onClick={() => dispatch(openUserDrawerAndSetInitialValues({ initialValues: []}))}
            icon={<AddIcon />}
          >
            {i18n.t('pages.users.create')}
          </Button>
        </Flex>
        <UsersTable></UsersTable>
        <UserDrawer></UserDrawer>
      </Card>
    </>
  );
};

export default UsersPage;
