import React, { useEffect } from 'react';
import { Flex, Form, Input } from 'antd';
import { resetSubmitDepotForm } from 'src/common/redux/drawer/drawer.slice';
import { DepotInterface, RootState, i18n, useAppDispatch, useAppSelector } from '../../common';
import { T1 } from '../texts';


export type DepotFormItemType = 'name' | 'active';
export type InitialValueTypeDepot = { formItem: DepotFormItemType, value: string | number | boolean };

export const allDepotFormItems: Array<DepotFormItemType> = ['name', 'active'];

interface Props {
  onDepotFormFinish?: (values: any) => void;
  depot?: DepotInterface;
  formItems?: Array<DepotFormItemType>;
  disabled?: Array<DepotFormItemType>;
  initialValues?: Array<InitialValueTypeDepot>;
  submitBtnLabel?: string;
}

function DepotForm({ onDepotFormFinish, depot, formItems, disabled, initialValues }: Props) {

  const dispatch = useAppDispatch();

  const [form] = Form.useForm();

  const submitForm = useAppSelector((state: RootState) => state.state.drawer.depot.submit);

  const onFormSubmit = (values: any) => {
    const data = {
      ...values
      //  TODO
    }
    if (onDepotFormFinish) onDepotFormFinish(data);
  }

  const items: Array<DepotFormItemType> = formItems || allDepotFormItems;

  const initialFormValues = {
    ...depot,
    name: (initialValues?.find((fi: InitialValueTypeDepot) => fi.formItem === 'name')?.value as string) || depot?.name,
  }

  useEffect(() => {
    if (submitForm) {
      form.submit();
      dispatch(resetSubmitDepotForm());
    }
  }, [submitForm]);

  return (<>
    <Form
      name='depot'
      form={form}
      onFinish={onFormSubmit}
      initialValues={initialFormValues}
      layout={'vertical'}
    >
      <Flex gap={'16px'} vertical>
        <Form.Item
          name="name"
          label={<T1>{i18n.translate(`components.forms.depot.name`)}</T1>}
          rules={[{ required: true, message: i18n.translate(`components.forms.depot.required`) }]}
          hidden={!items.find(i => i === 'name')}
        >
          <Input disabled={!!disabled?.find(d => d === 'name')} />
        </Form.Item>
        {/** JOŠ FORM ITEMA? NE VIDIM NIGDJE U FIGMI ZA SKLADIŠTA DRAWER */}
      </Flex>
    </Form>
  </>
  );
}

export default DepotForm;
