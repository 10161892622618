import React, { useState } from "react";
import { Table as AntTable, Flex, Typography } from 'antd';
import { ColumnsType } from "antd/es/table";
import type { ExpandableConfig } from 'antd/es/table/interface';
import { useQuery } from "src/lib/jsonapi-react/src";
import { AppRoutes } from "src/pages/app";
import dayjs from 'dayjs';
import { QueryParams, i18n, DeliveryListInterface, DefaultPagination, FetchParams, DisplayDateFormat, SegmentInterface } from "../../common";
import { L1, T1 } from "../texts";
import Table, { TableFilter, TableParams, TableSearch } from "./table";

const { Text } = Typography;

type DeliveryListsTableColumnType = 'uuid' | 'pickupDate' | 'dispatcher' | 'vehicle' | 'driver' | 'orders' | 'orderer' | 'actions';
type DeliveryListsTableFilterType = 'orderType' | 'activeSalesOpportunityStatus' | 'area' | 'user' | 'deliveryLists' | 'active';

export const allDeliveryListsTableColumns: Array<DeliveryListsTableColumnType> = ['uuid', 'pickupDate', 'dispatcher', 'vehicle', 'orders', 'actions'];
export const allDeliveryListsTableFilters: Array<DeliveryListsTableFilterType> = ['orderType', 'activeSalesOpportunityStatus', 'area', 'user', 'deliveryLists','active'];

interface Props {
  injectedQueryParams?: QueryParams;
  columns?: Array<DeliveryListsTableColumnType>;
  filters?: Array<DeliveryListsTableFilterType>;
}

const DeliveryListsTable = ({ injectedQueryParams, columns, filters }: Props) => {

  const componentQueryParams: QueryParams = {
    ...injectedQueryParams,
    includes: [
      ...(injectedQueryParams?.includes || []),
      'dispatcher',
      'vehicle',
      'driver',
      'segments',
      'segments.order',
      // 'segments.order.latestOrderState', // BE ovo ne podržava, kako doći do entiteta u relationshipu od ordera, dodatni poziv?
      // 'segments.order.deliveryLists', // BE ovo ne podržava, kako doći do entiteta u relationshipu od ordera, dodatni poziv?
    ],
    sorters: [
      { columnKey: 'id', order: 'descend', field: 'id' }
    ]
  };

  const [ queryParams, setQueryParams ] = useState<QueryParams>(componentQueryParams);

  const params: FetchParams = {
    type: 'delivery_list',
    queryParams
  };

  const { data, meta } = useQuery(params);

  const tableColumns: Array<DeliveryListsTableColumnType> = columns || allDeliveryListsTableColumns;

  const deliveryListsTableColumns: ColumnsType<DeliveryListInterface> = [
    {
      title: i18n.t('components.tables.deliveryLists.uuid'),
      dataIndex: 'uuid',
      key: 'uuid',
      render: (dataIndex: string, deliveryList: DeliveryListInterface) => <L1 to={`${AppRoutes.DELIVERY_LISTS.fullPath}/${deliveryList.id}`}>{deliveryList.uuid}</L1>,
      sorter: true,
      width: 150
    },
    {
      title: i18n.t('components.tables.deliveryLists.pickupDate'),
      dataIndex: 'pickupDate',
      key: 'pickupDate',
      render: (dataIndex: string, deliveryList: DeliveryListInterface) => `${dayjs(deliveryList.pickupDate).format(DisplayDateFormat)}`,
      width: 300
    },
    {
      title: i18n.t('components.tables.deliveryLists.dispatcher'),
      dataIndex: 'dispatcher',
      key: 'dispatcher',
      render: (dataIndex: string, deliveryList: DeliveryListInterface) => deliveryList.dispatcher.name,
      width: 300
    },
    {
      title: i18n.t('components.tables.deliveryLists.vehicle'),
      dataIndex: 'vehicle',
      key: 'vehicle',
      render: (dataIndex: string, deliveryList: DeliveryListInterface) => deliveryList.vehicle.name,
      width: 300
    },
    AntTable.EXPAND_COLUMN,
    // {
    //   title: i18n.translate(`components.tables.deliveryLists.actions`),
    //   dataIndex: 'actions',
    //   key: 'actions',
    //   render: (dataIndex: string, deliveryList: DeliveryListInterface) => '...'
    // },
  ].filter((c) => !('dataIndex' in c) || tableColumns.includes(c.dataIndex! as DeliveryListsTableColumnType));

  // Ako ne idemo preko segmenta ne možemo doći do ordera, ovdje bi trebalo na klik + buttona napraviti još jedan poziv na BE za sve ordere koji pripadaju listi
  const expandable: ExpandableConfig<any> = {
    expandedRowRender: (deliveryList: DeliveryListInterface) => {
      const ordersTableColumns: ColumnsType<SegmentInterface> = [
        {
          title: i18n.t('components.tables.orders.uuid'),
          dataIndex: 'uuid',
          key: 'uuid',
          render: (dataIndex: string, segment: SegmentInterface) => <L1 to={`${AppRoutes.ORDERS.fullPath}/${segment.order?.id}`}>{segment.order?.uuid}</L1>,
          sorter: true,
          width: 300
        },
        {
          title: i18n.t('components.tables.orders.deliveryDate'),
          dataIndex: 'deliveryDate',
          key: 'deliveryDate',
          render: (dataIndex: string, segment: SegmentInterface) => `${dayjs(segment.order?.deliveryDate).format(DisplayDateFormat)}`,
          width: 300
        },
        {
          title: i18n.t('components.tables.orders.orderer'),
          dataIndex: 'orderer',
          key: 'orderer',
          render: (dataIndex: string, segment: SegmentInterface) => segment.order?.orderer && <L1 to={`${AppRoutes.CLIENTS.fullPath}/${segment.order.orderer.id}}`}>{segment.order.orderer.name}</L1>,
          width: 300
        },
        {
          title: i18n.t('components.tables.orders.sender'),
          dataIndex: 'sender',
          key: 'sender',
          render: (dataIndex: string, segment: SegmentInterface) => segment.order?.sender && <L1 to={`${AppRoutes.CLIENTS.fullPath}/${segment.order.sender.id}}`}>{segment.order.sender.name}</L1>,
          width: 300
        },
        {
          title: i18n.t('components.tables.orders.senderAddress'),
          dataIndex: 'senderAddress',
          key: 'senderAddress',
          render: (dataIndex: string, segment: SegmentInterface) => segment.order?.senderAddress && segment.order.senderAddress.street,
          width: 300
        },
        {
          title: i18n.t('components.tables.orders.receiver'),
          dataIndex: 'receiver',
          key: 'receiver',
          render: (dataIndex: string, segment: SegmentInterface) => segment.order?.receiver && <L1 to={`${AppRoutes.CLIENTS.fullPath}/${segment.order.receiver.id}}`}>{segment.order.receiver.name}</L1>,
          width: 300
        },
        {
          title: i18n.t('components.tables.orders.receiverAddress'),
          dataIndex: 'receiverAddress',
          key: 'receiverAddress',
          render: (dataIndex: string, segment: SegmentInterface) => segment.order?.receiverAddress && segment.order.receiverAddress.street,
          width: 300
        },
        {
          title: i18n.t('components.tables.orders.latestOrderState'),
          dataIndex: 'latestOrderState',
          key: 'latestOrderState',
          render: (dataIndex: string, segment: SegmentInterface) => segment.order.latestOrderState ? segment.order.latestOrderState.status : '-', // getOrderStatusName(order.latestOrderState?.id) : '-', // TODO staviti ih u tagove i srediti statuse
          width: 150
        },
        {
          title: i18n.t('components.tables.orders.deliveryLists'),
          dataIndex: 'deliveryLists',
          key: 'deliveryLists',
          render: (dataIndex: string, segment: SegmentInterface) => (segment.order?.deliveryLists && segment.order?.deliveryLists?.length > 0) ? segment.order.deliveryLists.map((o: DeliveryListInterface) => o.uuid) : '-',
          // sorter: true,
          width: 300
        },
        // {
        //   title: i18n.translate(`components.tables.orders.actions`),
        //   dataIndex: 'actions',
        //   key: 'actions',
        //   render: (dataIndex: string, segment: SegmentInterface) => '...'
        // },
      ]

      return <Flex vertical>
        {deliveryList.note && <Text><T1>{i18n.t('components.tables.orders.note')}</T1>: {deliveryList.note}</Text>}
        <AntTable columns={ordersTableColumns} dataSource={deliveryList.segments} pagination={false}></AntTable>
      </Flex>;
    }
  }

  interface Filter {
    filterKey: DeliveryListsTableFilterType;
    tableFilter: TableFilter;
  }

  const tableFilters: Array<DeliveryListsTableFilterType> = filters || allDeliveryListsTableFilters;
  console.log(tableFilters)
  console.log(data)

  const ordersTableFilters: Array<Filter> = [];
  //   // { filterKey: 'activeSalesOpportunityStatus' as OrdersTableFilterType, tableFilter: {component: SalesOpportunityStatusFilter, key: 'transientValues.activeSalesOpportunity!.latestOrderState.id' }},
  //   { filterKey: 'orderType' as OrdersTableFilterType, tableFilter: { component: OrderTypeFilter, key: 'orderType.id' } },
  //   { filterKey: 'area' as OrdersTableFilterType, tableFilter: { component: AreaFilter, key: 'areas.id' } },
  //   { filterKey: 'user' as OrdersTableFilterType, tableFilter: { component: UserFilter, key: 'user.id', nullOption: true } },
  //   ...(GuardFunction({ domain: 'order', action: 'view_active' }) ? [{ filterKey: 'active' as OrdersTableFilterType, tableFilter: { component: ActiveFilter, key: 'active' } }] : []),
  // ].filter((f) => tableFilters.includes(f.filterKey as OrdersTableFilterType))

  const searchFields: Array<TableSearch> = [
    // { component: Search, keys: ['name', 'oib'], placeholder: i18n.t('components.tables.deliveryLists.searchPlaceholder') }
  ]

  // Potrebno prilagoditi funkciju ovisno o tome koje su sve mogućnosti
  const handleTableChange = (tableParams: TableParams) => {
    setQueryParams({
      pagination: tableParams.pagination,
      filters: [
        ...(tableParams.filters || []),
        ...(componentQueryParams?.filters || []),
      ],
      sorters: tableParams.sorters || componentQueryParams.sorters,
      includes: componentQueryParams.includes,
      search: tableParams.search
    })
  };

  // const handleGroupAction = (selectedRows: Array<DeliveryListInterface>, selectedGroupAction?: number) => {
  //   setSelectedOrders(selectedRows);
  //   groupActions?.find(x => x.key === selectedGroupAction)?.action();
  // };

  if (!data) return null;
  return (
    <>
      <Table
        rowKey={"id"}
        columns={deliveryListsTableColumns}
        dataSource={data}
        handleTableChange={handleTableChange}
        paginationDefault={DefaultPagination}
        total={meta.totalResourceCount}
        filters={ordersTableFilters.map((filter: Filter) => filter.tableFilter)}
        searchFields={searchFields}
        expandable={expandable}
      />
    </>
  )
}

export default DeliveryListsTable;
