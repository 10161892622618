import { LogoutOutlined, ProfileOutlined } from '@ant-design/icons';
import { Button, Dropdown, MenuProps, Typography, Avatar, theme, Layout, Flex } from 'antd';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { RootState, i18n, logout, setCollapsed, useAppDispatch, useAppSelector } from '../../common';
import { AppRoutes } from '../../pages/app';
import { HamburgerIcon, UserIcon } from '../icons';

const { useToken } = theme;

const { Text } = Typography;

const Header = () => {
  const { token } = useToken();

  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const userAuth = useAppSelector((store: RootState) => store.model.auth);

  const collapsed = useAppSelector((store: RootState) => store.state.layout.sider.collapsed);

  function onLogOutBtnClick() {
    dispatch(logout());
  }

  const items: MenuProps['items'] = [
    {
      key: '1',
      label: <a onClick={() => navigate(AppRoutes.PROFILE.path)}>{i18n.translate(`components.layout.header.profileBtn`)}</a>,
      icon: <ProfileOutlined />,
    },
    {
      key: '3',
      danger: true,
      label: <a onClick={onLogOutBtnClick}>{i18n.translate(`components.layout.header.logoutBtn`)}</a>,
      icon: <LogoutOutlined />,
    },
  ];

  return (
    <Layout.Header
      className="header"
      style={{
        position: 'sticky',
        top: 0,
        zIndex: 4,
        width: '100%',
        paddingInline: 24,
        backgroundColor: token.colorBgContainer,
        boxShadow: token.boxShadow
      }}>
      <Flex justify='space-between' align='center' style={{ height: '100%' }}>
        <Button
          type="link"
          icon={<HamburgerIcon style={{ height: 24, width: 24, fontSize: 24 }} />}
          onClick={() => dispatch(setCollapsed(!collapsed))}
          style={{ width: 24 }}
        />
        <Flex align='center' justify='center'>

          <Dropdown menu={{ items }} trigger={['click']}>
            <Button style={{ margin: 0, padding: 0 }} onClick={(e) => e.preventDefault()} type="default" shape="circle" icon={userAuth.user?.profileImageUrl ? <Avatar src={userAuth.user?.profileImageUrl} /> : <Avatar icon={<UserIcon />} />} />
          </Dropdown>
          <Text className='fullName ml8'>{`${userAuth.user?.name}`}</Text>

        </Flex>
      </Flex>
    </Layout.Header>
  );
}

export default Header;
