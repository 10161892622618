import { RootState, store } from '../../common';
import { UserFunctionInterface } from '../../common/interfaces/user/userFunction.interface';

interface Props {
  children: JSX.Element;
  permission: UserFunctionInterface | Array<UserFunctionInterface>;
}

function GuardFunction(permission: UserFunctionInterface | Array<UserFunctionInterface>): boolean {
  
  const { model : { auth: { userFunctions } }}: RootState = store.getState();

  if (!userFunctions) return false;

  const neededpermissions = Array.isArray(permission) ? permission : [permission];

  for (const np of neededpermissions) {
    // let accessGranted = false;
    for (const f of userFunctions!) {
      if (np.action.trim() === f.action.trim() && np.domain.trim() === f.domain.trim()) {
        if (f.isAllowed) {
          // accessGranted = true;
          break;
        } else {
          return false;
        }
      }
    }

    // if (!accessGranted) return false;
  }

  return true;
}

function Guard({ children, permission }: Props): JSX.Element | null {
  const accessGranted = GuardFunction(permission);
  if (!accessGranted) return null;

  return children;
}

export default Guard;
export { GuardFunction };
