import { Breadcrumb, Button, Card, Flex, Typography } from 'antd';
import React from 'react'

import { useParams } from 'react-router-dom';

import { EditIcon, H3, DeliveryListDetails, OrdersTable } from 'src/components';

import { useQuery } from "src/lib/jsonapi-react/src";

import { i18n, FetchParams, QueryParams } from '../../../common';

const DeliveryListPage = () => {
  const { Title } = Typography;

  const { id } = useParams();

  const queryParams: QueryParams = {
    includes: [
      'dispatcher',
      'vehicle',
      'driver'
    ]
  }

  const params: FetchParams = {
    type: 'deliveryList',
    id: parseInt(id!, 10),
    queryParams
  };

  const tableQueryParams: QueryParams = {
    filters: [
      {
        name: 'deliveryList.id',
        operator: 'eq',
        value: `${id}`
      }
    ]
  }

  const { data } = useQuery(params);

  return (
    <>
      <Breadcrumb
        items={[
          { title: i18n.t('pages.deliveryList.viewDeliveryList') }
        ]}
        style={{ marginLeft: 32, marginBottom: 16 }}
      />
      <Card>
        <Flex justify='space-between' align='center' style={{ marginBottom: 16 }}>
          <Title level={2} style={{ margin: 0 }}>
            {i18n.t('pages.deliveryList.title')} {data?.uuid}
          </Title>

          <Button
            type="primary"
            icon={<EditIcon />}
          >
            {i18n.t('pages.deliveryList.edit')}
          </Button>
        </Flex>

        <div className="mb16">
          <H3>{ i18n.t('pages.deliveryList.deliveryListDetails') }</H3>
        </div>
        <DeliveryListDetails deliveryList={data} />

        <OrdersTable
          injectedQueryParams={tableQueryParams}
        ></OrdersTable>
      </Card>
    </>
  );
}

export default DeliveryListPage;
