import { Flex, Space } from 'antd';
import React from 'react';
import { LoginInterface, login, useAppDispatch } from '../../../common';
import { LoginForm } from '../../../components';
import Logo from '../../../assets/images/logo.svg';

const LoginPage = () => {

  const dispatch = useAppDispatch();

  const onLogin = (values: any) => {
    const loginData: LoginInterface = {
      username: values.username,
      password: values.password,
    };
    dispatch(login(loginData));
  };

  const onLoginFailed = (errorInfo: any) => {
    console.log('Failed:', errorInfo);
  };

  return (
    <Flex justify='center' align='center' style={{ height: '100%' }}>
      <Space style={{ maxWidth: 420, minWidth: 360 }} direction='vertical' size={24}>
        <img width={'auto'} height={24} src={Logo} alt="" />
        <LoginForm onLoginFormFinish={(values) => onLogin(values)} onLoginFormFinishFailed={(errorInfo) => onLoginFailed(errorInfo)}></LoginForm>
      </Space>
    </Flex>
  );
};

export default LoginPage;
