import { Descriptions } from 'antd';
import React from 'react';
import { AddressInterface, ColorConstant, i18n } from '../../common';
import { T1, H3 } from '../texts';

interface Props {
  address?: AddressInterface;
}

const AddressDetails = ({address}: Props) => {

  const descriptionsItems: Array<{label: string | JSX.Element, render: JSX.Element}> = [
    {
      label: <T1 style={{ color: ColorConstant.DARK_GREY.hash }}>{i18n.translate('components.details.addresses.cityAndPostalCode')}</T1>,
      render: <H3>{(address?.city?.name || address?.city?.postalCode) ? `${address?.city?.name ? address?.city?.name : '—'} ${address?.city?.postalCode ? address?.city?.postalCode : '—'}` : '—'}</H3>
    },
    {
      label: <T1 style={{ color: ColorConstant.DARK_GREY.hash }}>{i18n.translate('components.details.addresses.street')}</T1>,
      render: <H3>{address?.street ? address?.street : '—'}</H3>
    },
    {
      label: <T1 style={{ color: ColorConstant.DARK_GREY.hash }}>{i18n.translate('components.details.addresses.contactPerson')}</T1>,
      render: <H3>{address?.contactPerson ? address?.contactPerson : '—'}</H3>
    },
    {
      label: <T1 style={{ color: ColorConstant.DARK_GREY.hash }}>{i18n.translate('components.details.addresses.contactNumber')}</T1>,
      render: <H3>{address?.phoneNumber ? address?.phoneNumber : '—'}</H3>
    },
  ]

  return (
    <Descriptions column={1} layout={'vertical'} colon={false}>
      {descriptionsItems.map((item: {label: string | JSX.Element, render: JSX.Element}, index: number) => (
        <Descriptions.Item key={index} label={item.label}>{item.render}</Descriptions.Item>
      ))}
    </Descriptions>
  );
}

export default AddressDetails;
