import { useQuery } from "src/lib/jsonapi-react/src";
import { AddressInterface, FetchParams, QueryParams } from "../../common";
import { ColumnFilter } from "../tables/table";

const AddressValues = (queryParams?: QueryParams): Array<ColumnFilter> => {
  const params: FetchParams = {
    type: 'address',
    queryParams: {
      ...queryParams,
      pagination: { current: 1, pageSize: 999999 }
    }
  };

  const { data } = useQuery(params);

  if (data) return data.map((c: AddressInterface) => ({ text: c.street, value: c.id} as ColumnFilter));
  return [];
}

export default AddressValues;