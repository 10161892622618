import { PayloadAction, createSlice } from "@reduxjs/toolkit";

// ============ INTERFACE ============

export interface LayoutStateInterface {
  sider: {
    collapsed: boolean;
  },
}

// ============ INIT STATE ============

const initialState: LayoutStateInterface = {
  sider: {
    collapsed: false
  }
};

// ========= SLICE ==========

export const drawerSlice = createSlice({
  name: 'layout',
  initialState,
  reducers: {
    setCollapsed: (state: LayoutStateInterface, action: PayloadAction<boolean>) => {
      state.sider.collapsed = action.payload;
    }
  }
})

export const { setCollapsed } = drawerSlice.actions;

export default drawerSlice.reducer;