import React, { useEffect } from 'react';
import { Flex, Form, Input } from 'antd';
import { resetSubmitVehicleForm } from 'src/common/redux/drawer/drawer.slice';
import { VehicleInterface, RootState, i18n, useAppSelector, useAppDispatch } from '../../common';
import { T1 } from '../texts';


export type VehicleFormItemType = 'name' | 'license' | 'client' | 'active';
export type InitialValueTypeVehicle = { formItem: VehicleFormItemType, value: string | number | boolean | {id: number | string} };

export const allVehicleFormItems: Array<VehicleFormItemType> = ['name', 'license', 'client', 'active'];

interface Props {
  onVehicleFormFinish?: (values: any) => void;
  vehicle?: VehicleInterface;
  formItems?: Array<VehicleFormItemType>;
  disabled?: Array<VehicleFormItemType>;
  initialValues?: Array<InitialValueTypeVehicle>;
  submitBtnLabel?: string;
}

function VehicleForm({ onVehicleFormFinish, vehicle, formItems, disabled, initialValues }: Props) {

  const dispatch = useAppDispatch();

  const [form] = Form.useForm();

  const submitForm = useAppSelector((state: RootState) => state.state.drawer.vehicle.submit);

  const onFormSubmit = (values: any) => {
    const data = {
      ...values,
      //  JEL TREBA UOPĆE CLIENT?
      client: {id: values.client}
    }
    if (onVehicleFormFinish) onVehicleFormFinish(data);
  }

  const items: Array<VehicleFormItemType> = formItems || allVehicleFormItems;

  const initialFormValues = {
    ...vehicle,
    name: (initialValues?.find((fi: InitialValueTypeVehicle) => fi.formItem === 'name')?.value as string) || vehicle?.name,
    license: (initialValues?.find((fi: InitialValueTypeVehicle) => fi.formItem === 'license')?.value as string) || vehicle?.license,
    //  JEL TREBA UOPĆE CLIENT?
    client: (initialValues?.find((fi: InitialValueTypeVehicle) => fi.formItem === 'client')?.value as number) || vehicle?.client?.id
  }

  useEffect(() => {
    if (submitForm) {
      form.submit();
      dispatch(resetSubmitVehicleForm());
    }
  }, [submitForm]);

  return (<>
    <Form
      name='vehicle'
      form={form}
      onFinish={onFormSubmit}
      initialValues={initialFormValues}
      layout={'vertical'}
    >
      <Flex gap={'16px'} vertical>
        <Form.Item
          name="name"
          label={<T1>{i18n.translate(`components.forms.vehicle.name`)}</T1>}
          rules={[{ required: true, message: i18n.translate(`components.forms.common.required`) }]}
          hidden={!items.find(i => i === 'name')}
        >
          <Input disabled={!!disabled?.find(d => d === 'name')} />
        </Form.Item>
        <Form.Item
          name="license"
          label={<T1>{i18n.translate(`components.forms.vehicle.license`)}</T1>}
          rules={[{ required: true, message: i18n.translate(`components.forms.common.required`) }]}
          hidden={!items.find(i => i === 'license')}
        >
          <Input disabled={!!disabled?.find(d => d === 'license')} />
        </Form.Item>
        {/** CLIENT DROPDOWN? TREBALO BI ONDA DOHVATITI SVE KLIJENTE, ZASADA STAVLJAM SAMO HIDDEN INPUT */}
        <Form.Item
          name="client"
          label={<T1>{i18n.translate(`components.forms.vehicle.client`)}</T1>}
          hidden={true}
        >
          <Input disabled={!!disabled?.find(d => d === 'client')} />
        </Form.Item>
      </Flex>
    </Form>
  </>
  );
}

export default VehicleForm;
