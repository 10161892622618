import { useQuery } from "src/lib/jsonapi-react/src";
import { ClientInterface, FetchParams, QueryParams } from "../../common";

const ClientValues = (queryParams?: QueryParams): Array<ClientInterface> => {
  const params: FetchParams = {
    type: 'client',
    queryParams: {
      ...queryParams,
      pagination: { current: 1, pageSize: 999999 }
    }
  };

  const { data } = useQuery(params);

  if (data) return data;
  return [];
}

export default ClientValues;