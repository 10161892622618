import { Descriptions } from 'antd';
import React from 'react';
import dayjs from 'dayjs';
import { DeliveryListInterface, ColorConstant, i18n, DisplayDateFormat } from '../../common';
import { T1, H3 } from '../texts';

interface Props {
  deliveryList?: DeliveryListInterface;
}

const DeliveryListDetails = ({deliveryList}: Props) => {

  // TODO - Pravilno mapirati po novim BE entitetima

  const descriptionsItems: Array<{label: string | JSX.Element, render: JSX.Element}> = [
    {
      label: <T1 style={{ color: ColorConstant.DARK_GREY.hash }}>{ i18n.t('components.details.deliveryList.dispatcher') }</T1>,
      render: <H3>{deliveryList?.dispatcher?.name ? deliveryList?.dispatcher?.name : '—'}</H3>
    },
    {
      label: <T1 style={{ color: ColorConstant.DARK_GREY.hash }}>{ i18n.t('components.details.deliveryList.vehicle') }</T1>,
      render: <H3>{deliveryList?.vehicle?.name ? deliveryList?.vehicle?.name : '—'}</H3>
    },
    {
      label: <T1 style={{ color: ColorConstant.DARK_GREY.hash }}>{ i18n.t('components.details.deliveryList.driver') }</T1>,
      render: <H3>{deliveryList?.driver?.name ? deliveryList?.driver?.name : '—'}</H3>
    },
    {
      label: <T1 style={{ color: ColorConstant.DARK_GREY.hash }}>{ i18n.t('components.details.deliveryList.pickupDate') }</T1>,
      render: <H3>{deliveryList?.pickupDate ? dayjs(deliveryList.pickupDate).format(DisplayDateFormat) : '—'}</H3>
    },
  ]

  const descriptionsNotes: Array<{label: string | JSX.Element, render: JSX.Element}> = [
    {
      label: <T1 style={{ color: ColorConstant.DARK_GREY.hash }}>{ i18n.t('components.details.deliveryList.note') }</T1>,
      render: <H3>{deliveryList?.note ? deliveryList?.note : '—'}</H3>
    },
  ]

  return (
    <>
      <Descriptions column={descriptionsItems?.length} layout={'horizontal'} colon={false}>
        {descriptionsItems.map((item: {label: string | JSX.Element, render: JSX.Element}, index: number) => (
          <Descriptions.Item key={index} label={item.label}>{item.render}</Descriptions.Item>
        ))}
      </Descriptions>
      <Descriptions column={1} layout={'vertical'} colon={false}>
        {descriptionsNotes.map((item: {label: string | JSX.Element, render: JSX.Element}, index: number) => (
          <Descriptions.Item key={index} label={item.label}>{item.render}</Descriptions.Item>
        ))}
      </Descriptions>
    </>
  );
}

export default DeliveryListDetails;
