import { Breadcrumb, Card, Flex, Typography, Timeline, Input, Button } from "antd";
import React, { useState } from "react";
import { CircleCheckIcon, CircleCircleIcon, H2, T1 } from "src/components";
import { useQuery } from "src/lib/jsonapi-react/src";
import { i18n, ColorConstant, FetchParams, OrderInterface, QueryParams, getTrackingStatusString } from '../../../common';

const TrackingPage = () => {

  const { Title } = Typography;

  const [searchInput, setSearchInput] = useState('');
  const handleChange = (e: any) => setSearchInput(e.target.value);

  const componentQueryParams: QueryParams = {
    includes: [
      'orderStates',
      // 'orderStates.segment', // segment na sebi trenutno nema nikakve informacije od adresi, ili ako ima se nalaze u relationshipu na trećoj jsonapi razini
      'latestOrderState',
      // 'latestOrderState.segment', // segment na sebi trenutno nema nikakve informacije od adresi, ili ako ima se nalaze u relationshipu na trećoj jsonapi razini
      'orderer',
      // 'orderer.addresses', // dobije se greška kod serijalizacija, ali i na hoppscotchu kad se stavi, ne dobijemo ništa u include. Kako doći do adrese?
      'sender',
      // 'sender.addresses', // dobije se greška kod serijalizacija, ali i na hoppscotchu kad se stavi, ne dobijemo ništa u include. Kako doći do adrese?
      'receiver',
      // 'receiver.addresses', // dobije se greška kod serijalizacija, ali i na hoppscotchu kad se stavi, ne dobijemo ništa u include. Kako doći do adrese?
    ],
    filters: [
      {
        name: 'uuid',
        operator: 'eq',
        value: searchInput
      }
    ]
  };

  const [ queryParams, setQueryParams ] = useState<QueryParams>(componentQueryParams);

  const params: FetchParams = {
    type: 'order',
    queryParams
  };

  const { data } = useQuery(params);

  console.log(data);

  const order: OrderInterface = data?.length ? data[0] : undefined;

  const findOrder = () => {
    setQueryParams({
      filters: componentQueryParams.filters,
      includes: componentQueryParams.includes,
    })
  };

  return (
    <>
      <Breadcrumb
        items={[
          { title: i18n.t('common.breadcrumb.tracking') }
        ]}
        style={{ marginLeft: 32, marginBottom: 16 }}
      />

      <Card>
        <Flex justify='space-between' align='center' style={{ marginBottom: 16 }}>
          <Title level={2} style={{ margin: 0 }}>
            {i18n.t('pages.tracking.title')}
          </Title>
        </Flex>
        <Flex vertical gap={'32px'}>
          <div>
            <label>{i18n.t('pages.tracking.inputLabel')}</label>
            <Flex style={{ maxWidth: '520px' }} gap={'12px'}>
              <Input value={searchInput} onChange={handleChange} placeholder={i18n.t('pages.tracking.inputPlaceholder')} />
              <Button onClick={() => findOrder()} type="primary">{i18n.t('pages.tracking.inputButton')}</Button>
            </Flex>
          </div>

          {order?.uuid ?
            <>
              <div>
                <H2>{i18n.t('pages.tracking.details.title')}</H2>
                <Flex className="mt16" gap={'130px'}>
                  <Flex vertical gap={'8px'}>
                    <T1 style={{ color: ColorConstant.DARK_GREY.hash }}>{i18n.t('pages.tracking.details.preorder')}</T1>
                    <T1>{order?.uuid}</T1>
                  </Flex>
                  <Flex vertical gap={'8px'}>
                    <T1 style={{ color: ColorConstant.DARK_GREY.hash }}>{i18n.t('pages.tracking.details.orderer')}</T1>
                    <T1>{order?.orderer?.name}</T1>
                  </Flex>
                </Flex>
                <Flex className="mt16" gap={'130px'}>
                  <Flex vertical gap={'8px'}>
                    <T1 style={{ color: ColorConstant.DARK_GREY.hash }}>{i18n.t('pages.tracking.details.sender')}</T1>
                    <T1>{order?.sender?.name}</T1>
                  </Flex>
                  <Flex vertical gap={'8px'}>
                    <T1 style={{ color: ColorConstant.DARK_GREY.hash }}>{i18n.t('pages.tracking.details.receiver')}</T1>
                    <T1>{order?.receiver?.name}</T1>
                  </Flex>
                </Flex>
              </div>

              <Timeline
                className="mt16"
                reverse
                items={order?.orderStates?.map((state: any, index: any) => ({
                  dot: state.id !== order.latestOrderState?.id ? <CircleCheckIcon style={{ fontSize: 18 }} /> : <CircleCircleIcon style={{ fontSize: 18 }} />,
                  color: state.id === order.latestOrderState?.id ? ColorConstant.ORANGE_STATUS.hash : ColorConstant.BLUEISH_BLACK.hash,
                  children: (
                    <div key={`state${index}`}>
                      <Flex align="center">
                        <H2 style={{ color: ColorConstant.BLACK.hash }}>{state.status}</H2>
                        <div style={{ flex: '1', marginInline: '18px', backgroundColor: 'rgba(217, 217, 217, 1)', height: '1px' }}></div>
                      </Flex>
                      <div style={{ display: 'grid', gridTemplateColumns: '1fr 8fr' }}>
                        <div style={{ alignItems: 'left' }}>{/** TODO - STATUSI NA BE TRENUTNO KOD SEBE NEMAJU NIKAKVE DATUME */}</div>
                        <div style={{ alignItems: 'left' }}>{getTrackingStatusString(state.status) /** TODO - PROŠIRITI KADA DOBIJEMO ADRESE */}</div>
                      </div>
                    </div>
                  ),
                }))}
              />
            </>
            : <div></div>}
        </Flex>
      </Card>
    </>
  );
}

export default TrackingPage;