import { Drawer, Space, Button } from 'antd'
import React from 'react'
import { VehicleInterface, useAppSelector, RootState, useAppDispatch, i18n } from 'src/common';
import { closeVehicleDrawer, submitVehicleForm } from 'src/common/redux/drawer/drawer.slice';
import VehicleForm, { allVehicleFormItems } from '../forms/vehicle.form';

const VehicleDrawer = () => {

  const dispatch = useAppDispatch();

  const vehicleDrawer = useAppSelector((state: RootState) => state.state.drawer.vehicle);

  const handleClose = () => {
    dispatch(closeVehicleDrawer());
  };

  const createVehicle = (data: VehicleInterface) => {
    console.log(data);
    // mutateCreate(data);
    handleClose();
  }

  const updateVehicle = (data: VehicleInterface) => {
    console.log(data);
    // mutateUpdate(data);
    handleClose();
  }

  const onVehicleFormFinish = (values: VehicleInterface) => {
    if (!vehicleDrawer.id) {
      createVehicle(values);
    } else {
      updateVehicle(values);
    }
  };

  return <Drawer onClose={handleClose} open={vehicleDrawer.open} destroyOnClose
    title={!vehicleDrawer.id ? i18n.translate('components.drawer.vehicle.titleCreate') : i18n.translate('components.drawer.vehicle.titleEdit')}
    width={500}
    extra={
      <Space>
        <Button onClick={handleClose}>{i18n.translate('components.drawer.vehicle.buttons.cancel')}</Button>
        <Button type="primary" onClick={() => dispatch(submitVehicleForm())}>{!vehicleDrawer.id ? i18n.translate('components.drawer.vehicle.buttons.create') : i18n.translate('components.drawer.vehicle.buttons.edit')}</Button>
      </Space>
    }>
    <VehicleForm
      onVehicleFormFinish={(values) => onVehicleFormFinish(values)}
      formItems={allVehicleFormItems.filter((f) => (f !== 'active'))}
      vehicle={vehicleDrawer.data ? vehicleDrawer.data : undefined}
      initialValues={vehicleDrawer.initialValues}
    ></VehicleForm>
  </Drawer>
}

export default VehicleDrawer;
