import { I18n } from 'i18n-js';

import en from '../../assets/i18n/en.json';
import hr from '../../assets/i18n/hr.json';

const i18nInit = new I18n({
  ...en,
  ...hr,
});

i18nInit.defaultLocale = 'hr';
i18nInit.enableFallback = true;
i18nInit.translations = { en, hr };

export const i18n = i18nInit;

export const setLang = (l: string | null) => {
  if (l === null) return;
  i18n.locale = l;
  localStorage.setItem('APP_LANG', l);
};

const lang = localStorage.getItem('APP_LANG');
setLang(lang);

export default i18n;
