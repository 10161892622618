import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { notification } from 'antd';
import { AppRoutes } from '../../../pages/app/_router/app.routes';
import { AuthRoutes } from '../../../pages/auth';
import { getLanguageId } from '../../constants';
import { JsonApiService, UserService } from '../../api';
import { ChangePasswordInterface, ForgotPasswordInterface, LoginInterface, LoginResponseInterface, ResetPasswordInterface, UserFunctionInterface, UserInterface } from '../../interfaces';
import { i18n, navigate } from '../../services';
import type { AppDispatch } from '../store';

// ============ INTERFACE ============

export interface AuthStateInterface {
  lang: 'hr' | 'en';
  langTime: number;
  isAuthenticated: boolean;
  xTenantUuid?: string;
  accessToken?: string;
  refreshToken?: string;
  accessTokenValidTo?: string;
  refreshTokenValidTo?: string;
  user?: UserInterface;
  userFunctions?: Array<UserFunctionInterface>;
  error?: Error;
}

// ============ INIT STATE ============

const initialState: AuthStateInterface = {
  lang: 'hr',
  langTime: 0,
  isAuthenticated: false,
  xTenantUuid: ''
};


// ========= SLICE ==========

export const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    loginSuccess: (state: AuthStateInterface, action: PayloadAction<LoginResponseInterface>) => {
      state.isAuthenticated = true;
      state.accessToken = action.payload.auth.accessToken;
      state.refreshToken = action.payload.auth.refreshToken;
      state.accessTokenValidTo = action.payload.auth.accessTokenValidTo;
      state.refreshTokenValidTo = action.payload.auth.refreshTokenValidTo;
      state.user = action.payload.user;
      state.userFunctions = action.payload.functions;
    },
    loginError: (state: AuthStateInterface, action: PayloadAction<Error>) => {
      state.error = action.payload;
    },
    setTenant: (state: AuthStateInterface, action: PayloadAction<string>) => {
      state.xTenantUuid = action.payload;
    },
    setLanguage: (state: AuthStateInterface, action: PayloadAction<'hr' | 'en'>) => {
      state.lang = action.payload;
      state.langTime = Date.now();
    },
    logoutSuccess: (state: AuthStateInterface) => {
      state.isAuthenticated = false;
      state.accessToken = undefined;
      state.refreshToken = undefined;
      state.accessTokenValidTo = undefined;
      state.refreshTokenValidTo = undefined;
      state.user = undefined;
      state.userFunctions = undefined;
    },
    refreshSuccess: (state: AuthStateInterface, action: PayloadAction<LoginResponseInterface['auth']>) => {
      state.isAuthenticated = true;
      state.accessToken = action.payload.accessToken;
      state.refreshToken = action.payload.refreshToken;
      state.accessTokenValidTo = action.payload.accessTokenValidTo;
      state.refreshTokenValidTo = action.payload.refreshTokenValidTo;
    },
  }
})

export const { setTenant, loginSuccess, loginError, logoutSuccess, setLanguage, refreshSuccess } = authSlice.actions;

export const changeLanguage = (data: 'hr' | 'en') => (dispatch: AppDispatch) => {
  const lang = getLanguageId(data);
  dispatch(setLanguage(lang));
}

export const login = (data: LoginInterface) => (dispatch: AppDispatch) =>
  UserService.login(data).subscribe({
    next: (response: LoginResponseInterface) => {
      dispatch(loginSuccess(response));
      dispatch(changeLanguage(response.user.language!));
      notification.success({ message: i18n.translate('common.banners.auth.loginSuccess'), duration: 2 });
      JsonApiService.getInstance().initClient();
      navigate(AppRoutes.DASHBOARD.fullPath);
    },
    error: (error: Error) => {
      dispatch(loginError(error));
      notification.error({ message: i18n.translate('common.banners.auth.loginError'), duration: 2 });
    }
  });

export const logout = () => (dispatch: AppDispatch) => {
  dispatch(logoutSuccess());
  notification.success({ message: i18n.translate('common.banners.auth.logoutSuccess'), duration: 2 });
  navigate(AuthRoutes.LOGIN.fullPath);
};

export const forgotPassword = (data: ForgotPasswordInterface) => (dispatch: AppDispatch) =>
  UserService.forgotPassword(data).subscribe({
    next: () => {
      notification.success({ message: i18n.translate('common.banners.auth.forgotPasswordSuccess'), duration: 5 });
      if (navigate) navigate(AuthRoutes.LOGIN.fullPath);
    },
    error: (error: Error) => {
      dispatch(loginError(error));
      notification.error({ message: i18n.translate('common.banners.auth.forgotPasswordError'), duration: 5 });
    }
  });

export const resetPassword = (data: ResetPasswordInterface) => (dispatch: AppDispatch) =>
  UserService.resetPassword(data).subscribe({
    next: () => {
      notification.success({ message: i18n.translate('common.banners.auth.resetPasswordSuccess'), duration: 5 });
      navigate(AuthRoutes.LOGIN.fullPath);
    },
    error: (error: Error) => {
      dispatch(loginError(error));
      notification.error({ message: i18n.translate('common.banners.auth.resetPasswordError'), duration: 5 });
    }
  });

export const changePassword = (data: ChangePasswordInterface) => (dispatch: AppDispatch) =>
  UserService.changePassword(data).subscribe({
    next: () => {
      notification.success({ message: i18n.translate('components.other.changePassword.msgs.success'), duration: 3 });
      navigate(AppRoutes.PROFILE.fullPath);
    },
    error: (error: Error) => {
      dispatch(loginError(error));
      notification.error({ message: i18n.translate('components.other.changePassword.msgs.error'), duration: 3 });
    }
  });  

export default authSlice.reducer;
