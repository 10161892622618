import { Button, Flex} from 'antd';
import React from 'react';
import { AddIcon } from 'src/components/icons';
import { i18n, useAppDispatch } from 'src/common';
import { VehiclesTable } from 'src/components/tables';
import { VehicleDrawer } from 'src/components/drawers';
import { openVehicleDrawerAndSetInitialValues } from 'src/common/redux/drawer/drawer.slice';

interface Props {
  clientId?: number;
}

const VehiclesTab = ({clientId}: Props) => {

  const dispatch = useAppDispatch();

  return (
    <>
      <Flex justify='end' align='center'>
        <Button
          className='mb16'
          onClick={() => dispatch(openVehicleDrawerAndSetInitialValues({ initialValues: [{formItem: 'client', value: clientId as number}] }))}
          type="primary"
          icon={<AddIcon />}
        >
          {i18n.t('components.tabs.vehicles.create')}
        </Button>
      </Flex>
      <VehiclesTable></VehiclesTable>
      <VehicleDrawer></VehicleDrawer>
    </>
  );
}

export default VehiclesTab;
