import { Button, Flex } from 'antd';
import React from 'react';
import { AddIcon } from 'src/components/icons';
import { i18n, useAppDispatch } from 'src/common';
import { DepotsTable } from 'src/components/tables';
import { DepotDrawer } from 'src/components/drawers';
import { openDepotDrawerAndSetInitialValues } from 'src/common/redux/drawer/drawer.slice';

const DepotsTab = () => {

  const dispatch = useAppDispatch();

  return (
    <>
      <Flex justify='end' align='center'>
        <Button
          className='mb16'
          onClick={() => dispatch(openDepotDrawerAndSetInitialValues({ initialValues: []}))}
          type="primary"
          icon={<AddIcon />}
        >
          {i18n.t('components.tabs.depots.create')}
        </Button>
      </Flex>
      <DepotsTable></DepotsTable>
      <DepotDrawer></DepotDrawer>
    </>
  );
}

export default DepotsTab;
