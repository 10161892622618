/* eslint-disable max-classes-per-file */
import { Observable } from 'rxjs';
import { ChangePasswordInterface, ForgotPasswordInterface, LoginInterface, LoginResponseInterface, ResetPasswordInterface } from '../interfaces';
import HttpFactory from './abstract.api.service';

export const apiRoutes: { [key: string]: string } = {
  login: `auth/login`,
  forgotPassword: `auth/send-reset-key`,
  resetPassword: `auth/reset-password-key`,
  changePassword: `auth/reset-password`
};

export class UserService {
  static login(data: LoginInterface): Observable<LoginResponseInterface> {
    return HttpFactory.POST(apiRoutes.login, data);
  }

  static forgotPassword(data: ForgotPasswordInterface) {
    return HttpFactory.POST(apiRoutes.forgotPassword, data);
  }

  static resetPassword(data: ResetPasswordInterface) {
    return HttpFactory.POST(apiRoutes.resetPassword, data);
  }

  static changePassword(data: ChangePasswordInterface) {
    return HttpFactory.POST(apiRoutes.changePassword, data);
  }

  static getInfiniteUrl(uuid: string): Observable<any> {
    const url = apiRoutes.infiniteUrl.replace('{uuid}', uuid);
    return HttpFactory.GET(url);
  }

  static getTempUrl(uuid: string): Observable<any> {
    const url = apiRoutes.tempUrl.replace('{uuid}', uuid);
    return HttpFactory.GET(url);
  }
}
