import React from 'react';
import { Space, Flex } from 'antd';
import { ForgotPasswordInterface, forgotPassword, useAppDispatch } from '../../../common';
import Logo from '../../../assets/images/logo.svg';
import { ForgotPasswordForm } from '../../../components';

const ForgotPasswordPage = () => {

  const dispatch = useAppDispatch();

  const onForgotPassword = (values: any) => {
    const forgotPasswordData: ForgotPasswordInterface = {
      email: values.email,
    };
    dispatch(forgotPassword(forgotPasswordData));
  };

  const onForgotPasswordFailed = (errorInfo: any) => {
    console.log('Failed:', errorInfo);
  };

  return (
    <Flex justify='center' align='center' style={{ height: '100%' }}>
      <Space style={{ maxWidth: 420, minWidth: 360 }} direction='vertical' size={24}>
        <img width={'auto'} height={24} src={Logo} alt="" />
        <ForgotPasswordForm onForgotPasswordFormFinish={(values) => onForgotPassword(values)} onForgotPasswordFormFinishFailed={(errorInfo) => onForgotPasswordFailed(errorInfo)}></ForgotPasswordForm>
      </Space>
    </Flex>
  );
}

export default ForgotPasswordPage;
