import { Drawer, Space, Button } from 'antd'
import React from 'react'
import { ClientInterface, useAppSelector, RootState, useAppDispatch, i18n } from 'src/common';
import { closeClientDrawer, submitClientForm } from 'src/common/redux/drawer/drawer.slice';
import ClientForm, { allClientFormItems } from '../forms/client.form';

const ClientDrawer = () => {

  const dispatch = useAppDispatch();

  const clientDrawer = useAppSelector((state: RootState) => state.state.drawer.client);

  const handleClose = () => {
    dispatch(closeClientDrawer());
  };

  const createClient = (data: ClientInterface) => {
    console.log(data);
    // mutateCreate(data);
    handleClose();
  }

  const updateClient = (data: ClientInterface) => {
    console.log(data);
    // mutateUpdate(data);
    handleClose();
  }

  const onClientFormFinish = (values: ClientInterface) => {
    if (!clientDrawer.id) {
      createClient(values);
    } else {
      updateClient(values);
    }
  };

  return <Drawer onClose={handleClose} open={clientDrawer.open} destroyOnClose
    title={!clientDrawer.id ? i18n.translate('components.drawer.client.titleCreate') : i18n.translate('components.drawer.client.titleEdit')}
    width={500}
    extra={
      <Space>
        <Button onClick={handleClose}>{i18n.translate('components.drawer.client.buttons.cancel')}</Button>
        <Button type="primary" onClick={() => dispatch(submitClientForm())}>{!clientDrawer.id ? i18n.translate('components.drawer.client.buttons.create') : i18n.translate('components.drawer.client.buttons.edit')}</Button>
      </Space>
    }>
    <ClientForm
      onClientFormFinish={(values) => onClientFormFinish(values)}
      formItems={allClientFormItems.filter((f) => (f !== 'active'))}
      client={clientDrawer.data ? clientDrawer.data : undefined}
    ></ClientForm>
  </Drawer>
}

export default ClientDrawer;
