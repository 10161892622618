import { AnyAction, Reducer, combineReducers, configureStore } from '@reduxjs/toolkit';
import { persistReducer, persistStore } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import authReducer from './model/auth.slice';
import layoutReducer from './state/layout.slice';
import drawerReducer from './drawer/drawer.slice';

// === LocalSotrage persist definition
const persistConfig = {
  key: 'dostava-app',
  storage,
  whitelist: ['model']
}

// === List of all redducers
const combinedReducer = combineReducers({
  model: combineReducers({
    auth: authReducer
  }),
  state: combineReducers({
    layout: layoutReducer,
    drawer: drawerReducer
  }),
});

// model: persistReducer(persistConfig, combineReducers({
//   auth: authReducer
// })), -- ovako nije htjelo mijenjati u LS na promjenu u reduxu

const rootReducer: Reducer = (store: RootState, action: AnyAction): RootState => {
  console.log(action.type)
  if (action.type === 'auth/logoutSuccess') {
    store = { state: {}, model: { }} as RootState;
    console.log(action.type, store)
  }

  return combinedReducer(store, action);
};

// export const AppReducers = (state: any, action: any): RootState => {
//   return AllReducers(state, action);
// };

const persistedReducer = persistReducer(persistConfig, rootReducer);

// const initialState = {};

// const middleware = [thunk];

export const store = configureStore(
  {
    reducer: persistedReducer,
    devTools: process.env.REACT_APP_IS_DEV === 'true',
    middleware: (getDefaultMiddleware) =>
      getDefaultMiddleware({
        serializableCheck: false,
        immutableCheck: false
      }),
  }
  // persistedReducer,
  // initialState,
  // composeWithDevTools(applyMiddleware(...middleware))
  // compose(applyMiddleware(...middleware))
);

export type RootState = ReturnType<typeof combinedReducer>;
export type AppDispatch = typeof store.dispatch;

export const persistor = persistStore(store);

// export type RootState = ReturnType<typeof AllReducers>;
