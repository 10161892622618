import { OrderStatusEnum } from "../enums";
import { i18n } from "../services";

export interface OrderStatus {
  id: number;
  name: string;
}

export const OrderStatuses = (): Array<OrderStatus> => [
  { id: OrderStatusEnum.CREATED, name: i18n.translate("common.constants.orderStatus.created") },
  { id: OrderStatusEnum.RECEIVED, name: i18n.translate("common.constants.orderStatus.received") },
  { id: OrderStatusEnum.PROCESSING, name: i18n.translate("common.constants.orderStatus.processing") },
  { id: OrderStatusEnum.IN_VEHICLE, name: i18n.translate("common.constants.orderStatus.inVehicle") },
  { id: OrderStatusEnum.IN_STORAGE, name: i18n.translate("common.constants.orderStatus.inStorage") },
  { id: OrderStatusEnum.DELIVERED, name: i18n.translate("common.constants.orderStatus.delivered") },
  { id: OrderStatusEnum.INVALIDATED, name: i18n.translate("common.constants.orderStatus.invalidated") },
]

export const getOrderStatusName = (id: number): string =>
  OrderStatuses().find((sos: OrderStatus) => sos.id === id) ? OrderStatuses().find((sos: OrderStatus) => sos.id.toString() === id.toString())!.name : "";

// TODO DORADITI NAKON ŠTO SE FINALIZIRAJU STATUSI I NJIHOVI ID-JEVI
export const getTrackingStatusString = (status: string, senderAddress?: string, receiverAddress?: string, depotName?: string, depotAddress?: string): string => {
  switch (status) {
    case "KREIRANO":
      return i18n.t(`pages.tracking.timeline.${status}.info`);
    case "ZAPRIMLJENO":
      return i18n.t(`pages.tracking.timeline.${status}.info`);
    case "U_VOZILU":
      return i18n.t(`pages.tracking.timeline.${status}.info`, { senderAddress, receiverAddress });
    case "LINIJSKI_PRIJEVOZ":
      return i18n.t(`pages.tracking.timeline.${status}.info`);
    case "U_SKLADIŠTU":
      return i18n.t(`pages.tracking.timeline.${status}.info`, { depotName, depotAddress });
    case "DOSTAVLJENO":
      return i18n.t(`pages.tracking.timeline.${status}.info`);
    default:
      return '';
  }
}
