import { Breadcrumb, Button, Card, Flex, Tabs, TabsProps, Typography } from 'antd';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { i18n } from '../../../common';
import { AddIcon, L1, OrdersTable, T1 } from '../../../components';
import { AppRoutes } from '../_router/app.routes';

const { Title } = Typography;

const OrdersPage = () => {
  const navigate = useNavigate();

  const renderAllOrders = (): JSX.Element => (
    <OrdersTable></OrdersTable>
  );

  const renderFreeOrders = (): JSX.Element => (
    <OrdersTable></OrdersTable>
  );

  const tabs: TabsProps['items'] = [
    {
      key: '1',
      label: <T1 style={{ color: 'inherit' }}>{i18n.t('pages.orders.allOrders')}</T1>,
      children: renderAllOrders(),
    },
    {
      key: '2',
      label: <T1 style={{ color: 'inherit' }}>{i18n.t('pages.orders.freeOrders')}</T1>,
      children: renderFreeOrders(),
    },
  ];

  const renderContent = (): JSX.Element => <Tabs items={tabs}></Tabs>; // destroyInactiveTabPane={true}

  return (
    <>
      <Breadcrumb
        items={[
          { title: <L1 to={AppRoutes.ORDERS.fullPath}>{i18n.t('common.breadcrumb.orders')}</L1> }
        ]}
        style={{ marginLeft: 32, marginBottom: 16 }}
      />
      <Card>
        <Flex justify='space-between' align='center' style={{ marginBottom: 16 }}>
          <Title level={2} style={{ margin: 0 }}>
            {i18n.t('pages.orders.title')}
          </Title>

          <Button
            type="primary"
            icon={<AddIcon />}
            onClick={() => {
              navigate(`${AppRoutes.ORDERS.fullPath}/new`);
            }}
          >
            {i18n.t('pages.orders.create')}
          </Button>
        </Flex>
        {renderContent()}
      </Card>
    </>
  );
};

export default OrdersPage;
