import React, { useState } from "react";
import { Table as AntTable, Flex } from 'antd';
import { ColumnsType } from "antd/es/table";
import { useQuery } from "src/lib/jsonapi-react/src";
import type { ExpandableConfig } from 'antd/es/table/interface';
import { QueryParams, i18n, OrderInterface, DefaultPagination, FetchParams, SegmentInterface, ClientInterface } from "../../common";

import { L1, T1, T3 } from "../texts";
import { AppRoutes } from "../../pages/app";
import { AddressValues, ClientValues, ColumnSearch } from "../filters";
import { MoreIcon } from "../icons";
import Table, { ColumnFilter, TableParams } from "./table";

type OrdersTableColumnType = 'uuid' | 'deliveryDate' | 'orderer' | 'sender' | 'senderAddress' | 'receiver' | 'receiverAddress' | 'latestOrderState' | 'orders' | 'actions';
type OrdersTableFilterType = 'uuid' | 'deliveryDate' | 'orderer' | 'sender' | 'senderAddress' | 'receiver' | 'receiverAddress' | 'latestOrderState' | 'orders';

export const allOrdersTableColumns: Array<OrdersTableColumnType> = ['uuid', 'deliveryDate', 'orderer', 'sender', 'senderAddress', 'receiver', 'receiverAddress', 'latestOrderState', 'orders', 'actions'];
export const allOrdersTableFilters: Array<OrdersTableFilterType> = ['uuid', 'deliveryDate', 'orderer', 'sender' , 'senderAddress' , 'receiver' , 'receiverAddress' , 'latestOrderState' , 'orders'];

interface Props {
  injectedQueryParams?: QueryParams;
  columns?: Array<OrdersTableColumnType>;
  filters?: Array<OrdersTableFilterType>;
}

const OrdersTable = ({ injectedQueryParams, columns, filters }: Props) => {

  const componentQueryParams: QueryParams = {
    ...injectedQueryParams,
    includes: [
      ...(injectedQueryParams?.includes || []),
      'orderer',
      'sender',
      'senderAddress',
      'receiver',
      'receiverAddress',
      'latestOrderState',
      // 'parcels', // BE baca 403 ako dodamo i parcels i segments u include
      'segments',
      'segments.fromAddress',
      'segments.toAddress',
      'segments.latestSegmentState'
    ],
    sorters: [
      { columnKey: 'id', order: 'descend', field: 'id' }
    ]
  };

  const [ queryParams, setQueryParams ] = useState<QueryParams>(componentQueryParams);

  const params: FetchParams = {
    type: 'order',
    queryParams
  };

  const { data, meta } = useQuery(params);

  const tableColumns: Array<OrdersTableColumnType> = columns || allOrdersTableColumns;
  const tableFilters: Array<OrdersTableFilterType> = filters || allOrdersTableFilters;

  const ordersTableColumns: ColumnsType<OrderInterface> = [
    {
      title: i18n.t('components.tables.orders.uuid'),
      dataIndex: 'uuid',
      key: 'uuid',
      render: (dataIndex: string, order: OrderInterface) => <L1 to={`${AppRoutes.ORDERS.fullPath}/${order.id}`}>{order.uuid}</L1>,
      sorter: true,
      width: 300,
      search: tableFilters.find(f => f === 'uuid') && { component: ColumnSearch, key: 'uuid', placeholder: i18n.t('components.tables.orders.uuidPlaceholder') }
    },
    {
      title: i18n.t('components.tables.orders.orderer'),
      dataIndex: 'orderer',
      key: 'orderer.id',
      render: (dataIndex: string, order: OrderInterface) => order.orderer && <L1 to={`${AppRoutes.CLIENTS.fullPath}/${order.orderer.id}}`}>{order.orderer.name}</L1>,
      width: 300,
      filters: tableFilters.find(f => f === 'orderer') && ClientValues().map((c: ClientInterface) => ({ text: c.name, value: c.id} as ColumnFilter)),
    },
    {
      title: i18n.t('components.tables.orders.sender'),
      dataIndex: 'sender',
      key: 'sender.id',
      render: (dataIndex: string, order: OrderInterface) => order.sender && <L1 to={`${AppRoutes.CLIENTS.fullPath}/${order.sender.id}}`}>{order.sender.name}</L1>,
      width: 300,
      filters: tableFilters.find(f => f === 'sender') && ClientValues().map((c: ClientInterface) => ({ text: c.name, value: c.id} as ColumnFilter))
    },
    {
      title: i18n.t('components.tables.orders.senderAddress'),
      dataIndex: 'senderAddress',
      key: 'senderAddress.id',
      render: (dataIndex: string, order: OrderInterface) => order.senderAddress && order.senderAddress.street,
      width: 300,
      sorter: true,
      filters: tableFilters.find(f => f === 'senderAddress') && AddressValues()
    },
    {
      title: i18n.t('components.tables.orders.receiver'),
      dataIndex: 'receiver',
      key: 'receiver.id',
      render: (dataIndex: string, order: OrderInterface) => order.receiver && <L1 to={`${AppRoutes.CLIENTS.fullPath}/${order.receiver.id}}`}>{order.receiver.name}</L1>,
      width: 300,
      filters: tableFilters.find(f => f === 'receiver') && ClientValues().map((c: ClientInterface) => ({ text: c.name, value: c.id} as ColumnFilter))
    },
    {
      title: i18n.t('components.tables.orders.receiverAddress'),
      dataIndex: 'receiverAddress',
      key: 'receiverAddress.id',
      render: (dataIndex: string, order: OrderInterface) => order.receiverAddress && order.receiverAddress.street,
      width: 300,
      filters: tableFilters.find(f => f === 'receiverAddress') && AddressValues()
    },
    {
      title: i18n.t('components.tables.orders.latestOrderState'),
      dataIndex: 'latestOrderState',
      key: 'latestOrderState.id',
      render: (dataIndex: string, order: OrderInterface) => order.latestOrderState ? order.latestOrderState.status : '-', // getOrderStatusName(order.latestOrderState?.id) : '-', // TODO staviti ih u tagove i srediti statuse
      width: 150
    },
    /**
    {
      title: i18n.t('components.tables.orders.location'),
      dataIndex: 'latestStatus',
      key: 'latestStatus.id',
      render: (dataIndex: string, order: OrderInterface) => order.latestOrderState ? ? order.latestOrderState.status : '-', // getOrderStatusName(order.latestOrderState?.id) : '-', // TODO staviti ih u tagove i srediti statuse
      width: 300
    },
    */
    {
      title: i18n.translate(`components.tables.orders.actions`),
      dataIndex: 'actions',
      key: 'actions',
      render: () => <MoreIcon />,
      width: 100
    },
    AntTable.EXPAND_COLUMN
  ].filter((c) => !('dataIndex' in c) || tableColumns.includes(c.dataIndex! as OrdersTableColumnType));

  const expandable: ExpandableConfig<any> = {
    expandedRowRender: (order: OrderInterface) => {
      const segmentsTableColumns: ColumnsType<SegmentInterface> = [
        {
          title: i18n.t('components.tables.orders.segments.from'),
          dataIndex: 'fromAddress',
          key: 'fromAddress',
          render: (dataIndex: string, segment: SegmentInterface) => segment.fromAddress?.street,
          width: 300
        },
        {
          title: i18n.t('components.tables.orders.segments.to'),
          dataIndex: 'toAddress',
          key: 'toAddress',
          render: (dataIndex: string, segment: SegmentInterface) => segment.toAddress?.street,
          width: 300
        },
        {
          title: i18n.t('components.tables.orders.segments.state'),
          dataIndex: 'latestSegmentState',
          key: 'latestSegmentState',
          render: (dataIndex: string, segment: SegmentInterface) => segment.latestSegmentState?.status,
          width: 300
        },
        {
          title: i18n.t('components.tables.orders.segments.deliveryList'),
          dataIndex: 'deliveryList',
          key: 'deliveryList',
          render: (dataIndex: string, segment: SegmentInterface) => segment.deliveryList?.id, // Sa BE fali deliveryList u segmentu
          width: 300
        },
        {
          title: i18n.t('components.tables.orders.segments.driver'),
          dataIndex: 'deliveryList.driver',
          key: 'deliveryList.driver',
          render: (dataIndex: string, segment: SegmentInterface) => segment.deliveryList?.driver, // Sa BE fali deliveryList u segmentu
          width: 300
        },
        {
          title: i18n.t('components.tables.orders.segments.note'),
          dataIndex: 'note',
          key: 'note',
          render: (dataIndex: string, segment: SegmentInterface) => segment.note,
          width: 300
        }
      ]
      return <Flex vertical gap={24}>
        <Flex gap={24}>
          <T1>{`${i18n.t('components.tables.orders.numberOfParcels')}: ${order.parcels?.length ? order.parcels.length : 0}`}</T1>
          <T1>{`${i18n.t('components.tables.orders.chargeableWeight')}: ${order.parcels?.length ? order.parcels.length : 0}`}</T1>
        </Flex>
        {order.note && <Flex vertical><T1>{`${i18n.t('components.tables.orders.note')}: `}</T1><T3>{order.note}</T3></Flex>}
        <AntTable columns={segmentsTableColumns} dataSource={order.segments} pagination={false}></AntTable>
      </Flex>;
    }
  }

  
  // Potrebno prilagoditi funkciju ovisno o tome koje su sve mogućnosti
  const handleTableChange = (tableParams: TableParams) => {
    setQueryParams({
      pagination: tableParams.pagination,
      filters: [
        ...(tableParams.filters || []),
        ...(componentQueryParams?.filters || []),
      ],
      sorters: tableParams.sorters || componentQueryParams.sorters,
      includes: componentQueryParams.includes,
      search: tableParams.search
    })
  };

  // const handleGroupAction = (selectedRows: Array<OrderInterface>, selectedGroupAction?: number) => {
  //   setSelectedOrders(selectedRows);
  //   groupActions?.find(x => x.key === selectedGroupAction)?.action();
  // };

  console.log(data)

  if (!data) return null;
  return (
    <>
      <Table
        rowKey={"id"}
        columns={ordersTableColumns}
        dataSource={data}
        handleTableChange={handleTableChange}
        paginationDefault={DefaultPagination}
        total={meta.totalResourceCount}
        expandable={expandable}
      />
    </>
  )
}

export default OrdersTable;
