import { Breadcrumb, Button, Card, Flex, Typography } from 'antd';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { i18n } from '../../../common';
import { AddIcon, DeliveryListsTable } from '../../../components';
import { AppRoutes } from '../_router/app.routes';

const { Title } = Typography;

const DeliveryListsPage = () => {
  const navigate = useNavigate();

  return (
    <>
      <Breadcrumb
        items={[
          { title: i18n.t('common.breadcrumb.deliveryLists') }
        ]}
        style={{ marginLeft: 32, marginBottom: 16 }}
      />
      <Card>
        <Flex justify='space-between' align='center' style={{ marginBottom: 16 }}>
          <Title level={2} style={{ margin: 0 }}>
            {i18n.t('pages.deliveryLists.title')}
          </Title>

          <Button
            type="primary"
            icon={<AddIcon />}
            onClick={() => {
              navigate(`${AppRoutes.DELIVERY_LISTS.fullPath}/new`);
            }}
          >
            {i18n.t('pages.deliveryLists.create')}
          </Button>

        </Flex>
        <DeliveryListsTable></DeliveryListsTable>
      </Card>
    </>
  );
};

export default DeliveryListsPage;
