import React from "react";
import { Typography } from "antd";

const { Title } = Typography;

interface Props {
  children: React.JSX.Element | string | any; 
  style?: React.CSSProperties;
}

// SemiBold 18px
const H2 = ({ children, style }: Props) => ( 
  <Title level={2} style={{ margin: 0, padding: 0, fontWeight: 600, ...style }}>{children}</Title>
)

export default H2;
