import React, { useEffect } from 'react';
import { Link, useParams } from 'react-router-dom';
import { Breadcrumb, Card } from 'antd';
import { i18n } from '../../../common';
import { DeliveryListForm } from '../../../components';
import { AppRoutes } from '../_router/app.routes';

const DeliveryListEditPage = () => {
  // const dispatch = useAppDispatch();

  const params = useParams();

  // const queryParams: QueryParams = { includes: ['dbRole'] };

  // TODO možda dodatno zaštititi da se ne dohvaća s api-ja ako je već u reduxu
  useEffect(() => {
    // if (params.deliveryList_id) dispatch(getDeliveryList(parseInt(params.deliveryList_id!, 10), queryParams));
  }, [params]
  );

  // const deliveryList: DeliveryListInterface | undefined = useAppSelector((state: RootState) => state.deliveryList.one);

  // if (!deliveryList || !deliveryList.id) return null;

  // const onDeliveryListEdit = (values: DeliveryListInterface) => {
  // dispatch(updateDeliveryList(deliveryList.id!, values));
  // };

  return (
    <>
      <Breadcrumb style={{ marginBottom: 24 }} items={[
        { title: <Link to={AppRoutes.ORDERS.fullPath} key={AppRoutes.ORDERS.fullPath}>{i18n.t('common.breadcrumb.deliveryLists')}</Link> },
        { title: i18n.translate('common.breadcrumb.deliveryListEdit') }]} />
      <Card>
        <DeliveryListForm
          // deliveryList={deliveryList}
          // onDeliveryListFormFinish={(values) => onDeliveryListEdit(values)}
        />
      </Card>
    </>
  );
}

export default DeliveryListEditPage;
