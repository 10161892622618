import React, { useState } from "react";
import { Table as AntTable, Flex, Typography } from 'antd';
import { ColumnsType } from "antd/es/table";
import { useQuery } from "src/lib/jsonapi-react/src";
import dayjs from 'dayjs';
import type { ExpandableConfig } from 'antd/es/table/interface';
import { QueryParams, i18n, OrderInterface, FetchParams, DisplayDateFormat, DeliveryListInterface, ParcelInterface, ClientInterface } from "../../common";

import { L1, T1 } from "../texts";
import { AppRoutes } from "../../pages/app";
import { AddressValues, ClientValues } from "../filters";
import Table, { ColumnFilter, TableParams } from "./table";

const { Text } = Typography;

type OrdersTableColumnType = 'uuid' | 'deliveryDate' | 'orderer' | 'sender' | 'senderAddress' | 'receiver' | 'receiverAddress' | 'latestOrderState' | 'orders' | 'actions';
type OrdersTableFilterType = 'deliveryDate' | 'orderer' | 'sender' | 'senderAddress' | 'receiver' | 'receiverAddress' | 'latestOrderState' | 'orders';

export const allOrdersTableColumns: Array<OrdersTableColumnType> = ['uuid', 'deliveryDate', 'orderer', 'sender', 'senderAddress', 'receiver', 'receiverAddress', 'latestOrderState', 'orders', 'actions'];
export const allOrdersTableFilters: Array<OrdersTableFilterType> = ['deliveryDate', 'orderer', 'sender' , 'senderAddress' , 'receiver' , 'receiverAddress' , 'latestOrderState' , 'orders'];

interface Props {
  injectedQueryParams?: QueryParams;
  columns?: Array<OrdersTableColumnType>;
  filters?: Array<OrdersTableFilterType>;
}

const OrdersFormTable = ({ injectedQueryParams, columns, filters }: Props) => {

  // TODO - modificirati kad dio OrderForme

  const componentQueryParams: QueryParams = {
    ...injectedQueryParams,
    includes: [
      ...(injectedQueryParams?.includes || []),
      'orderer',
      'sender',
      'senderAddress',
      'receiver',
      'receiverAddress',
      'latestOrderState'
    ],
    sorters: [
      { columnKey: 'id', order: 'descend', field: 'id' }
    ]
  };

  const [ queryParams, setQueryParams ] = useState<QueryParams>(componentQueryParams);

  const params: FetchParams = {
    type: 'order',
    queryParams
  };

  const { data } = useQuery(params);
  console.log(data)
  const tableColumns: Array<OrdersTableColumnType> = columns || allOrdersTableColumns;
  const tableFilters: Array<OrdersTableFilterType> = filters || allOrdersTableFilters;

  const ordersTableColumns: ColumnsType<OrderInterface> = [
    {
      title: i18n.t('components.tables.orders.uuid'),
      dataIndex: 'uuid',
      key: 'uuid',
      render: (dataIndex: string, order: OrderInterface) => <L1 to={`${AppRoutes.ORDERS.fullPath}/${order.id}`}>{order.uuid}</L1>,
      sorter: true,
      width: 300
    },
    {
      title: i18n.t('components.tables.orders.deliveryDate'),
      dataIndex: 'deliveryDate',
      key: 'deliveryDate',
      render: (dataIndex: string, order: OrderInterface) => `${dayjs(order.deliveryDate).format(DisplayDateFormat)}`,
      width: 300
    },
    {
      title: i18n.t('components.tables.orders.orderer'),
      dataIndex: 'orderer',
      key: 'orderer.id',
      render: (dataIndex: string, order: OrderInterface) => order.orderer && <L1 to={`${AppRoutes.CLIENTS.fullPath}/${order.orderer.id}}`}>{order.orderer.name}</L1>,
      width: 300,
      filters: tableFilters.find(f => f === 'orderer') && ClientValues().map((c: ClientInterface) => ({ text: c.name, value: c.id} as ColumnFilter))
    },
    {
      title: i18n.t('components.tables.orders.sender'),
      dataIndex: 'sender',
      key: 'sender.id',
      render: (dataIndex: string, order: OrderInterface) => order.sender && <L1 to={`${AppRoutes.CLIENTS.fullPath}/${order.sender.id}}`}>{order.sender.name}</L1>,
      width: 300,
      filters: tableFilters.find(f => f === 'sender') && ClientValues().map((c: ClientInterface) => ({ text: c.name, value: c.id} as ColumnFilter))
    },
    {
      title: i18n.t('components.tables.orders.senderAddress'),
      dataIndex: 'senderAddress',
      key: 'senderAddress.id',
      render: (dataIndex: string, order: OrderInterface) => order.senderAddress && order.senderAddress.street,
      width: 300,
      filters: tableFilters.find(f => f === 'senderAddress') && AddressValues()
    },
    {
      title: i18n.t('components.tables.orders.receiver'),
      dataIndex: 'receiver',
      key: 'receiver.id',
      render: (dataIndex: string, order: OrderInterface) => order.receiver && <L1 to={`${AppRoutes.CLIENTS.fullPath}/${order.receiver.id}}`}>{order.receiver.name}</L1>,
      width: 300,
      filters: tableFilters.find(f => f === 'receiver') && ClientValues().map((c: ClientInterface) => ({ text: c.name, value: c.id} as ColumnFilter))
    },
    {
      title: i18n.t('components.tables.orders.receiverAddress'),
      dataIndex: 'receiverAddress',
      key: 'receiverAddress.id',
      render: (dataIndex: string, order: OrderInterface) => order.receiverAddress && order.receiverAddress.street,
      width: 300,
      filters: tableFilters.find(f => f === 'receiverAddress') && AddressValues()
    },
    {
      title: i18n.t('components.tables.orders.latestOrderState'),
      dataIndex: 'latestOrderState',
      key: 'latestOrderState.id',
      render: (dataIndex: string, order: OrderInterface) => order.latestOrderState ? order.latestOrderState.status : '-', // getOrderStatusName(order.latestOrderState?.id) : '-', // TODO staviti ih u tagove i srediti statuse
      width: 150
    },
    {
      title: i18n.t('components.tables.orders.deliveryLists'),
      dataIndex: 'deliveryLists',
      key: 'deliveryLists',
      render: (dataIndex: string, order: OrderInterface) => (order.deliveryLists && order.deliveryLists.length > 0) ? order.deliveryLists.map((d: DeliveryListInterface) => d.uuid) : '-',
      // sorter: true,
      width: 300
    },
    AntTable.EXPAND_COLUMN,
    // {
    //   title: i18n.translate(`components.tables.orders.actions`),
    //   dataIndex: 'actions',
    //   key: 'actions',
    //   render: (dataIndex: string, order: OrderInterface) => '...'
    // },
  ].filter((c) => !('dataIndex' in c) || tableColumns.includes(c.dataIndex! as OrdersTableColumnType));

  console.log(ordersTableColumns)

  const expandable: ExpandableConfig<any> = {
    expandedRowRender: (order: OrderInterface) => {
      const parcelsTableColumns: ColumnsType<ParcelInterface> = [
        {
          title: i18n.t('components.tables.orders.parcels.parcelType'),
          dataIndex: 'parcelType',
          key: 'parcelType',
          render: (dataIndex: string, parcel: ParcelInterface) => parcel.parcelType,
          width: 300
        },
        {
          title: i18n.t('components.tables.orders.parcels.quantity'),
          dataIndex: 'quantity',
          key: 'quantity',
          render: (dataIndex: string, parcel: ParcelInterface) => parcel.quantity,
          width: 300
        },
        {
          title: i18n.t('components.tables.orders.parcels.weight'),
          dataIndex: 'weight',
          key: 'weight',
          render: (dataIndex: string, parcel: ParcelInterface) => parcel.weight,
          width: 300
        },
        {
          title: i18n.t('components.tables.orders.parcels.parcelType'),
          dataIndex: 'dimensions',
          key: 'dimensions',
          render: (dataIndex: string, parcel: ParcelInterface) => parcel.dimensions,
          width: 300
        },
        // {
        //   title: i18n.t('components.tables.orders.parcels.actions'),
        //   dataIndex: 'actions',
        //   key: 'actions',
        //   render: (dataIndex: string, parcel: ParcelInterface) => '...',
        //   width: 300
        // },
      ]
      console.log(order)
      return <Flex vertical>
        {order.note && <Text><T1>{i18n.t('components.tables.orders.note')}</T1>: {order.note}</Text>}
        <AntTable columns={parcelsTableColumns} dataSource={order.parcels} pagination={false}></AntTable>
      </Flex>;
    }
  }

  
  // Potrebno prilagoditi funkciju ovisno o tome koje su sve mogućnosti
  const handleTableChange = (tableParams: TableParams) => {
    console.log("tableparams", tableParams);
    setQueryParams({
      pagination: tableParams.pagination,
      filters: [
        ...(tableParams.filters || []),
        ...(componentQueryParams?.filters || []),
      ],
      sorters: tableParams.sorters || componentQueryParams.sorters,
      includes: componentQueryParams.includes,
      search: tableParams.search
    })
  };

  // const handleGroupAction = (selectedRows: Array<OrderInterface>, selectedGroupAction?: number) => {
  //   setSelectedOrders(selectedRows);
  //   groupActions?.find(x => x.key === selectedGroupAction)?.action();
  // };

  // if (!data) return null;
  return (
    <>
      <Table
        rowKey={"id"}
        columns={ordersTableColumns}
        dataSource={data}
        handleTableChange={handleTableChange}
        expandable={expandable}
      />
    </>
  )
}

export default OrdersFormTable;
