import { Button, Flex } from 'antd';
import React from 'react';
import { AddIcon } from 'src/components/icons';
import { QueryParams, i18n, useAppDispatch } from 'src/common';
import { ClientsTable } from 'src/components/tables';
import { ClientDrawer } from 'src/components/drawers';
import { openClientDrawerAndSetInitialValues } from 'src/common/redux/drawer/drawer.slice';

interface Props {
  clientId?: number;
}

const ClientsTab = ({clientId}: Props) => {

  console.log(clientId);

  const dispatch = useAppDispatch();

  const componentQueryParams: QueryParams = {
    /**
    filters: [
      {
        name: 'partners.id',
        operator: 'eq',
        value: clientId?.toString() as string
      }
    ]
    */
  }

  return (
    <>
      <Flex justify='end' align='center'>
        <Button
          className='mb16'
          onClick={() => dispatch(openClientDrawerAndSetInitialValues({ initialValues: []}))}
          type="primary"
          icon={<AddIcon />}
        >
          {i18n.t('components.tabs.clients.create')}
        </Button>
      </Flex>
      <ClientsTable injectedQueryParams={componentQueryParams}></ClientsTable>
      <ClientDrawer></ClientDrawer>
    </>
  );
}

export default ClientsTab;
