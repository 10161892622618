import { PaymentTypeEnum } from "../enums";
import { i18n } from "../services";

export interface PaymentType {
  code: string;
  label: string;
}

export const PaymentTypes = (): Array<PaymentType> => [
  { code: PaymentTypeEnum.CASH, label: i18n.translate("common.constants.paymentType.cash") },
  { code: PaymentTypeEnum.WIRE_TRANSFER, label: i18n.translate("common.constants.paymentType.wireTransfer") },
  { code: PaymentTypeEnum.CASH_ON_DELIVERY, label: i18n.translate("common.constants.paymentType.cashOnDelivery") },
  { code: PaymentTypeEnum.CASH_ON_DELIVERY_BY_CARD, label: i18n.translate("common.constants.paymentType.cashOnDeliveryCard") }
]

export const getPaymentTypeLabel = (code: string): string =>
  PaymentTypes().find((pt: PaymentType) => pt.code === code) ? PaymentTypes().find((pt: PaymentType) => pt.code === code)!.label : "";
