import { Result, Typography } from 'antd';
import React from 'react';
import Logo from '../../assets/images/logo.svg';
import { i18n } from '../../common';

const { Title, Text } = Typography;

export const GoToSubdomainPage = () => (
  <Result
    icon={<img height={64} src={Logo} alt="" />}
    title={<Title style={{}}>
      DOSTAVA APP
    </Title>}
    subTitle={<Text style={{}}>
      {i18n.translate(`pages.goToSubdomain.subtitle`)}
    </Text>}
  />
)





