import React, { useEffect } from 'react';
import { Card, Flex, Form, Input } from 'antd';
import TextArea from 'antd/es/input/TextArea';
import { resetSubmitClientForm } from 'src/common/redux/drawer/drawer.slice';
import { ClientInterface, RootState, i18n, useAppDispatch, useAppSelector } from '../../common';
import { H3, T1 } from '../texts';


export type ClientFormItemType = 'name' | 'vat' | 'note' | 'headquartersCity' | 'headquartersStreet' | 'headquartersContactPerson' | 'headquartersPhoneNumber' | 'destinationCity' | 'destinationStreet' | 'destinationContactPerson' | 'destinationPhoneNumber' | 'active';
export type InitialValueTypeClient = { formItem: ClientFormItemType, value: string | number | boolean };

export const allClientFormItems: Array<ClientFormItemType> = ['name', 'vat', 'note', 'headquartersCity', 'headquartersStreet', 'headquartersContactPerson', 'headquartersPhoneNumber', 'destinationCity', 'destinationStreet', 'destinationContactPerson', 'destinationPhoneNumber', 'active'];

interface Props {
  onClientFormFinish?: (values: any) => void;
  client?: ClientInterface;
  formItems?: Array<ClientFormItemType>;
  disabled?: Array<ClientFormItemType>;
  initialValues?: Array<InitialValueTypeClient>;
  submitBtnLabel?: string;
}

function ClientForm({ onClientFormFinish, client, formItems, disabled, initialValues }: Props) {
  
  const dispatch = useAppDispatch();

  const [form] = Form.useForm();

  const submitForm = useAppSelector((state: RootState) => state.state.drawer.client.submit);

  const onFormSubmit = (values: any) => {
    const data = {
      ...values,
      name: values.name,
      vat: values.vat,
      note: values.note
    }
    if (onClientFormFinish) onClientFormFinish(data);
  }

  const items: Array<ClientFormItemType> = formItems || allClientFormItems;

  const initialFormValues = {
    ...client,
    name: (initialValues?.find((fi: InitialValueTypeClient) => fi.formItem === 'name')?.value as string) || client?.name,
    vat: (initialValues?.find((fi: InitialValueTypeClient) => fi.formItem === 'vat')?.value as string) || client?.vat,

    // TODO: addresses
  }

  useEffect(() => {
    if (submitForm) {
      form.submit();
      dispatch(resetSubmitClientForm());
    }
  }, [submitForm]);

  return (<>
    <Form
      name='client'
      form={form}
      onFinish={onFormSubmit}
      initialValues={initialFormValues}
      layout={'vertical'}
    >
      <Flex gap={'16px'} vertical>
        <Form.Item
          name="name"
          label={<T1>{i18n.translate(`components.forms.client.name`)}</T1>}
          rules={[{ required: true, message: i18n.translate(`components.forms.client.required`) }]}
          hidden={!items.find(i => i === 'name')}
        >
          <Input size="small" disabled={!!disabled?.find(d => d === 'name')} />
        </Form.Item>
        <Form.Item
          name="vat"
          label={<T1>{i18n.translate(`components.forms.client.vat`)}</T1>}
          rules={[{ required: false, message: i18n.translate(`components.forms.client.required`) }]}
          hidden={!items.find(i => i === 'vat')}
        >
          <Input size="small" disabled={!!disabled?.find(d => d === 'vat')} />
        </Form.Item>
        <Form.Item
          name="note"
          label={<T1>{i18n.translate(`components.forms.client.note`)}</T1>}
          rules={[{ required: false, message: i18n.translate(`components.forms.client.required`) }]}
          hidden={!items.find(i => i === 'note')}
        >
          <TextArea size="small" disabled={!!disabled?.find(d => d === 'note')} />
        </Form.Item>


        <H3>{i18n.translate(`components.forms.client.headquartersAddresses`)}</H3>

        <Card>
          <Form.Item
            name="headquartersCity"
            label={<T1>{i18n.translate(`components.forms.client.headquartersCity`)}</T1>}
            rules={[{ required: true, message: i18n.translate(`components.forms.client.required`) }]}
            hidden={!items.find(i => i === 'headquartersCity')}
          >
            <Input size="small" disabled={!!disabled?.find(d => d === 'headquartersCity')} />
          </Form.Item>
          <Form.Item
            name="headquartersStreet"
            label={<T1>{i18n.translate(`components.forms.client.headquartersStreet`)}</T1>}
            rules={[{ required: true, message: i18n.translate(`components.forms.client.required`) }]}
            hidden={!items.find(i => i === 'headquartersStreet')}
          >
            <Input size="small" disabled={!!disabled?.find(d => d === 'headquartersStreet')} />
          </Form.Item>
          <Form.Item
            name="headquartersContactPerson"
            label={<T1>{i18n.translate(`components.forms.client.headquartersContactPerson`)}</T1>}
            rules={[{ required: true, message: i18n.translate(`components.forms.client.required`) }]}
            hidden={!items.find(i => i === 'headquartersContactPerson')}
          >
            <Input size="small" disabled={!!disabled?.find(d => d === 'headquartersContactPerson')} />
          </Form.Item>
          <Form.Item
            name="headquartersPhoneNumber"
            label={<T1>{i18n.translate(`components.forms.client.headquartersPhoneNumber`)}</T1>}
            rules={[{ required: true, message: i18n.translate(`components.forms.client.required`) }]}
            hidden={!items.find(i => i === 'headquartersPhoneNumber')}
          >
            <Input size="small" disabled={!!disabled?.find(d => d === 'headquartersPhoneNumber')} />
          </Form.Item>
        </Card>

        <H3>{i18n.translate(`components.forms.client.destinationAddresses`)}</H3>

        <Card>
          <Form.Item
            name="destinationCity"
            label={<T1>{i18n.translate(`components.forms.client.destinationCity`)}</T1>}
            rules={[{ required: false, message: i18n.translate(`components.forms.client.required`) }]}
            hidden={!items.find(i => i === 'destinationCity')}
          >
            <Input size="small" disabled={!!disabled?.find(d => d === 'destinationCity')} />
          </Form.Item>
          <Form.Item
            name="destinationStreet"
            label={<T1>{i18n.translate(`components.forms.client.destinationStreet`)}</T1>}
            rules={[{ required: false, message: i18n.translate(`components.forms.client.required`) }]}
            hidden={!items.find(i => i === 'destinationStreet')}
          >
            <Input size="small" disabled={!!disabled?.find(d => d === 'destinationStreet')} />
          </Form.Item>
          <Form.Item
            name="destinationContactPerson"
            label={<T1>{i18n.translate(`components.forms.client.destinationContactPerson`)}</T1>}
            rules={[{ required: false, message: i18n.translate(`components.forms.client.required`) }]}
            hidden={!items.find(i => i === 'destinationContactPerson')}
          >
            <Input size="small" disabled={!!disabled?.find(d => d === 'destinationContactPerson')} />
          </Form.Item>
          <Form.Item
            name="destinationPhoneNumber"
            label={<T1>{i18n.translate(`components.forms.client.destinationPhoneNumber`)}</T1>}
            rules={[{ required: false, message: i18n.translate(`components.forms.client.required`) }]}
            hidden={!items.find(i => i === 'destinationPhoneNumber')}
          >
            <Input size="small" disabled={!!disabled?.find(d => d === 'destinationPhoneNumber')} />
          </Form.Item>
        </Card>
      </Flex>
    </Form>
  </>
  );
}

export default ClientForm;
