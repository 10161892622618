import { Descriptions } from 'antd';
import React from 'react';
import { OrderInterface, ColorConstant, i18n } from '../../common';
import { T1, H3 } from '../texts';

interface Props {
  order?: OrderInterface;
}

const OrderDetails = ({order}: Props) => {

  // TODO - Pravilno mapirati po novim BE entitetima

  console.log(order)

  const descriptionsOrdererItems: Array<{label: string | JSX.Element, render: JSX.Element}> = [
    {
      label: <T1 style={{ color: ColorConstant.DARK_GREY.hash }}>{ i18n.t('components.details.order.orderer') }</T1>,
      render: <H3>{order?.orderer?.name ? order?.orderer?.name : '—'}</H3>
    },
    {
      label: <T1 style={{ color: ColorConstant.DARK_GREY.hash }}>{ i18n.t('components.details.order.ordererAddress') }</T1>,
      render: <H3>{order?.orderer?.address ? order?.orderer?.address : '—'}</H3>
    },
  ]

  const descriptionsSenderItems: Array<{label: string | JSX.Element, render: JSX.Element}> = [
    {
      label: <T1 style={{ color: ColorConstant.DARK_GREY.hash }}>{ i18n.t('components.details.order.sender') }</T1>,
      render: <H3>{order?.sender?.name ? order?.sender?.name : '—'}</H3>
    },
    {
      label: <T1 style={{ color: ColorConstant.DARK_GREY.hash }}>{ i18n.t('components.details.order.senderAddress') }</T1>,
      render: <H3>{order?.senderAddress ? order?.senderAddress?.street : '—'}</H3>
    },
    {
      label: <T1 style={{ color: ColorConstant.DARK_GREY.hash }}>{ i18n.t('components.details.order.senderContactPerson') }</T1>,
      render: <H3>{order?.sender?.contactPerson ? order?.sender?.contactPerson : '—'}</H3>
    },
    {
      label: <T1 style={{ color: ColorConstant.DARK_GREY.hash }}>{ i18n.t('components.details.order.senderContactNumber') }</T1>,
      render: <H3>{order?.sender?.contactNumber ? order?.sender?.contactNumber : '—'}</H3>
    },
  ]

  const descriptionsReceiverItems: Array<{label: string | JSX.Element, render: JSX.Element}> = [
    {
      label: <T1 style={{ color: ColorConstant.DARK_GREY.hash }}>{ i18n.t('components.details.order.receiver') }</T1>,
      render: <H3>{order?.receiver?.name ? order?.receiver?.name : '—'}</H3>
    },
    {
      label: <T1 style={{ color: ColorConstant.DARK_GREY.hash }}>{ i18n.t('components.details.order.receiverAddress') }</T1>,
      render: <H3>{order?.receiverAddress ? order?.receiverAddress?.street : '—'}</H3>
    },
    {
      label: <T1 style={{ color: ColorConstant.DARK_GREY.hash }}>{ i18n.t('components.details.order.receiverContactPerson') }</T1>,
      render: <H3>{order?.receiver?.contactPerson ? order?.receiver?.contactPerson : '—'}</H3>
    },
    {
      label: <T1 style={{ color: ColorConstant.DARK_GREY.hash }}>{ i18n.t('components.details.order.receiverContactNumber') }</T1>,
      render: <H3>{order?.receiver?.contactNumber ? order?.receiver?.contactNumber : '—'}</H3>
    },
  ]

  const descriptionsPaymentItems: Array<{label: string | JSX.Element, render: JSX.Element}> = [
    {
      label: <T1 style={{ color: ColorConstant.DARK_GREY.hash }}>{ i18n.t('components.details.order.receiver') }</T1>,
      render: <H3>{order?.payedBy ? order?.payedBy : '—'}</H3>
    },
    {
      label: <T1 style={{ color: ColorConstant.DARK_GREY.hash }}>{ i18n.t('components.details.order.receiverAddress') }</T1>,
      render: <H3>{order?.paymentType ? order?.paymentType : '—'}</H3>
    },
  ]

  /**
   * TODO Dynamic form element
  const descriptionsSpecificationsItems: Array<{label: string | JSX.Element, render: JSX.Element}> = [
    {
      label: <T1 style={{ color: ColorConstant.DARK_GREY.hash }}>{ i18n.t('components.details.order.receiver') }</T1>,
      render: <H3>{order?.receiver?.name ? order?.receiver?.name : '—'}</H3>
    },
    {
      label: <T1 style={{ color: ColorConstant.DARK_GREY.hash }}>{ i18n.t('components.details.order.receiverAddress') }</T1>,
      render: <H3>{order?.receiverAddress ? order?.receiverAddress?.street : '—'}</H3>
    },
    {
      label: <T1 style={{ color: ColorConstant.DARK_GREY.hash }}>{ i18n.t('components.details.order.receiverContactPerson') }</T1>,
      render: <H3>{order?.receiver?.contactPerson ? order?.receiver?.contactPerson : '—'}</H3>
    },
    {
      label: <T1 style={{ color: ColorConstant.DARK_GREY.hash }}>{ i18n.t('components.details.order.receiverContactNumber') }</T1>,
      render: <H3>{order?.receiver?.contactNumber ? order?.receiver?.contactNumber : '—'}</H3>
    },
  ]
  */

  const descriptionsShipmentItems: Array<{label: string | JSX.Element, render: JSX.Element}> = [
    {
      label: <T1 style={{ color: ColorConstant.DARK_GREY.hash }}>{ i18n.t('components.details.order.orderTotalValue') }</T1>,
      // srediti render nakon BE updatea entiteta
      render: <H3>{order?.payOnDelivery ? order?.payedBy : '—'}</H3>
    },
  ]

  const descriptionsRansomItems: Array<{label: string | JSX.Element, render: JSX.Element}> = [
    {
      label: <T1 style={{ color: ColorConstant.DARK_GREY.hash }}>{ i18n.t('components.details.order.ransomValue') }</T1>,
      // srediti render nakon BE updatea entiteta
      render: <H3>{order?.payOnDelivery ? order?.payedBy : '—'}</H3>
    },
  ]

  const descriptionsNoteItems: Array<{label: string | JSX.Element, render: JSX.Element}> = [
    {
      label: <T1 style={{ color: ColorConstant.DARK_GREY.hash }}>{ i18n.t('components.details.order.note') }</T1>,
      render: <H3>{order?.note ? order?.note : '—'}</H3>
    },
  ]

  return (
    <>
      <Descriptions column={descriptionsOrdererItems?.length} layout={'vertical'} colon={false} title={<H3>{i18n.translate(`components.details.order.ordererDetails`)}</H3>} style={{ marginBottom: 16 }}>
        {descriptionsOrdererItems.map((item: {label: string | JSX.Element, render: JSX.Element}, index: number) => (
          <Descriptions.Item key={index} label={item.label}>{item.render}</Descriptions.Item>
        ))}
      </Descriptions>
      <Descriptions column={descriptionsSenderItems?.length} layout={'vertical'} colon={false} title={<H3>{i18n.translate(`components.details.order.senderDetails`)}</H3>} style={{ marginBottom: 16 }}>
        {descriptionsSenderItems.map((item: {label: string | JSX.Element, render: JSX.Element}, index: number) => (
          <Descriptions.Item key={index} label={item.label}>{item.render}</Descriptions.Item>
        ))}
      </Descriptions>
      <Descriptions column={descriptionsReceiverItems?.length} layout={'vertical'} colon={false} title={<H3>{i18n.translate(`components.details.order.receiverDetails`)}</H3>} style={{ marginBottom: 16 }}>
        {descriptionsReceiverItems.map((item: {label: string | JSX.Element, render: JSX.Element}, index: number) => (
          <Descriptions.Item key={index} label={item.label}>{item.render}</Descriptions.Item>
        ))}
      </Descriptions>
      <Descriptions column={descriptionsPaymentItems?.length} layout={'vertical'} colon={false} title={<H3>{i18n.translate(`components.details.order.paymentDetails`)}</H3>} style={{ marginBottom: 16 }}>
        {descriptionsPaymentItems.map((item: {label: string | JSX.Element, render: JSX.Element}, index: number) => (
          <Descriptions.Item key={index} label={item.label}>{item.render}</Descriptions.Item>
        ))}
      </Descriptions>
      <H3 style={{ marginBottom: 16 }}>{i18n.translate(`components.details.order.orderSpecifications`)}</H3>
      {/** Dynamic Element */}
      <Descriptions column={descriptionsShipmentItems?.length} layout={'vertical'} colon={false} title={<H3>{i18n.translate(`components.details.order.shipmentDetails`)}</H3>} style={{ marginBottom: 16 }}>
        {descriptionsShipmentItems.map((item: {label: string | JSX.Element, render: JSX.Element}, index: number) => (
          <Descriptions.Item key={index} label={item.label}>{item.render}</Descriptions.Item>
        ))}
      </Descriptions>
      <Descriptions column={descriptionsRansomItems?.length} layout={'vertical'} colon={false} title={<H3>{i18n.translate(`components.details.order.ransom`)}</H3>} style={{ marginBottom: 16 }}>
        {descriptionsRansomItems.map((item: {label: string | JSX.Element, render: JSX.Element}, index: number) => (
          <Descriptions.Item key={index} label={item.label}>{item.render}</Descriptions.Item>
        ))}
      </Descriptions>
      <Descriptions column={descriptionsNoteItems?.length} layout={'vertical'} colon={false}>
        {descriptionsNoteItems.map((item: {label: string | JSX.Element, render: JSX.Element}, index: number) => (
          <Descriptions.Item key={index} label={item.label}>{item.render}</Descriptions.Item>
        ))}
      </Descriptions>
    </>
  );
}

export default OrderDetails;
