import { Card } from "antd";
import React from 'react';
import { AddressInterface } from "src/common";
import { AddressDetails } from "../details";

interface Props {
  address?: AddressInterface;
}

const AddressCard = ({address}: Props) => (
  <Card className="mt8 mb8" style={{ maxWidth: 400 }}>
    <AddressDetails address={address} />
  </Card>
)

export default AddressCard;
