import { Col, Row } from 'antd';
import React from 'react';
import { OrderInterface } from 'src/common';
import { OrderDetails } from 'src/components/details';

interface Props {
  order?: OrderInterface;
}

const DetailsTab = ({ order }: Props) => (
  <>
    <Row justify={'space-between'}>
      <Col xs={24} md={20} flex={'auto'}>
        <OrderDetails order={order} />
      </Col>
    </Row>
  </>
);

export default DetailsTab;
