import React from "react";
import { Typography } from "antd";

const { Title } = Typography;

interface Props {
  children: React.JSX.Element | string; 
  style?: React.CSSProperties;
}

// Figtree Medium 16px
const H4 = ({ children, style }: Props) => ( 
  <Title level={3} style={{ margin: 0, padding: 0, fontWeight: 500, ...style }}>{children}</Title>
)

export default H4;
