import React from 'react';
import { Button, Checkbox, Flex, Form, Input, Radio, Row, Select, Space } from 'antd';
// import { resetSubmitOrderForm } from 'src/common/redux/drawer/drawer.slice';
import { AddressInterface, ClientInterface, OrderInterface, OrderPayer, OrderPayers, ParcelTypes, PaymentType, PaymentTypes, i18n } from '../../common';
import { H2, H3, T1 } from '../texts';
import { ClientValues } from '../filters';
import { AddIcon, DeleteIcon } from '../icons';

const { Option } = Select;

export type OrderFormItemType = 'uuid' | 'orderer' | 'ordererAddress' | 'sender' | 'senderAddress' | 'senderContactPerson' | 'senderContactNumber' | 'receiver' | 'receiverAddress' | 'receiverContactPerson' | 'receiverContactNumber' | 'orderPayer' | 'paymentType' | 'parcels' | 'orderTotalValue' | 'ransom' | 'ransomValue' | 'return' | 'requestedDeliveryDate' | 'note' | 'active';
export type InitialValueTypeOrder = { formItem: OrderFormItemType, value: string | number | boolean };

export const allOrderFormItems: Array<OrderFormItemType> = ['uuid', 'orderer', 'ordererAddress', 'sender', 'senderAddress', 'senderContactPerson', 'senderContactNumber', 'receiver', 'receiverAddress', 'receiverContactPerson', 'receiverContactNumber', 'orderPayer', 'paymentType', 'parcels', 'orderTotalValue', 'ransom', 'ransomValue', 'return', 'requestedDeliveryDate', 'note', 'active'];

interface Props {
  onOrderFormFinish?: (values: any) => void;
  order?: OrderInterface;
  formItems?: Array<OrderFormItemType>;
  disabled?: Array<OrderFormItemType>;
  initialValues?: Array<InitialValueTypeOrder>;
  labels?: { submitBtnLabel: string };
}

function OrderForm({ onOrderFormFinish, order, formItems, disabled, initialValues, labels }: Props) {

  // TODO - Pravilno mapirati po novim BE entitetima

  // const dispatch = useAppDispatch();

  const [form] = Form.useForm();

  // const submitForm = useAppSelector((state: RootState) => state.state.drawer.order.submit);

  const onFormSubmit = (values: any) => {
    const data = {
      ...values
    }
    if (onOrderFormFinish) onOrderFormFinish(data);
  }

  const items: Array<OrderFormItemType> = formItems || allOrderFormItems;

  const initialFormValues = {
    ...order,
    name: (initialValues?.find((fi: InitialValueTypeOrder) => fi.formItem === 'uuid')?.value as string) || order?.uuid,
    parcels: [{}]
  }

  /**
  useEffect(() => {
    if (submitForm) {
      form.submit();
      // dispatch(resetSubmitOrderForm());
    }
  }, [submitForm]);
  */

  const ordererId = Form.useWatch(['orderer', 'id'], form);
  const senderId = Form.useWatch(['sender', 'id'], form);
  const receiverId = Form.useWatch(['receiver', 'id'], form);

  const clientValues = ClientValues({ includes: ['addresses'] });

  return (<>
    <Form
      name='order'
      form={form}
      onFinish={onFormSubmit}
      initialValues={initialFormValues}
      layout={'vertical'}
    >
      <Flex gap={'16px'} vertical>
        <Flex gap={'16px'} justify='space-between'>
          <H2>{i18n.t('pages.order.title')} {order?.uuid}</H2>
          <Button type="primary" htmlType="submit">{labels?.submitBtnLabel}</Button>
        </Flex>
        <H3>{i18n.translate(`components.forms.order.ordererDetails`)}</H3>
        <Flex gap={'16px'} wrap='wrap'>
          <Form.Item
            name={['orderer', 'id']}
            label={<T1>{i18n.translate(`components.forms.order.orderer`)}</T1>}
            rules={[{ required: true, message: i18n.translate(`components.forms.common.required`) }]}
            hidden={!items.find(i => i === 'orderer')}
          >
            <Select
              style={{ minWidth: 220 }}
              disabled={!!disabled?.find(d => d === 'orderer')}
              onChange={() => form.setFieldValue(['ordererAddress', 'id'], undefined)}
            >
              {clientValues.map((c: ClientInterface) => <Option key={c.id} value={c.id}>{c.name}</Option>)}
            </Select>
          </Form.Item>
          <Form.Item
            name={['ordererAddress', 'id']}
            label={<T1>{i18n.translate(`components.forms.order.ordererAddress`)}</T1>}
            rules={[{ required: true, message: i18n.translate(`components.forms.common.required`) }]}
            hidden={!items.find(i => i === 'ordererAddress')}
          >
            <Select
              style={{ minWidth: 220 }}
              disabled={!!disabled?.find(d => d === 'ordererAddress')}
            >
              {ordererId && clientValues && clientValues
                .find((c: ClientInterface) => c.id === ordererId)?.addresses
                .map((a: AddressInterface) => <Option key={a.id} value={a.id}>{a.street}</Option>)}
            </Select>
          </Form.Item>
        </Flex>

        <H3>{i18n.translate(`components.forms.order.senderDetails`)}</H3>
        <Flex gap={'16px'} wrap='wrap'>
          <Form.Item
            name={['sender', 'id']}
            label={<T1>{i18n.translate(`components.forms.order.sender`)}</T1>}
            rules={[{ required: true, message: i18n.translate(`components.forms.common.required`) }]}
            hidden={!items.find(i => i === 'sender')}
          >
            <Select
              style={{ minWidth: 220 }}
              disabled={!!disabled?.find(d => d === 'sender')}
              onChange={() => form.setFieldValue(['senderAddress', 'id'], undefined)}
            >
              {clientValues.map((c: ClientInterface) => <Option key={c.id} value={c.id}>{c.name}</Option>)}
            </Select>
          </Form.Item>
          <Form.Item
            name={['senderAddress', 'id']}
            label={<T1>{i18n.translate(`components.forms.order.senderAddress`)}</T1>}
            rules={[{ required: true, message: i18n.translate(`components.forms.common.required`) }]}
            hidden={!items.find(i => i === 'senderAddress')}
          >
            <Select
              style={{ minWidth: 220 }}
              disabled={!!disabled?.find(d => d === 'senderAddress')}
            >
              {senderId && clientValues && clientValues
                .find((c: ClientInterface) => c.id === senderId)?.addresses
                .map((a: AddressInterface) => <Option key={a.id} value={a.id}>{a.street}</Option>)}
            </Select>
          </Form.Item>
          <Form.Item
            name="senderContactPerson"
            label={<T1>{i18n.translate(`components.forms.order.senderContactPerson`)}</T1>}
            hidden={!items.find(i => i === 'senderContactPerson')}
          >
            <Input style={{ maxWidth: 220 }} disabled={!!disabled?.find(d => d === 'senderContactPerson')} />
          </Form.Item>
          <Form.Item
            name="senderContactNumber"
            label={<T1>{i18n.translate(`components.forms.order.senderContactNumber`)}</T1>}
            hidden={!items.find(i => i === 'senderContactNumber')}
          >
            <Input style={{ maxWidth: 220 }} disabled={!!disabled?.find(d => d === 'senderContactNumber')} />
          </Form.Item>
        </Flex>

        <H3>{i18n.translate(`components.forms.order.receiverDetails`)}</H3>
        <Flex gap={'16px'} wrap='wrap'>
          <Form.Item
            name={['receiver', 'id']}
            label={<T1>{i18n.translate(`components.forms.order.receiver`)}</T1>}
            rules={[{ required: true, message: i18n.translate(`components.forms.common.required`) }]}
            hidden={!items.find(i => i === 'receiver')}
          >
            <Select
              style={{ minWidth: 220 }}
              disabled={!!disabled?.find(d => d === 'receiver')}
              onChange={() => form.setFieldValue(['receiverAddress', 'id'], undefined)}
            >
              {clientValues.map((c: ClientInterface) => <Option key={c.id} value={c.id}>{c.name}</Option>)}
            </Select>
          </Form.Item>
          <Form.Item
            name={['receiverAddress', 'id']}
            label={<T1>{i18n.translate(`components.forms.order.receiverAddress`)}</T1>}
            rules={[{ required: true, message: i18n.translate(`components.forms.common.required`) }]}
            hidden={!items.find(i => i === 'receiverAddress')}
          >
            <Select
              style={{ minWidth: 220 }}
              disabled={!!disabled?.find(d => d === 'receiverAddress')}
            >
              {receiverId && clientValues && clientValues
                .find((c: ClientInterface) => c.id === receiverId)?.addresses
                .map((a: AddressInterface) => <Option key={a.id} value={a.id}>{a.street}</Option>)}
            </Select>
          </Form.Item>
          <Form.Item
            name="receiverContactPerson"
            label={<T1>{i18n.translate(`components.forms.order.receiverContactPerson`)}</T1>}
            hidden={!items.find(i => i === 'receiverContactPerson')}
          >
            <Input style={{ maxWidth: 220 }} disabled={!!disabled?.find(d => d === 'receiverContactPerson')} />
          </Form.Item>
          <Form.Item
            name="receiverContactNumber"
            label={<T1>{i18n.translate(`components.forms.order.receiverContactNumber`)}</T1>}
            hidden={!items.find(i => i === 'receiverContactNumber')}
          >
            <Input style={{ maxWidth: 220 }} disabled={!!disabled?.find(d => d === 'receiverContactNumber')} />
          </Form.Item>
        </Flex>

        <H3>{i18n.translate(`components.forms.order.paymentDetails`)}</H3>
        <Flex gap={'16px'} wrap='wrap'>
          <Form.Item
            name="payedBy"
            label={<T1>{i18n.translate(`components.forms.order.orderPayer`)}</T1>}
            rules={[{ required: true, message: i18n.translate(`components.forms.common.required`) }]}
            hidden={!items.find(i => i === 'orderPayer')}
          >
            <Select
              style={{ minWidth: 220 }}
              disabled={!!disabled?.find(d => d === 'orderPayer')}
            >
              {OrderPayers().map((op: OrderPayer) => <Option key={op.code} value={op.code}>{op.label}</Option>)}
            </Select>
          </Form.Item>
          <Form.Item
            name="paymentType"
            label={<T1>{i18n.translate(`components.forms.order.paymentType`)}</T1>}
            rules={[{ required: true, message: i18n.translate(`components.forms.common.required`) }]}
            hidden={!items.find(i => i === 'paymentType')}
          >
            <Select
              style={{ minWidth: 220 }}
              disabled={!!disabled?.find(d => d === 'paymentType')}
            >
              {PaymentTypes().map((pt: PaymentType) => <Option key={pt.code} value={pt.code}>{pt.label}</Option>)}
            </Select>
          </Form.Item>
        </Flex>

        <H3>{i18n.translate(`components.forms.order.orderSpecifications`)}</H3>
        <Form.Item
          name='parcels'
          hidden={!items.find(i => i === 'parcels')}
        >
          <Form.List name="parcels">
            {(fields, { add, remove }) => (
              <>
                {fields.map(({ key, name, ...restField }, index) => (
                  <Flex key={key} gap={16} style={{ marginBottom: 16 }} align='end' wrap='wrap'>
                    <Flex align='end' gap={16} >
                      <Form.Item {...restField} name={[name, 'rbr']} style={{ marginBottom: 0 }}>
                        <Flex style={{ marginBottom: 5, minWidth: 24 }}><T1>{`${index + 1}.`}</T1></Flex>
                      </Form.Item>
                      <Form.Item {...restField} name={[name, 'parcelType']} style={{ marginBottom: 0 }} label={index === 0 ? <T1>{i18n.translate(`components.forms.order.parcelType`)}</T1> : undefined}>
                        <Select
                          disabled={!!disabled?.find(d => d === 'parcels')}
                          style={{ minWidth: 220 }}
                        >
                          {ParcelTypes().map((op: OrderPayer) => <Option key={op.code} value={op.code}>{op.label}</Option>)}
                        </Select>
                      </Form.Item>
                    </Flex>
                    <Flex gap={8} align='end'>
                      <Form.Item {...restField} name={[name, 'length']} style={{ marginBottom: 0 }} label={index === 0 ? <T1>{i18n.translate(`components.forms.order.dimensions`)}</T1> : undefined}>
                        <Input disabled={!!disabled?.find(d => d === 'parcels')} style={{ minWidth: 120 }}/>
                      </Form.Item>
                      <Flex style={{ marginBottom: 5 }}>x</Flex>
                      <Form.Item {...restField} name={[name, 'width']} style={{ marginBottom: 0 }}>
                        <Input disabled={!!disabled?.find(d => d === 'parcels')} style={{ minWidth: 120 }}/>
                      </Form.Item>
                      <Flex style={{ marginBottom: 5 }}>x</Flex>
                      <Form.Item {...restField} name={[name, 'height']} style={{ marginBottom: 0 }}>
                        <Input disabled={!!disabled?.find(d => d === 'parcels')} style={{ minWidth: 120 }} />
                      </Form.Item>
                    </Flex>
                    <Form.Item {...restField} name={[name, 'weight']} style={{ marginBottom: 0 }} label={index === 0 ? <T1>{i18n.translate(`components.forms.order.weight`)}</T1> : undefined}>
                      <Input disabled={!!disabled?.find(d => d === 'parcels')} style={{ minWidth: 220 }} />
                    </Form.Item>
                    <Flex gap={8} align='end'>
                      <Form.Item {...restField} name={[name, 'chargeableWeight']} style={{ marginBottom: 0 }} label={index === 0 ? <T1>{i18n.translate(`components.forms.order.chargeableWeight`)}</T1> : undefined}>
                        <Input disabled  style={{ minWidth: 220 }}/>
                      </Form.Item>
                      <Form.Item style={{ marginBottom: 0 }}>
                        <Flex style={{ marginBottom: 9 }} gap={8}>
                          {/* <DuplicateIcon style={{ color: 'red' }} onClick={() => {console.log(key, name, fields[name]); add(name)}} /> */}
                          {fields.length > 1 && <DeleteIcon style={{ color: 'red' }} onClick={() => remove(name)} />}
                        </Flex>
                      </Form.Item>
                    </Flex>
                  </Flex>
                ))}
                <Row>
                  {!disabled?.find(d => d === 'parcels') && <Form.Item style={{ marginBottom: 0 }}>
                    <Button type="link" onClick={() => add()} style={{ padding: 0 }} icon={<AddIcon />}>
                      {i18n.t('components.forms.order.addParcel')}
                    </Button>
                  </Form.Item>}
                </Row>
              </>
            )}
          </Form.List>
        </Form.Item>

        <Form.Item
          name="orderTotalValue"
          label={<T1>{i18n.translate(`components.forms.order.orderTotalValue`)}</T1>}
          rules={[{ required: true, message: i18n.translate(`components.forms.common.required`) }]}
          hidden={!items.find(i => i === 'orderTotalValue')}
        >
          <Input style={{ maxWidth: 220 }} disabled={!!disabled?.find(d => d === 'orderTotalValue')} />
        </Form.Item>

        <H3>{i18n.translate(`components.forms.order.ransom`)}</H3>
        <Form.Item
          name="ransom"
          hidden={!items.find(i => i === 'ransom')}
        >
          <Radio.Group size="large">
            <Space direction="horizontal">
              <Radio value={true}>{i18n.translate(`components.forms.order.ransomYes`)}</Radio>
              <Radio value={false}>{i18n.translate(`components.forms.order.ransomNo`)}</Radio>
            </Space>
          </Radio.Group>
        </Form.Item>
        <Form.Item
          name="ransomValue"
          label={<T1>{i18n.translate(`components.forms.order.ransomValue`)}</T1>}
          rules={[{ required: true, message: i18n.translate(`components.forms.common.required`) }]}
          hidden={!items.find(i => i === 'ransomValue')}
        >
          <Input style={{ maxWidth: 220 }} disabled={!!disabled?.find(d => d === 'ransomValue')} />
        </Form.Item>

        {/* <Form.Item
          name="return"
          hidden={!items.find(i => i === 'return')}
        >
          <Checkbox><T1>{i18n.translate(`components.forms.order.return`)}</T1></Checkbox>
        </Form.Item> TODO povrati */}

        <Form.Item
          name="requestedDeliveryDate"
          hidden={!items.find(i => i === 'requestedDeliveryDate')}
        >
          <Checkbox><T1>{i18n.translate(`components.forms.order.requestedDeliveryDate`)}</T1></Checkbox>
        </Form.Item>

        <Form.Item
          name="note"
          label={<T1>{i18n.translate(`components.forms.order.note`)}</T1>}
          hidden={!items.find(i => i === 'note')}
        >
          <Input style={{ maxWidth: 700 }} disabled={!!disabled?.find(d => d === 'note')} />
        </Form.Item>
      </Flex>
    </Form>
  </>
  );
}

export default OrderForm;
