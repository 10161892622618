import React, { useEffect } from 'react';
import { Link, useParams } from 'react-router-dom';
import { Breadcrumb, Card } from 'antd';
import { QueryParams, OrderInterface, i18n } from '../../../common';
import { OrderForm } from '../../../components';
import { AppRoutes } from '../_router/app.routes';

const OrderEditPage = () => {
  // const dispatch = useAppDispatch();

  const params = useParams();

  const queryParams: QueryParams = { includes: ['dbRole'] };

  // TODO možda dodatno zaštititi da se ne dohvaća s api-ja ako je već u reduxu
  useEffect(() => {
    // if (params.order_id) dispatch(getOrder(parseInt(params.order_id!, 10), queryParams));
  }, [params]
  );

  // const order: OrderInterface | undefined = useAppSelector((state: RootState) => state.order.one);

  // if (!order || !order.id) return null;

  const onOrderEdit = (values: OrderInterface) => {
    console.log(values);
    // dispatch(updateOrder(order.id!, values));
  };

  console.log(queryParams, onOrderEdit);

  return (
    <>
      <Breadcrumb style={{ marginBottom: 24 }} items={[
        { title: <Link to={AppRoutes.ORDERS.fullPath} key={AppRoutes.ORDERS.fullPath}>{i18n.t('common.breadcrumb.orders')}</Link> },
        { title: i18n.translate('common.breadcrumb.orderEdit') }]} />
      <Card>
        <OrderForm
          // order={order}
          // onOrderFormFinish={(values) => onOrderEdit(values)}
        />
      </Card>
    </>
  );
}

export default OrderEditPage;
