import { RoleEnum } from "../enums";
import { i18n } from "../services";

export interface Role {
  id: number;
  name: string;
}

export const Roles = (): Array<Role> => [
  { id: RoleEnum.SUPER_ADMIN, name: i18n.translate("common.constants.role.superAdmin") },
  { id: RoleEnum.ADMIN, name: i18n.translate("common.constants.role.admin") },
  { id: RoleEnum.DISPATCHER, name: i18n.translate("common.constants.role.dispatcher") },
  { id: RoleEnum.WAREHOUSE_WORKER, name: i18n.translate("common.constants.role.warehouseWorker") },
  { id: RoleEnum.DRIVER, name: i18n.translate("common.constants.role.driver") },
  { id: RoleEnum.OUTSIDE_EMPLOYEE, name: i18n.translate("common.constants.role.outsideEmployee") },
]
